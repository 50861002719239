import { GET } from './Clients';

const INSIDER_API_PREFIX = `${process.env.NEXT_PUBLIC_INSIDER_API_DOMAIN}/thuocsivn/vi_VN`;
const INSIDER_API = {
  MOST_VIEWED: `${INSIDER_API_PREFIX}/most/viewed/partner`,
  USER_BASED: `${INSIDER_API_PREFIX}/user`,
};

/**
 * Get Insider recommendation data
 * @param {object} param
 * @param {import('next').NextPageContext=} param.ctx
 * @param {string=} param.userId
 * @param {boolean=} param.details
 * @param {string} param.type
 * @returns {Promise<object>}
 */
export async function getInsiderRecommendationData({ ctx, userId = '', details = true, type }) {
  const url = INSIDER_API[type];
  if (!url) return {};

  return GET({
    ctx,
    url: INSIDER_API[type],
    params: { userId, details },
  });
}

