import { loadCategoryInfoBySlug, loadManufacturerInfoBySlug } from 'clients/CatClientV2';
import { getData, getFirst, isValid } from 'clients/Clients';
import ProductClientV2, { getDescriptionById, getFuzzySearchClient, getProducts, getSkusLimit } from 'clients/ProductClientV2';
import { getProductBySeller } from 'clients/SellerClient';
import { getCombosContainingProduct, getProductDetailRaw, getProductDetailRawLite, getRecommendationProduct, getTopProductsSkipLogin, loadProductDetail } from 'clients/WebServiceClient';
import { PAGE_SIZE, PAGE_SIZE_24, PAGE_SIZE_30, THUOC_VA_HOAT_CHAT } from 'constants/data';
import { HTTP_STATUS } from 'constants/Enums/http';
import { GIFT_IMAGE } from 'constants/Images';
import { IS_ENCRYPTED_PRICE, SELLER_CODES_REQUIRE_GPP, TAG_NEW } from 'sysconfig';
import { decryptAES, genkey } from 'utils/decode';
import { formatCurrency, formatNumber } from 'utils/FormatNumber';
import { getProxyImageList } from 'utils/ImageUtils';
import { capitalize } from 'utils/StringUtils';
import { getAccount } from './UserService';

export const findDiscountPercent = (salePrice, displayPrice) => {
  if (salePrice === displayPrice || displayPrice > salePrice) return 0;
  let percentDiscountProduct = ((salePrice - displayPrice) / salePrice) * 100;
  if (percentDiscountProduct > 100) percentDiscountProduct = 100;
  return Math.round(percentDiscountProduct) || 1;
};

export const convertSlug = (slug) => slug?.toLowerCase() || slug;

const DEFAULT_SEARCH_STRATEGY = {
  text: true,
  keyword: true,
  ingredient: true,
};

/*
Hàm convert thông tin product từ API trả về và chỉ dùng 1 số phần thôi
dùng trong tất cả các hàm ở web
*/
// limit order
export function getLimitOrder(limitPerday, limitPerOrder, unit) {
  let messageLimitOrder = '';
  if (limitPerday > 0 && limitPerday < limitPerOrder) {
    messageLimitOrder = `Đặt tối đa ${formatNumber(limitPerday)} ${unit || 'sản phẩm'}/ngày`;
  }
  if (limitPerday <= 0 && limitPerOrder > 0 && limitPerOrder < 100000) {
    messageLimitOrder = `Đặt tối đa ${formatNumber(limitPerOrder)} ${unit || 'sản phẩm'}/lần`;
  }
  if (limitPerOrder <= 0 && limitPerday > 0) {
    messageLimitOrder = `Đặt tối đa ${formatNumber(limitPerday)} ${unit || 'sản phẩm'}/ngày`;
  } else if ((limitPerOrder > 0 && limitPerday > 0 && limitPerday === limitPerOrder) || limitPerday > limitPerOrder) {
    messageLimitOrder = `Đặt tối đa ${formatNumber(limitPerday)} ${unit || 'sản phẩm'}/ngày ${
      limitPerday !== limitPerOrder ? `và ${formatNumber(limitPerOrder)} ${unit || 'sản phẩm'}/lần` : ''
    }`;
  }
  return messageLimitOrder;
}
// 19Apr22 : thêm config limitPerDay -> chỉ áp dụng cho normal , ko áp dụng cho deal/ campaign /
const convertProductV2 = (item = {}) => {
  const {
    isAvailable = true,
    isShadowSKU = false,
    isWishList = false,
    product,
    sku,
    // deal = null,
    // nextPriceLevel = {},
    // campaign = null,
    skuDescription = {},
    limitPerDay = item?.consumedMaxQuantity?.maxQuantityPerDay || 0,
    quantityPurchasedToday = 0,
    consumedMaxQuantity = null, // web service
    // feature display product without login
    withoutLogin = false,
    isProductCard = false,
    sellerInfo = {},
    vendorInfo = {},
    vendorProductTag = '',
    quantity = 0, // cart quantity
    skuReplace = [],
    isSKUReplace = false,
    isSkuReplaceProductList = false,
  } = item || {};

  const deal = (item?.deal && JSON.parse(JSON.stringify(item?.deal || {}))) || null;
  const nextPriceLevel = JSON.parse(JSON.stringify(item?.nextPriceLevel || {}));
  const campaign = (item?.campaign && JSON.parse(JSON.stringify(item?.campaign))) || null;

  const {
    categoryCodes = null,
    name = '',
    origin = '',
    volume = null,
    unit = null,
    manufacturerCode = null,
    ingredients = [],
    ingredientCodes = [], // product detail sẽ trả ra thêm 1 field là ingredientCodes
    productID = '',
    documentFiles = [],
    weight = 0,
    height = 1,
    length = 1,
    width = 1,
    isCombo = false,
    originName = '',
    categories = [],
    manufacturer = null,
    documents = [],
    doseUnitName = '',
    // retailInfo = {},// product detail sẽ trả ra thêm 1 field là retailInfo
  } = product || {};
  // requiredCertificates: [ 'GPP', 'BUSINESS_CERTIFICATE', 'BUSINESS_LICENSE' ],

  const retailInfo = JSON.parse(JSON.stringify(product?.retailInfo || {}));

  const {
    maxQuantityPerOrder = null,
    productCode = '',
    retailPriceType = '',
    retailPriceValue = 0,
    sellerCode = null,
    sellerClass = null,
    status = '',
    code = '',
    tags = [],
    slug = null,
    skuTags = [],
    statusData = null,
    type,
    skus = [],
    point = null,
    pointMultiplier = null,
    isActive, // true la co hien thi tren web, false la bi tat hien thi
    lotDates = [], // thong tin ve can date
    requiredCertificates = [],
    hasGift = false,
    vouchers = [],
    retailPriceValueEncrypt = '',
    retailPriceApplyVoucherEncrypt = '',
    retailPricePerUnitEncrypt = '',
    skuLabels = [],
    discountPercentage: newDiscountPercentage = 0,
    isHighProfit = false,
    styleDisplay = "",
  } = sku || {};

  const { maxQuantity: maxQuantityDeal } = deal || {};

  const tagExpired = lotDates.find((ele) => ele?.isNearExpired === true) || {};

  const { expiredDate = '', isNearExpired = false } = tagExpired;

  // giá hợp đồng
  const isContractPrice = type === 'SKU_CONTRACT';

  const isDeal = deal && deal?.status === 'ACTIVE' && new Date(deal?.startTime) <= new Date() ? true : null;

  const imageUrls = isDeal && deal?.imageUrls?.length > 0 ? deal?.imageUrls : item?.product?.imageUrls || [];
  const descriptionInfo = item?.description || {};
  if (skuDescription && skuDescription?.description) {
    descriptionInfo.description = skuDescription.description;
  }

  // const salePrice = deal?.price || retailPriceValue;
  // ưu tiên campaign

  const isCampaign = !!campaign || false;
  const isHappeningCampaign = (campaign && campaign?.isShow) || false;
  // anpham: push status into tags
  const newTags = (status !== 'NORMAL' && status !== 'NEAR_EXPIRATION' && [...tags, status]) || tags;
  if (isNearExpired) newTags.push('NEAR_EXPIRATION');
  const isNew = tags?.indexOf(TAG_NEW) >= 0;
  // logic check sản phẩm này có dc hiển thị hay ko ở đây
  let customMessageproduct = null;
  let requireGPPMessage = null;

  if (SELLER_CODES_REQUIRE_GPP?.length > 0 && sellerCode && SELLER_CODES_REQUIRE_GPP.indexOf(sellerCode) >= 0) {
    requireGPPMessage = 'Yêu cầu cung cấp GPP và giấy phép kinh doanh';
  }

  const dealPrice = (isDeal && deal && deal?.price) ?? null;
  const imagesProxy = getProxyImageList(imageUrls);

  const isGift = status === 'GIFT';

  // variable template
  // update formatedCurrency
  const displayPrice = dealPrice ?? retailPriceValue;

  // product type
  let productType = 'NORMAL';
  let typeItem = 'NORMAL';
  const hidenPrice = null;
  let maxQuantity = maxQuantityPerOrder;
  let retailPriceApplyVoucher = retailPriceApplyVoucherEncrypt;
  let retailPricePerUnit = 0;
  const maxQuantityPerOrderItem = maxQuantityPerOrder;

  // percentage of quantity sold and sold out condition
  let percentDealSold;
  // available products to be able to buy in campaign
  let availableProducts;
  let discountPercentage;
  const messageLimitOrder = getLimitOrder(limitPerDay, maxQuantityPerOrderItem, unit);

  const priceObject = {
    salePrice: retailPriceValue,
    dealPrice,
    displayPrice,
  };

  // ============= DECODE PRICE =============
  if (IS_ENCRYPTED_PRICE && retailPriceValueEncrypt) {
    // do action
    const keygen = genkey(`${process.env.NEXT_PUBLIC_DECODE_PRICE_KEY}`);

    const encodeRetailPriceValue = decryptAES({ key: keygen, value: retailPriceValueEncrypt || '' });
    priceObject.displayPrice = priceObject.dealPrice ?? encodeRetailPriceValue;

    if (deal?.priceEncrypt) {
      const encodeDealPrice = decryptAES({ key: keygen, value: deal?.priceEncrypt || '' });
      priceObject.dealPrice = encodeDealPrice;
      priceObject.displayPrice = encodeDealPrice ?? encodeRetailPriceValue;
      deal.price = encodeDealPrice;
    }

    priceObject.salePrice = encodeRetailPriceValue;

    // if have campaign
    if (isHappeningCampaign) {
      const retailPriceCampaign = decryptAES({ key: keygen, value: campaign?.retailPriceValueEncrypt || '' });
      campaign.retailPriceValue = retailPriceCampaign;
    }

    if (retailInfo.retailRefPriceEncrypt) {
      retailInfo.retailRefPrice = decryptAES({ key: keygen, value: retailInfo.retailRefPriceEncrypt || 0 });
    }

    if (nextPriceLevel && nextPriceLevel.priceEncrypt) {
      nextPriceLevel.price = decryptAES({ key: keygen, value: nextPriceLevel.priceEncrypt || 0 });
    }
  }
  if (IS_ENCRYPTED_PRICE) {
    const keygen = genkey(`${process.env.NEXT_PUBLIC_DECODE_PRICE_KEY}`);

    retailPriceApplyVoucher = decryptAES({ key: keygen, value: retailPriceApplyVoucherEncrypt || '' });
    retailPricePerUnit = decryptAES({ key: keygen, value: retailPricePerUnitEncrypt || '' });
  }
  // ============= END DECODE PRICE =============

  // nếu có campaign
  if (isHappeningCampaign) {
    const {
      campaign: campaignInfo,
      maxQuantityPerOrder: maxQuantityPerOrderCampaign = maxQuantityPerOrder,
      quantity: maxQuantityCampaign,
      retailPriceValue: retailPriceCampaign,
      soldQuantity = 0,
    } = campaign;

    percentDealSold = (soldQuantity / campaign?.quantity) * 100;

    if (percentDealSold < 100) {
      newTags.push('DEAL');
      discountPercentage = findDiscountPercent(priceObject?.salePrice, retailPriceCampaign);
      availableProducts = Math.min(maxQuantityCampaign - soldQuantity, maxQuantityPerOrderCampaign);
      if (campaignInfo?.campaignType === 'NORMAL') {
        productType = 'MEGA_DAY';
      } else if (campaignInfo?.campaignType === 'FLASH_SALE') {
        productType = 'FLASH_SALE';
        newTags.push(productType);
      }
      // price for campaign
      typeItem = 'CAMPAIGN';
      priceObject.displayPrice = retailPriceCampaign;
      maxQuantity = maxQuantityCampaign;
    } else {
      productType = 'NORMAL';
      customMessageproduct = `Bạn đã bỏ lỡ giá khuyến mãi ${formatCurrency(retailPriceCampaign)}`;
    }
  } else if (isDeal) {
    productType = 'DEAL';
    typeItem = 'DEAL';
    // MIN
    maxQuantity = Math.min(maxQuantityDeal, maxQuantityPerOrder);
    discountPercentage = findDiscountPercent(priceObject?.salePrice, priceObject?.dealPrice);
    newTags.push(productType);
    availableProducts = deal?.maxQuantity - deal?.quantity || 0;
    percentDealSold = ((deal?.quantity || 0) / deal?.maxQuantity) * 100;
  } else {
    productType = 'NORMAL';
    // availableProducts = limitPerDay;
  }

  priceObject.salePriceFormated = formatCurrency(priceObject.salePrice) || '';
  priceObject.displayPriceFormated = formatCurrency(priceObject.displayPrice) || '';
  priceObject.dealPriceFormated = formatCurrency(priceObject.dealPrice) || '';

  if (isProductCard) {
    return {
      consumedMaxQuantity,
      isNew,
      isAvailable,
      isShadowSKU,
      isWishList,
      defaultImage: isGift ? imagesProxy[0] || GIFT_IMAGE : imagesProxy[0] || '',
      originalImages: getProxyImageList(item?.product?.imageUrls || []),
      maxQuantity,
      maxQuantityPerOrder: maxQuantityPerOrderItem,
      maxQuantityPerDay: limitPerDay,
      limitPerDay,
      quantityPurchasedToday,
      isCombo,
      ...priceObject,
      hidenPrice,
      // sku
      sku: code,
      // combo
      skus,
      skuTags,
      skuId: productCode,
      // code
      slug: convertSlug(slug),
      status,
      // deal
      // nếu tồn tại campaing thì sẽ ko tồn tại deal- thuannc 06Jan2022
      deal: isCampaign ? null : deal,
      isDeal,
      // seller
      sellerClass,
      sellerCode,
      seller: withoutLogin
        ? {}
        : {
            code: sellerCode,
            slug, // not yet
          },
      // info
      // DONE: name deal thuannc
      name: (isDeal && deal && deal?.name) || name,
      nameNormal: name,
      productId: productID, // for insider
      productCode,
      unit,
      volume: capitalize(volume),
      statusData,
      productSkuType: type,
      tags: withoutLogin ? [] : newTags || null,
      type: typeItem || null,
      productType,
      isGift,
      customMessageproduct,
      requiredCertificates,
      // campaign
      isCampaign,
      campaign,
      discountPercent: newDiscountPercentage || discountPercentage || 0,
      isHappeningCampaign,
      availableProducts: availableProducts || 0,
      // percentage discount for progress
      percentDealSold: percentDealSold || 0,
      isContractPrice, // giá hợp đồng
      // plus points
      point,
      pointMultiplier,
      messageLimitOrder,
      isActive,
      expiredDate,
      isTagGift: hasGift,
      quantity, // cart quantity
      skuLabels,
      retailPriceApplyVoucher,
      retailPricePerUnit,
      doseUnitName,
      skuReplace:
        skuReplace?.length > 0 && isSkuReplaceProductList
          ? skuReplace.map((item) => ({
              ...convertProductV2({ ...item, withoutLogin: false, isProductCard: true }),
              quantity: item.quantityInCart || 0,
              errorCode: item.errorCode || null,
              errorMessage: item.errorMessage || null,
              sellerID: item?.sellerInfo?.sellerID || '',
            }))
          : skuReplace,
      isSKUReplace,
      sellerID: sellerInfo?.sellerID || '',
      isHighProfit,
      styleDisplay,
    };
  }
  const { sku: { last24hSoldQuantity = 0 } = {} } = item ?? {};
  const productInfo = {
    consumedMaxQuantity,
    isNew,
    isCombo,
    productCode,
    isAvailable,
    isShadowSKU,
    isWishList,
    imageUrls,
    imagesProxy: isGift ? imagesProxy || [GIFT_IMAGE] : imagesProxy,
    defaultImage: isGift ? imagesProxy[0] || GIFT_IMAGE : imagesProxy[0] || '',
    originalImages: getProxyImageList(item?.product?.imageUrls || []),
    maxQuantity,
    maxQuantityPerOrder: maxQuantityPerOrderItem,
    maxQuantityPerDay: limitPerDay,
    limitPerDay,
    quantityPurchasedToday,
    // price
    // salePrice: retailPriceValue,
    // salePriceFormated: formatCurrency(retailPriceValue) || '',
    // dealPrice,
    // dealPriceFormated: formatCurrency(dealPrice) || '',
    // displayPrice,
    // displayPriceFormated: formatCurrency(displayPrice) || '', // không sài giá âm ví dụ: -410000đ
    hidenPrice,
    ...priceObject,
    nextPrice: isDeal || isCampaign ? 0 : nextPriceLevel?.price || 0,
    nextLevel: nextPriceLevel?.code || '',
    // sku
    sku: code,
    skuTags,
    // combo
    skus,
    skuId: productCode,
    kind: retailPriceType,
    // code
    slug: convertSlug(slug),
    status,
    ingredients,
    ingredientCodes,
    manufacturerCode,
    categoryCodes,

    // type

    // deal
    // nếu tồn tại campaing thì sẽ ko tồn tại deal- thuannc 06Jan2022
    deal: isCampaign ? null : deal,
    isDeal,
    // seller
    sellerCode,
    seller: withoutLogin
      ? {}
      : {
          code: sellerCode,
          slug, // not yet
        },
    // info
    // DONE: name deal thuannc
    name: (isDeal && deal && deal?.name) || name,
    nameNormal: name,
    productId: productID, // for insider
    unit,
    origin,
    description: descriptionInfo,
    volume: capitalize(volume),
    weight,
    productVolume: width * height * length,
    statusData,
    productSkuType: type,
    tags: withoutLogin ? [] : newTags || null,
    type: typeItem || null,
    productType,
    isGift,
    customMessageproduct,
    requireGPPMessage,
    requiredCertificates,
    documentFiles,

    // campaign
    isCampaign,
    campaign,
    discountPercent: newDiscountPercentage || discountPercentage || 0,
    isHappeningCampaign,
    availableProducts: availableProducts || 0,

    // percentage discount for progress
    percentDealSold: percentDealSold || 0,
    isContractPrice, // giá hợp đồng
    // plus points
    point,
    pointMultiplier,
    messageLimitOrder,
    isActive,
    expiredDate,
    isTagGift: hasGift,
    vouchers,
    sellerInfo,

    originName,
    categories,
    manufacturer,
    documents,
    vendorInfo,
    vendorProductTag,
    retailInfo,
    quantity, // cart quantity
    orderedCount: last24hSoldQuantity,
    skuLabels,
    retailPriceApplyVoucher,
    retailPricePerUnit,
    doseUnitName,
    skuReplace:
      skuReplace?.length > 0 && isSkuReplaceProductList
        ? skuReplace.map((item) => ({
            ...convertProductV2({ ...item, withoutLogin: false, isProductCard: true }),
            quantity: item.quantityInCart || 0,
            errorCode: item.errorCode || null,
            errorMessage: item.errorMessage || null,
            sellerID: item?.sellerInfo?.sellerID || '',
          }))
        : skuReplace,
    isSKUReplace,
    sellerID: sellerInfo?.sellerID || '',
    isHighProfit,
    styleDisplay,
  };

  // ưu tiên tag theo thứ tự -> hang_dat_truoc,...

  // console.log('convertProductV2  > ', productInfo);
  return productInfo;
};

export const mapDataProduct = async ({
  result,
  withoutLogin = false,
  isAvailable = false,
  isProductCard = false,
  isConvertProduct = true,
  isSkuReplaceProductList = false,
  doNotAttachRecommendSKUs = false,
}) => {
  try {
    const time = new Date().getTime();
    if (!isValid(result)) {
      return result;
    }
    const { data: dataProducts = [] } = result || {};
    let data = dataProducts;
    if (isAvailable) {
      data = data.filter((item) => item.isAvailable);
    }

    // eslint-disable-next-line no-param-reassign
    result.data =
      data?.map((item) => {
        // 20Apr thêm limitPerDay
        const info = isConvertProduct ? convertProductV2({ ...item, withoutLogin, isProductCard, isSkuReplaceProductList }) : item;
        return {
          ...info,
          quantity: item.quantityInCart || 0,
          errorCode: item.errorCode || null,
          errorMessage: item.errorMessage || null,
          recommendSKUs: !doNotAttachRecommendSKUs
            ? data
                .filter((i) => i.sku?.sku != item.sku?.sku)
                .map((i) => i.sku?.sku)
                .join()
            : '',
        };
      }) || [];
    // eslint-disable-next-line no-param-reassign
    result.timeMapData = +new Date() - time;
    return result;
  } catch (e) {
    console.error(e);
    return null;
  }
};

// map with limit skus
// deprecated 22Feb2023 - Dinh Tran
// export const mapDataProductWithoutCart = async ({ result }) => {
//   try {
//     const { data = [] } = result || {};
//     // eslint-disable-next-line no-param-reassign
//     result.data = data.map((item) => {
//       const info = convertProductV2(item);
//       return {
//         ...info,
//       };
//     });
//     return result;
//   } catch (e) {
//     console.error(e);
//     return null;
//   }
// };

// search product
export const searchProductsQuickOrder = async (keyword, page = 1, getTotal = false) => {
  const body = {
    offset: (page - 1) * PAGE_SIZE_30,
    text: keyword || null,
    limit: PAGE_SIZE_30,
    getTotal,
    getPrice: true,
    searchStrategy: DEFAULT_SEARCH_STRATEGY,
    // allSku: true, // for status out of stock for search
  };
  const result = await getFuzzySearchClient({ body });
  if (!isValid(result)) {
    return result;
  }

  return mapDataProduct({ result, isGetQuantity: true });
};

// Mobile Product V2
export const searchProductsMobile = async (keyword, page, query) => {
  const { category, manufacturer, sort, text, tag } = query;
  const filter = {};
  const defaultQuerySearch = '';

  if (category) {
    filter.category = query.category;
  }
  if (manufacturer) {
    filter.manufacturer = query.manufacturer;
  }
  if (tag) {
    filter.tag = query.tag;
  }
  const searchText = keyword || text || defaultQuerySearch;
  const searchSort = !keyword ? sort : '';
  const searchPage = Number(page) || Number(query?.page) || 1;
  const body = {
    ...query,
    offset: Number(page - 1) * PAGE_SIZE,
    text: searchText,
    limit: PAGE_SIZE,
    getTotal: true,
    getPrice: true,
    searchStrategy: DEFAULT_SEARCH_STRATEGY,
    filter,
    sort: searchSort,
    isAvailable: query?.isAvailable === 'true',
    page: searchPage,
  };
  const result = await getFuzzySearchClient({ body });
  if (!isValid(result)) {
    return result;
  }

  return mapDataProduct({ result, isGetQuantity: false });
};

export const getListTabs = async ({ ctx }) => {
  const res = await ProductClientV2.getTabs({ ctx });
  if (!isValid(res)) {
    return [];
  }
  return res.data;
};

export const loadDataQuickOrder = async ({ page = 1, getTotal = false }) => {
  const body = {
    offset: (page - 1) * PAGE_SIZE_30,
    limit: PAGE_SIZE_30,
    getTotal,
    getPrice: true,
    searchStrategy: DEFAULT_SEARCH_STRATEGY,
    // allSku: true,
  };

  const result = await getFuzzySearchClient({ body });
  if (!isValid(result)) return result;

  return mapDataProduct({ result, isGetQuantity: false });
};

const checkCurrentTab = async ({ ctx, filter }) => {
  const { query } = ctx;
  if (query.currentTab) {
    const tabs = await getListTabs({ ctx });
    const currentTab = tabs.find((tab) => tab.slug === query.currentTab);
    // eslint-disable-next-line no-param-reassign
    if (currentTab) filter[currentTab.type.toLowerCase()] = currentTab.value;
  }
};

// tabs truyền vào từ ssr / client
export const loadDataProduct = async ({
  ctx,
  limit = PAGE_SIZE_30,
  tabs = [],
  log = false,
  v2 = false,
  isConvertProduct = true,
  isQuickOrder = false,
}) => {
  const { query } = ctx;
  let filter = {};

  // check currentTab -> filter
  if (query.currentTab) {
    if (tabs?.length > 0) {
      const currentTab = tabs.find((tab) => tab.slug === query.currentTab);
      if (currentTab) filter[currentTab.type.toLowerCase()] = currentTab.value;
    } else {
      await checkCurrentTab({ ctx, filter });
    }
  }
  // check tag
  if (query.tag) {
    if (query.extraTag) {
      filter.tags = [query.extraTag, query.tag];
      delete filter.tag;
    } else {
      filter.tag = query.tag;
    }
  } else if (query.extraTag) {
    filter.tag = query.extraTag;
  }

  if (query.filter) {
    let categoryFilters = [];
    try {
      categoryFilters = JSON.parse(query.filter)?.categoryFilters || [];
    } catch (e) {
      categoryFilters = [];
    }

    const combinedCategoryFilters = categoryFilters.filter((item) => item.isCombined) || [];

    categoryFilters = categoryFilters.filter(
      (cat) =>
        cat.code !== cat.categoryCodes?.[0] ||
        cat.efficacyCodes?.length > 0 ||
        combinedCategoryFilters.every((item) => !item.categoryCodes?.includes(cat.code)),
    );

    filter = {
      ...filter,
      categoryFilters,
    };
  }

  // end
  const sort = query?.sort || '';
  const page = Number(query?.page - 1 || 0);

  const body = {
    ...ctx.query,
    text: ctx?.query?.q || ctx?.query?.text || null,
    offset: page * PAGE_SIZE_30,
    limit,
    getTotal: true,
    filter,
    sort,
    searchStrategy: DEFAULT_SEARCH_STRATEGY,
  };

  const result = await getFuzzySearchClient({ ctx, body, v2, isQuickOrder });
  if (!isValid(result)) return result;

  return mapDataProduct({ ctx, result, isConvertProduct });
};
// TODO:
export const loadDataProductWeb = async ({
  query,
  filter = {},
  isGetQuantity,
  isReplacePriceAfterVoucher = false,
  isSkuReplaceProductList = false,
}) => {
  // check currentTab -> filter
  if (query.tag) {
    if (query.extraTag) {
      filter.tags = [query.extraTag, query.tag];
      delete filter.tag;
    } else {
      filter.tag = query.tag;
    }
    // }
  } else if (query.extraTag) {
    filter.tag = query.extraTag;
  }

  if (query.manufacturer) {
    filter.manufacturer = query.manufacturer;
  }
  if (query.category) {
    filter.category = query.category;
  }

  if (query.filter) {
    let categoryFilters = [];
    try {
      categoryFilters = JSON.parse(query.filter)?.categoryFilters || [];
    } catch (e) {
      categoryFilters = [];
    }

    const combinedCategoryFilters = categoryFilters.filter((item) => item.isCombined) || [];

    categoryFilters = categoryFilters.filter(
      (cat) =>
        cat.code !== cat.categoryCodes?.[0] ||
        cat.efficacyCodes?.length > 0 ||
        combinedCategoryFilters.every((item) => !item.categoryCodes?.includes(cat.code)),
    );

    filter = {
      ...filter,
      categoryFilters,
    };
  }

  // end
  const sort = query?.sort || '';
  const page = Number(query?.page - 1 || 0);
  const searchType = Number(query?.searchType || THUOC_VA_HOAT_CHAT);
  const searchStrategy = {
    text: true,
    keyword: true,
    ingredient: searchType === THUOC_VA_HOAT_CHAT,
  };
  const body = {
    ...query,
    text: query?.q || query?.text || null,
    offset: page * PAGE_SIZE_24,
    limit: PAGE_SIZE_24,
    filter,
    sort,
    isAvailable: query?.isAvailable === 'true',
    searchStrategy,
    isReplacePriceAfterVoucher,
  };
  const result = await getFuzzySearchClient({ body, v2: true, isQuickOrder: true, isGetSalePriceLabel: true });
  if (!isValid(result)) return result;
  return mapDataProduct({ result, isProductCard: true, isGetQuantity, isSkuReplaceProductList });
};

export const loadDataProductMobileClient = async ({ page = 1, query, isEmptySearch = false }) => {
  const filter = {};
  const defaultQuerySearch = '';
  // check currentTab -> filter
  if (query.tag) {
    // if (query.tag === 'DOCQUYENGIATOT' && query.provinceCode !== undefined) {
    //   const formatQueryProvinceCode = `PROVINCE_${query.provinceCode}`;
    //   // eslint-disable-next-line no-param-reassign
    //   filter.tags = [query.tag, formatQueryProvinceCode];
    // } else {
    // eslint-disable-next-line no-param-reassign
    filter.tag = query.tag;
    // }
  }
  if (query.category) {
    filter.category = query.category;
  }
  if (query.manufacturer) {
    filter.manufacturer = query.manufacturer;
  }

  // end
  const sort = query?.sort || '';
  const searchPage = Number(page) || Number(query?.page) || 1;
  const searchType = Number(query?.searchType || THUOC_VA_HOAT_CHAT);
  const searchStrategy = {
    text: true,
    keyword: true,
    ingredient: searchType === THUOC_VA_HOAT_CHAT,
  };
  const searchText = isEmptySearch ? defaultQuerySearch : query?.q || query?.text;
  const body = {
    ...query,
    page: searchPage,
    text: searchText,
    offset: (searchPage - 1) * PAGE_SIZE,
    limit: PAGE_SIZE,
    getTotal: true,
    getPrice: true,
    filter,
    sort,
    isAvailable: query?.isAvailable === 'true',
    searchStrategy,
    // allSku: true,
  };
  const result = await getFuzzySearchClient({ body });
  if (!isValid(result)) return result;
  return mapDataProduct({ result });
};

const loadProductFuzzy = async ({
  ctx,
  offset,
  limit = PAGE_SIZE_30,
  getTotal = false,
  filter,
  text = '',
  sort = '',
  isUseRawData = false,
  isConvertProduct = true,
  isGetQuantity,
  ...params
}) => {
  const result = await getFuzzySearchClient({
    ctx,
    body: {
      text,
      offset,
      limit,
      getTotal,
      sort,
      filter,
      searchStrategy: DEFAULT_SEARCH_STRATEGY,
      ...params,
      queryOption: {
        isReplacePriceAfterVoucher: params?.isReplacePriceAfterVoucher || false,
      },
    },
  });
  if (isUseRawData) return result;

  const mappedData = await mapDataProduct({ ctx, result, isGetQuantity, isConvertProduct });
  return {
    ...mappedData,
  };
};

const loadProductWithFilter = async ({
  ctx,
  offset,
  limit = PAGE_SIZE_30,
  filter,
  text = '',
  sort = '',
  v2 = false,
  isConvertProduct = true,
  ...restProps
}) => {
  const result = await getFuzzySearchClient({
    ctx,
    body: {
      text,
      offset,
      limit,
      getTotal: true,
      sort,
      filter,
      searchStrategy: DEFAULT_SEARCH_STRATEGY,
      queryOption: {
        isReplacePriceAfterVoucher: restProps?.isReplacePriceAfterVoucher || false,
      },
    },
    isBasic: true,
    v2,
    ...restProps,
  });
  if (!isValid(result)) return result;
  return mapDataProduct({ ctx, result, isConvertProduct, doNotAttachRecommendSKUs: true });
};

const loadProductWithTag = async ({ ctx, slug, offset, ...params }) =>
  loadProductWithFilter({
    ctx,
    offset,
    filter: {
      tag: slug,
    },
    ...params,
  });

const loadProductWithIngredient = async ({ ctx, code, offset }) =>
  loadProductWithFilter({
    ctx,
    offset,
    filter: {
      ingredient: code,
    },
  });

const loadProductWithSeller = async ({ ctx, code, offset, isConvertProduct, ...params }) =>
  loadProductWithFilter({
    ctx,
    offset,
    filter: {
      seller: code,
    },
    isConvertProduct,
    ...params,
  });

async function loadProductWithCategory({ ctx }) {
  const { query } = ctx;
  const page = Number(query?.page - 1 || 0);
  const sort = query?.sort || '';
  const catInfo = await loadCategoryInfoBySlug(ctx);
  const cat = catInfo?.[0]?.code || '';
  const filter = {};
  // check currentTab -> filter
  await checkCurrentTab({ ctx, filter });
  // end
  if (cat) {
    filter.category = cat;
  }

  return loadProductWithFilter({
    ctx,
    offset: page * PAGE_SIZE_30,
    sort,
    filter,
  });
}

async function loadProductWithManufacturer({ ctx }) {
  const { query } = ctx;
  const page = Number(query?.page - 1 || 0);
  const sort = query?.sort || '';
  const manufacturerInfo = await loadManufacturerInfoBySlug(ctx);
  const manufacturer = manufacturerInfo?.[0]?.code || '';
  let filter = {};
  try {
    filter = JSON.parse(query.filter || '{}');
  } catch (e) {
    filter = {};
  }
  // check currentTab -> filter
  await checkCurrentTab({ ctx, filter });
  // end
  if (manufacturer) {
    filter.manufacturer = manufacturer;
  }
  return loadProductWithFilter({
    ctx,
    offset: page * PAGE_SIZE_30,
    sort,
    filter,
  });
}

const LIMIT = 50;
export const getProductInfoFromSkus = async ({ ctx, skus, useCache = true, getPrice = true, customerLevel, locationCode, customerId }) => {
  const body = { ctx, limit: LIMIT, useCache, getPrice };
  const skuListArray = [];
  for (let i = 0; i < skus.length; i += LIMIT) {
    skuListArray.push(skus.slice(i, i + LIMIT));
  }

  // fix chữa cháy hàm getProduct cần user customer_level + location
  if (getPrice) {
    if (!customerLevel || !locationCode) {
      const userInfo = await getAccount(ctx);
      const { level, provinceCode, customerID: id } = getFirst(userInfo, {});
      body.customerLevel = level;
      body.locationCode = provinceCode;
      body.customerId = id;
    } else {
      body.customerLevel = customerLevel;
      body.locationCode = locationCode;
      body.customerId = customerId;
    }
  }

  const responses = await Promise.all(skuListArray.map((codes) => getProducts({ ...body, codes })));
  const mapLimitSkus = {};
  const responsesLimitSkus = await Promise.all(skuListArray.map((codes) => getSkusLimit({ ctx, skuCodes: codes })));
  const listSkusLimited = [];
  responsesLimitSkus.forEach(({ data }) => {
    data
      ?.filter((item) => item?.isActive)
      ?.forEach((skuLimit) => {
        mapLimitSkus[skuLimit.itemCode] = skuLimit;
        listSkusLimited.push(skuLimit.sku);
      });
  });

  const mapSkuPurchased = {};

  const newData = [];

  responses.forEach(({ data }) => {
    data?.forEach((product) => {
      const sku = product?.sku?.itemCode;
      const limitSku = mapLimitSkus[sku] || {};
      newData.push(
        convertProductV2({ ...product, limitPerDay: limitSku?.quantity || 0, quantityPurchasedToday: mapSkuPurchased[sku]?.quantity || 0 }),
      );
    });
  });

  return {
    status: HTTP_STATUS.Ok,
    data: newData,
  };
};

export const getProductInfoFromSkusClient = async ({
  skus,
  useCache = true,
  getPrice = true,
  customerLevel = '',
  locationCode = '',
  customerId = '',
}) => {
  const body = { limit: LIMIT, useCache, getPrice };
  const skuListArray = [];
  for (let i = 0; i < skus.length; i += LIMIT) {
    skuListArray.push(skus.slice(i, i + LIMIT));
  }

  // fix chữa cháy hàm getProduct cần user customer_level + location
  if (getPrice) {
    if (!customerLevel || !locationCode) {
      const userInfo = await getAccount();
      const { level, provinceCode, customerID: id } = getFirst(userInfo, {});
      body.customerLevel = level;
      body.locationCode = provinceCode;
      body.customerId = id;
    } else {
      body.customerLevel = customerLevel;
      body.locationCode = locationCode;
      body.customerId = customerId;
    }
  }

  const responses = await Promise.all(skuListArray.map((codes) => getProducts({ ...body, codes })));
  const mapLimitSkus = {};
  const responsesLimitSkus = await Promise.all(skuListArray.map((codes) => getSkusLimit({ skuCodes: codes })));
  const listSkusLimited = [];
  responsesLimitSkus.forEach(({ data }) => {
    data
      ?.filter((item) => item?.isActive)
      ?.forEach((skuLimit) => {
        mapLimitSkus[skuLimit.itemCode] = skuLimit;
        listSkusLimited.push(skuLimit.sku);
      });
  });

  const mapSkuPurchased = {};

  const newData = [];

  responses.forEach(({ data }) => {
    data?.forEach((product) => {
      const sku = product?.sku?.itemCode;
      const limitSku = mapLimitSkus[sku] || {};
      newData.push(
        convertProductV2({ ...product, limitPerDay: limitSku?.quantity || 0, quantityPurchasedToday: mapSkuPurchased[sku]?.quantity || 0 }),
      );
    });
  });

  return {
    status: HTTP_STATUS.Ok,
    data: newData,
  };
};

export const getProductInfoFromSkusForSeller = async ({ ctx, skus }) => {
  let body = {};
  body = {
    limit: PAGE_SIZE_30,
    filter: {
      skus,
    },
  };

  const result = await getFuzzySearchClient({ ctx, body, isBasic: true, v2: true });
  if (!isValid(result)) return result;
  return mapDataProduct({ ctx, result, isGetQuantity: false, doNotAttachRecommendSKUs: true });
};

export const getProductInfoMapFromSkus = async ({ ctx, skus, useCache = true, getPrice = true, customerLevel, locationCode, customerID }) => {
  const body = { ctx, limit: LIMIT, useCache, getPrice };
  const skuListArray = [];
  for (let i = 0; i < skus.length; i += LIMIT) {
    skuListArray.push(skus.slice(i, i + LIMIT));
  }

  // fix chữa cháy hàm getProduct cần user customer_level + location
  if (!customerLevel || !locationCode) {
    const userInfo = await getAccount(ctx);
    const { level, provinceCode, customerID: id } = getFirst(userInfo, {});
    body.customerLevel = level;
    body.locationCode = provinceCode;
    body.customerId = id;
  } else {
    body.customerLevel = customerLevel;
    body.locationCode = locationCode;
    body.customerId = customerID;
  }

  const mapProducts = {};

  // get limit
  const responses = await Promise.all(skuListArray.map((codes) => getProducts({ ...body, codes })));
  const mapLimitSkus = {};
  const responsesLimitSkus = await Promise.all(skuListArray.map((codes) => getSkusLimit({ ctx, skuCodes: codes })));
  const listSkusLimited = [];
  responsesLimitSkus.forEach(({ data }) => {
    data
      ?.filter((item) => item.isActive)
      ?.forEach((skuLimit) => {
        mapLimitSkus[skuLimit.itemCode] = skuLimit;
        listSkusLimited.push(skuLimit.sku);
      });
  });

  const mapSkuPurchased = {};

  responses.forEach(({ data }) => {
    data?.forEach((product) => {
      const sku = product?.sku?.itemCode;
      const info = convertProductV2({
        ...product,
        limitPerDay: mapLimitSkus[sku]?.quantity || 0,
        quantityPurchasedToday: mapSkuPurchased[sku] || 0,
        campaign: product.campaign || {},
      });
      mapProducts[info?.sku] = info;
    });
  });

  return {
    status: HTTP_STATUS.Ok,
    data: [mapProducts],
  };
};

export const loadDataProductDetail = async ({ ctx, isConvertProduct }) => {
  const result = await loadProductDetail({
    ctx,
    params: {
      q: convertSlug(ctx.query.slug),
      queryOption: {
        isRawFuzzy: true,
        isReplacePriceAfterVoucher: true,
      },
    },
  });
  if (!isValid(result)) return result;

  return mapDataProduct({ ctx, result, isConvertProduct });
};

export const getDataProductBySlug = async ({ ctx, slug }) => {
  const result = await loadProductDetail({
    ctx,
    params: {
      q: convertSlug(slug),
      queryOption: {
        isRawFuzzy: true,
        isReplacePriceAfterVoucher: true,
      },
    },
  });
  if (!isValid(result)) return result;

  return mapDataProduct({ ctx, result });
};

export const getDataProductsBySlugs = async ({ ctx, slugs }) => {
  const results = await Promise.all(
    slugs.map((slug) =>
      loadProductDetail({
        ctx,
        params: {
          q: convertSlug(slug),
          queryOption: {
            isRawFuzzy: true,
            isReplacePriceAfterVoucher: true,
          },
        },
      }),
    ),
  );

  const result = {
    status: HTTP_STATUS.Ok,
    data: results?.filter((res) => isValid(res))?.map((productRes) => getFirst(productRes)) || [],
  };

  // filter isAvailable
  return mapDataProduct({ ctx, result, isGetQuantity: false, isAvailable: true });
  // return result;
};

export const getSettingTags = async ({ ctx, params }) => ProductClientV2.getSettingTags({ ctx, params, isBasic: true });


export const getDeals = async ({ ctx, sellerCode = '', v2 = false }) => {
  const { query } = ctx;
  // const url = FUZZY_SEARCH;
  const page = Number(query?.page - 1 || 0);
  let body = {};

  if (sellerCode) {
    body = {
      ...ctx.query,
      offset: page * PAGE_SIZE_30,
      limit: PAGE_SIZE_30,
      getTotal: true,
      getDealOnly: true,
      filter: {
        sellers: [sellerCode],
      },
      text: '',
    };
  } else {
    body = {
      ...ctx.query,
      text: ctx?.query?.q || null,
      offset: page * PAGE_SIZE_30,
      limit: PAGE_SIZE_30,
      getTotal: true,
      getDealOnly: true,
    };
  }
  body.searchStrategy = DEFAULT_SEARCH_STRATEGY;
  const result = await getFuzzySearchClient({ ctx, body, isBasic: true, v2 });
  if (!isValid(result)) return result;
  const deals = await mapDataProduct({ ctx, result, doNotAttachRecommendSKUs: true });
  const now = new Date();
  const data = getData(deals, []).filter((item) => new Date(item?.deal?.readyTime || 0) < now);
  return { ...deals, data };
};

// without ctx
export const getDealsClient = async ({ query, sellerCode = '', limit = PAGE_SIZE_30, ...restProps }) => {
  const page = Number(query?.page - 1 || 0);
  let body = {};

  if (sellerCode) {
    body = {
      ...query,
      offset: page * limit,
      limit,
      getTotal: true,
      getDealOnly: true,
      filter: {
        sellers: [sellerCode],
      },
      text: '',
    };
  } else {
    body = {
      ...query,
      text: query?.q || null,
      offset: page * limit,
      limit,
      getTotal: true,
      getDealOnly: true,
    };
  }
  const searchResult = await getFuzzySearchClient({ body, ...restProps });
  if (!isValid(searchResult)) return searchResult;
  const dealsRes = await mapDataProduct({ result: searchResult });
  // const now = new Date();
  // const data = getData(dealsRes, []).filter((item) => {
  //   if (item?.deal?.readyTime) {
  //     return new Date(item.deal.readyTime) < now;
  //   }
  //   return false;
  // });
  const data = getData(dealsRes);
  return { ...dealsRes, data };
};
export const getStoreProductClient = async ({ query, sellerCode = '', keywords = '', sectionStore = '', isDeal = false }) => {
  const page = Number(query?.page - 1 || 0);
  let body = {};

  if (sellerCode) {
    body = {
      offset: page * PAGE_SIZE_30,
      limit: PAGE_SIZE_30,
      getTotal: true,
      getDealOnly: isDeal,
      filter: {
        sellers: [sellerCode],
        sectionStore,
      },
      text: keywords,
      ...query,
    };
  } else {
    body = {
      ...query,
      text: keywords,
      offset: page * PAGE_SIZE_30,
      limit: PAGE_SIZE_30,
      getTotal: true,
      getDealOnly: isDeal,
    };
  }

  const searchResult = await getFuzzySearchClient({ body });
  if (!isValid(searchResult)) return searchResult;
  const dataRes = await mapDataProduct({ result: searchResult, doNotAttachRecommendSKUs: true });
  return { ...dataRes };
};

// TODO:
const getProductsByIds = async ({ ctx, ids = [], ...restProps }) => {
  const idsListArray = [];
  for (let i = 0; i < ids.length; i += LIMIT) {
    idsListArray.push(ids.slice(i, i + LIMIT));
  }
  const responses = await Promise.all(idsListArray.map((productIds) => ProductClientV2.getProductsByIds({ ctx, ids: productIds, ...restProps })));

  const newData = [];
  responses?.forEach((res) => {
    res?.data?.forEach((product) => {
      newData.push(product);
    });
  });

  return {
    status: HTTP_STATUS.Ok,
    data: newData,
  };
};

const getDescriptionByProductId = async ({ ctx, productId, ...restProps }) => getDescriptionById({ productId, ctx, ...restProps });

export const getProductOfSeller = async ({ ctx, params }) => {
  const result = await getProductBySeller({ ctx, params });
  if (!isValid(result)) return result;
  const data = getData(result);
  const skus = data.map(({ code }) => code);

  const productMapRes = await getProductInfoMapFromSkus({ ctx, skus });
  if (!isValid(productMapRes)) return productMapRes;
  const mapProduct = getFirst(productMapRes, {});
  const productInfo = skus.map((sku) => ({ ...mapProduct[sku] }));
  return { total: result?.total || 0, data: productInfo, status: 'OK' };
};

const getProductByCampaign = ({ ctx, text, campaignCode = null, offset = 0, limit = 30, ...restProps }) =>
  loadProductWithFilter({ ctx, text, filter: { campaign: campaignCode }, offset, limit, ...restProps });

const getDetailProductWithoutLogin = async ({ ctx, provinceCode }) => {
  const result = await getProductDetailRaw({
    ctx,
    params: {
      q: convertSlug(ctx?.query?.slug || null),
      provinceCode,
      queryOption: 'isReplacePriceAfterVoucher',
    },
  });
  if (!isValid(result)) return result;
  return mapDataProduct({ ctx, result, isGetQuantity: false, withoutLogin: true, isConvertProduct: false });
};

const getLiteProduct = async ({ ctx, provinceCode }) => {
  const result = await getProductDetailRawLite({
    ctx,
    params: {
      q: convertSlug(ctx?.query?.slug || null),
      provinceCode,
      queryOption: 'isReplacePriceAfterVoucher',
    },
  });
  if (!isValid(result)) return result;
  return mapDataProduct({ ctx, result, isGetQuantity: false, withoutLogin: true, isConvertProduct: false });
};

const getListProductWithoutLogin = async ({ ctx, provinceCode }) => {
  const result = await getTopProductsSkipLogin({
    ctx,
    params: {
      q: convertSlug(ctx?.query?.slug || null),
      provinceCode,
    },
  });
  if (!isValid(result)) return result;
  return mapDataProduct({ ctx, result, isGetQuantity: false, withoutLogin: true });
};

const getSearchSameProduct = async ({ productCode }) => {
  const body = {
    offset: 0,
    limit: 15,
    getTotal: true,
    searchStrategy: DEFAULT_SEARCH_STRATEGY,
    filter: {
      productCodes: [productCode],
    },
    queryOption: { consumedMaxQuantity: true, sellerInfo: true, getTwoLowestPricesEachSKU: false, isReplacePriceAfterVoucher: true },
  };
  const result = await getFuzzySearchClient({ body, v2: true, isReplacePriceAfterVoucher: true });
  if (!isValid(result)) return result;
  return mapDataProduct({ result, isGetQuantity: true });
};

const getComboContainingProduct = async ({ ctx, skuCode }) => {
  const result = await getCombosContainingProduct({ ctx, skuCode });
  if (!isValid(result)) return result;

  return mapDataProduct({ result, isGetQuantity: false });
};

const getSearchRecommend = async ({ q }) => {
  const result = await getRecommendationProduct({ q, limit: 15 });
  if (!isValid(result)) return result;

  return mapDataProduct({ result, isGetQuantity: false });
};

export default {
  findDiscountPercent,
  loadDataProduct,
  loadDataProductWeb,
  loadDataProductMobileClient,
  mapDataProduct,
  searchProductsQuickOrder,
  searchProductsMobile,
  loadDataQuickOrder,
  loadProductWithCategory,
  loadProductWithManufacturer,
  getListTabs,
  getProductInfoMapFromSkus,
  loadDataProductDetail,
  getSettingTags,
  getDeals,
  loadProductWithFilter,
  loadProductWithTag,
  loadProductWithIngredient,
  getProductInfoFromSkus,
  getProductsByIds,
  getProductOfSeller,
  loadProductWithSeller,
  getDescriptionByProductId,
  getProductByCampaign,
  getDataProductBySlug,
  getDataProductsBySlugs,
  loadProductFuzzy,
  convertProductV2,
  getDetailProductWithoutLogin,
  getListProductWithoutLogin,
  getProductInfoFromSkusForSeller,
  getDealsClient,
  getStoreProductClient,
  getSearchSameProduct,
  getProductInfoFromSkusClient,
  getSearchRecommend,
  getLiteProduct,
  getComboContainingProduct,
};

