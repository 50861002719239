import { TopManufacturer } from 'constants/manufacturer';
import { GET, GET_ALL, getData, isValid } from './Clients';

const PRODUCT_API_PREFIX = '/marketplace/product/v2';

export async function loadBrand({ ctx, params }) {
  const res = await GET_ALL({
    ctx,
    url: `${PRODUCT_API_PREFIX}/manufacturer/list`,
    isBasic: true,
    params,
  });
  if (!isValid(res)) {
    return [];
  }

  return res?.data
    ?.filter((item) => TopManufacturer.indexOf(item.code) >= 0)
    .sort((a, b) => (a?.name?.toLowerCase() || '').localeCompare(b?.name?.toLowerCase() || ''));
}

export async function loadCategoryList({ ctx } = {}) {
  const res = await GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/category/list`,
    isBasic: true,
    params: { getCache: true, limit: 1000 },
  });
  if (!isValid(res)) {
    return [];
  }

  return res?.data?.sort((a, b) => (a?.name?.toLowerCase() || '').localeCompare(b?.name?.toLowerCase() || ''));
}

export async function loadEfficacyList({ ctx } = {}) {
  const res = await GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/efficacy/list`,
    isBasic: true,
    params: { getCache: true, limit: 1000 },
  });
  return getData(res);
}

export async function loadEfficacyCombinedList({ ctx } = {}) {
  const res = await GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/efficacy-combined/list`,
    isBasic: true,
    params: { getCache: true, limit: 1000 },
  });
  return getData(res);
}

export async function loadCategoryInfoBySlug(ctx) {
  const { query } = ctx;
  const params = {
    q: JSON.stringify({ slug: query.slug }),
  };

  const res = await GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/category`,
    params,
    isBasic: true,
  });
  return getData(res);
}

export async function loadManufacturerInfoBySlug(ctx) {
  const { query } = ctx;
  const params = {
    q: JSON.stringify({ slug: query.slug }),
  };

  const res = await GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/manufacturer`,
    params,
    isBasic: true,
  });
  return getData(res);
}

export async function loadTagInfoBySlug(ctx) {
  const { query } = ctx;
  const params = {
    q: JSON.stringify({ slug: query.slug }),
  };
  const res = await GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/tag/list`,
    isBasic: true,
    params,
  });

  return getData(res);
}

export async function loadTabDetail({ ctx, params }) {
  return GET({
    ctx,
    url: `${PRODUCT_API_PREFIX}/tabs/active`,
    isBasic: true,
    params,
  });
}
