import { getIngredients, loadAllIngredients } from 'clients/IngredientClient';
import { CACHE_KEY, deserializeFunc, getWithExpiry, serializeFunc, setWithExpiry } from 'utils/localStorageUtils';
import { changeAlias } from 'utils/StringUtils';

const FIELDS = ['ingredientID', 'name', 'slug', 'code', 'isMedicine', 'unsignedKey'];

/**
 * Get all ingredients from cache or fetch from API
 * @returns {Promise<Array>} array of ingredients
 */
export async function getCacheAllIngredients() {
  // Case 1: cache hit, data is all ingredients
  const cachedIngredients = getWithExpiry(CACHE_KEY.INGREDIENTS);
  if (cachedIngredients?.isAll) {
    return Object.values(cachedIngredients.data).map((i) => deserializeFunc(i, FIELDS));
  }

  // Case 2: data is not all -> Fetch all data, then cache
  let data = (await loadAllIngredients()) || [];
  data = data.sort((a, b) => a.name.localeCompare(b.name));
  data.forEach((ingredient) => {
    ingredient.unsignedKey = changeAlias(ingredient.name);
  });

  const ingredients = data.reduce((acc, i) => {
    acc[i.code] = serializeFunc(i, FIELDS);
    return acc;
  }, {});

  // Update cache
  setWithExpiry(CACHE_KEY.INGREDIENTS, { data: ingredients, isAll: true });

  return Object.values(ingredients).map((i) => deserializeFunc(i, FIELDS));
}

/**
 * Retrieve ingredients by codes from cache or fetch from API
 * @param {string[]} codes
 * @returns {Promise<Record<string, object>} map of ingredients. key is ingredient code
 */
export async function getCacheIngredientsByCodes(codes) {
  const ingredients = {};

  // Step 1: Try to get cached data
  const missingCodes = [];
  const cachedIngredients = (getWithExpiry(CACHE_KEY.INGREDIENTS) || {}).data || {};
  for (const code of codes) {
    if (cachedIngredients?.[code]) {
      const ingredient = deserializeFunc(cachedIngredients[code], FIELDS);
      ingredients[code] = ingredient;
    } else {
      missingCodes.push(code);
    }
  }

  // Step 2: Fetch missing data, then cache
  if (missingCodes.length > 0) {
    const res = await getIngredients({ codes: missingCodes });

    if (res.status !== 'OK') {
      console.error('[Error] Get ingredients', res);
    } else {
      res.data.forEach((ingredient) => {
        const serializedData = serializeFunc(ingredient, FIELDS);
        cachedIngredients[ingredient.code] = serializedData;
        ingredients[ingredient.code] = deserializeFunc(serializedData, FIELDS);
      });

      // Update cache
      setWithExpiry(CACHE_KEY.INGREDIENTS, { data: cachedIngredients });
    }
  }

  return ingredients;
}
