import { POST } from './Clients';

const ACCOUNTING_API_PREFIX = '/accounting/core/v1';
const PAYMENT_WALLET = '/payment/wallet/v1';

const getDebt = ({ customerId, ctx }) =>
  POST({
    ctx,
    url: `${ACCOUNTING_API_PREFIX}/debt/list`,
    body: {
      q: {
        customerId,
      },
    },
  });

export async function getDebtCheck({ ctx, customerId }) {
  return POST({
    ctx,
    url: `${ACCOUNTING_API_PREFIX}/debt/check`,
    body: { customerId },
  });
}

const getListOrders = ({ orderId, ctx }) =>
  POST({
    ctx,
    url: `${ACCOUNTING_API_PREFIX}/debt-order/list`,
    body: {
      q: {
        orderId,
      },
    },
    debug: false,
  });

const getListTransactions = ({ orderId, debug = false, status = null, moneySource = 'CREDIT', ctx, ...restProps }) =>
  POST({
    ctx,
    url: `${PAYMENT_WALLET}/me/transaction/list`,
    body: {
      q: {
        refAll: [`ORDER/${orderId}`],
        moneySource,
        status,
        ...restProps,
      },
      debug,
    },
  });

export default {
  getDebt,
  getListOrders,
  getListTransactions,
};

