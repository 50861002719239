export const NOTIFY_TYPES = {
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_ERROR: 'FETCH_ERROR',
  INIT_WEB_SOCKET_SUCCESS: 'INIT_WEB_SOCKET_SUCCESS',
  INIT_WEB_SOCKET_FAIL: 'INIT_WEB_SOCKET_FAIL',
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  COUNT_SUCCESS: 'COUNT_SUCCESS',
  CLEAR_ALL: 'CLEAR_ALL',
};

const NotiReducer = (state, action) => {
  const { notification } = state;
  switch (action.type) {
    case NOTIFY_TYPES.INIT_WEB_SOCKET_SUCCESS:
      return {
        ...state,
        initSocket: true,
      };
    case NOTIFY_TYPES.FETCH_SUCCESS:
      return {
        ...state,
        notification: {
          important: [...action.payload.notification.important],
          product: [...action.payload.notification.product],
          promotion: [...action.payload.notification.promotion],
        },
        unread: action.payload.unread,
        total: action.payload.total,
        loading: false,
        numberNotifyWithTab: {
          important: {
            total: action.payload.importantNotify.total,
            unread: action.payload.importantNotify.unread,
          },
          product: {
            total: action.payload.productNotify.total,
            unread: action.payload.productNotify.unread,
          },
          promotion: {
            total: action.payload.promotionNotify.total,
            unread: action.payload.promotionNotify.unread,
          },
        },
      };
    case NOTIFY_TYPES.FETCH_ERROR:
      return {
        ...state,
        notification: {
          important: [],
          product: [],
          promotion: [],
        },
        unread: 0,
        total: 0,
        loading: false,
        numberNotifyWithTab: {
          important: {
            total: 0,
            unread: 0,
          },
          product: {
            total: 0,
            unread: 0,
          },
          promotion: {
            total: 0,
            unread: 0,
          },
        },
      };
    case NOTIFY_TYPES.GET_NOTIFICATIONS:
      return {
        ...state,
        notification: {
          important: notification.important,
          product: notification.product,
          promotion: notification.promotion,
        },
      };
    case NOTIFY_TYPES.CLEAR_ALL:
      return {
        ...state,
        notification: {
          important: [],
          product: [],
          promotion: [],
        },
        unread: 0,
        total: 0,
        numberNotifyWithTab: {
          important: {
            total: 0,
            unread: 0,
          },
          product: {
            total: 0,
            unread: 0,
          },
          promotion: {
            total: 0,
            unread: 0,
          },
        },
      };
    case NOTIFY_TYPES.COUNT_SUCCESS:
      return {
        ...state,
        unread: action.payload.unread,
        total: action.payload.total,
        loading: false,
      };
    default:
      return state;
  }
};

export default NotiReducer;

