import { API_HOST_DOMAIN } from 'sysconfig';
import { POST } from './Clients';

const MONITOR_API_PREFIX = '/m/c/v1';
const FRONTEND_APIS_PREFIX = '/marketplace/frontend-apis/v1';

export async function collectEvent(event, metadata, numericMetadata = null) {
  return POST({
    url: `${MONITOR_API_PREFIX}/e`,
    body: {
      event,
      metadata: convertKeyValueAsString(metadata),
      numericMetadata,
      clientUserTime: new Date(),
    },
    timeout: null,
    priority: 'low',
  });
}

export async function collectEvents(events) {
  return POST({
    url: `${MONITOR_API_PREFIX}/es`,
    body: {
      datas: events,
      clientUserTime: new Date(),
    },
    timeout: null,
    priority: 'low',
  });
}

export async function collectEventRegister(event, metadata) {
  const res = await fetch(`${API_HOST_DOMAIN}/${FRONTEND_APIS_PREFIX}/e`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      event,
      metadata: convertKeyValueAsString(metadata),
    }),
  });
  if (res.status === 'OK') {
    return res.json();
  }
}

function convertKeyValueAsString(metadata) {
  return Object.fromEntries(Object.entries(metadata).map(([key, value]) => [key, typeof value !== 'string' ? String(value) : value]));
}
