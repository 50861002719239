import CartClientV2 from 'clients/CartClientV2';
import { getFirst, isValid, isValidWithoutData } from 'clients/Clients';
import { loadDataCart, loadDataCartEncrypted, loadDataCartLiteEncrypted } from 'clients/WebServiceClient';
import { ARR_REMOVE_PRODUCT } from 'constants/Enums';
import { ERROR_CODE_CART } from 'constants/ErrorCart';
import { GIFT_IMAGE } from 'constants/Images';
import { CHECKOUT_URL, PRODUCTS_URL, QUICK_ORDER } from 'constants/Paths';
import { useAuth } from 'context/Auth';
import useModal from 'hooks/useModal';
import { useRouter } from 'next/router';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import CartService from 'services/CartService';
import mapDataProductV2 from 'services/convertNewAPIProduct';
import { getLimitOrder } from 'services/ProductServiceV2';
import { IS_ENCRYPTED_PRICE, IS_WEB_SERVICE, MAX_ITEM_IN_CART, SELLER_CODES_REQUIRE_GPP } from 'sysconfig';
import { useEnhancedReducer } from 'utils/EnhanceReducer';
import { formatCurrency } from 'utils/FormatNumber';
import gtag from 'utils/gtag';
import { getProxyImageList } from 'utils/ImageUtils';
import MonitorUtils, { mapScreenToEnum, mapSourceToEnum, MONITORING_COLLECTOR_TYPE } from 'utils/MonitorUtils';
import NotifyUtils from 'utils/NotifyUtils';
import { capitalizeText } from 'utils/StringUtils';
import { isEmpty } from 'utils/ValidateUtils';
import { useCartActions } from 'zustand-lib/useCart';
import useSearchResult from 'zustand-lib/useSearchResult';
import useSellers from 'zustand-lib/useSellers';
import { CartReducer } from './CartReducer';
import { CHECKOUT, CLEAR, FETCH_ERROR, FETCH_SUCCESS, INCREASE_BY, SELECT_ALL_ITEM, SELECT_ITEM, UN_SELECT_ALL_ITEM } from './CartType';

export const SHARED_SCREEN_NAME = {
  QUICK: 'Quick',
  CART: 'Cart',
  BUSINESS_INFORMATION: 'BusinessInformation',
  REWARD: 'Reward',
  VOUCHER: 'Voucher',
  MY_COUPON: 'MyCoupon',
  ORDER: 'Order',
  ACCUMULATION: 'Accumulation',
  ACCOUNT_INFORMATION: 'AccountInformation',
  VIEWED: 'Viewed',
  SELLER: 'Seller',
  BILL_OF_LADING: 'BillOfLading',
  INTEREST: 'Interest',
  STORE_DETAIL: 'StoreDetails',
  PRODUCT: 'Product',
  GAME_WEB: 'GameWeb',
  DASHBOARD: 'Dashboard',
  INTRODUCTION: 'Introduction',
  SIGN_IN: 'SignIn',
  SIGN_UP: 'SignUp',
  FORGOT_PASSWORD: 'ForgotPassword',
  RESET_PASSWORD: 'ResetPassword',
  LOGIN: 'Login',
  HOME: 'Home',
  DISCOVERY: 'Discovery',
  ACCOUNT: 'Account',
  CREATOR: 'Creator',
  JOURNEY: 'Journey',
  NOTIFICATION: 'Notification',
  SEARCH: 'Search',
  PRODUCT_FILTER: 'ProductFilter',
  PRODUCT_DETAIL: 'ProductDetail',
  VOUCHER_DETAIL: 'VoucherDetail',
  COUPON: 'Coupon',
  PROMOTION: 'Promotion',
  PROMOTION_DETAIL: 'PromotionDetail',
  PROFILE: 'Profile',
  JOURNEY_DELIVERY: 'JourneyDelivery',
  JOURNEY_PICK: 'JourneyPick',
  JOURNEY_DELIVERY_DIRECTION: 'JourneyDeliveryDirection',
  JOURNEY_PICK_DIRECTION: 'JourneyPickDirection',
  ORDER_DETAIL: 'OrderDetail',
  RECONCILE: 'Reconcile',
  RECONCILE_DETAIL: 'ReconcileDetail',
  HUB_RECONCILE: 'HubReconcile',
  HUB_RECONCILE_DETAIL: 'HubReconcileDetail',
  ACC_RECONCILE: 'AccReconcile',
  ACC_RECONCILE_DETAIL: 'AccReconcileDetail',
  CREATOR_ORDER_DELIVERY_BY_SCAN: 'CreatorOrderDeliveryByScan',
  CREATOR_ORDER_DELIVERY_BY_INPUT: 'CreatorOrderDeliveryByInput',
  CREATOR_ORDER_PICK_BY_SCAN_BIN: 'CreatorOrderPickByScanBin',
  CREATOR_ORDER_PICK_BY_INPUT_BIN: 'CreatorOrderPickByInputBin',
  SCAN_QR_CODE: 'ScanQRCode',
  WEB: 'Web',
  EMPTY: 'Empty',
  LEVEL_IN_FORMATION: 'LevelInformation',
  INVOICE_INFORMATION: 'InvoiceInformation',
  STATISTIC_ORDER: 'StatisticOrder',
  INTRODUCE: 'Introduce',
  MY_DISCOUNT: 'MyDiscount',
  FEED_BACK: 'Feedback',
  ACCUMULATE: 'Accumulate',
  FAQ: 'FAQ',
  FEEDBACK_DETAIL: 'FeedbackDetail',
  CREATE_FEEDBACK: 'CreateFeedback',
  FEEDBACK_PRODUCT: 'FeedbackProduct',
  PAYMENT: 'Payment',
  CONFIRM: 'Confirm',
  STORE_SECTIONS_ALL_PRODUCT: 'StoreSectionsAllProduct',
  STORE_PORTFOLIO_PRODUCT: 'StorePortfolioProduct',
  STORE_SEARCH_PRODUCTS: 'StoreSearchProducts',
  ACCUMULATE_LIST: 'AccumulateList',
  STATISTIC_PRODUCTS: 'StatisticProducts',
  STATISTIC_MANUFACTURE: 'StatisticManufacture',
  ORDER_ALL: 'OrderAll',
  ORDER_WAIT_TO_CONFIRM: 'OrderWaitToConfirm',
  ORDER_PROCESSING: 'OrderProcessing',
  ORDER_DELIVERING: 'OrderDelivering',
  ORDER_DELIVERED: 'OrderDelivered',
  ORDER_CONFIRMED: 'OrderConfirmed',
  ORDER_COMPLETED: 'OrderCompleted',
  ORDER_CANCEL: 'OrderCancel',
  BLOCK_ACCOUNT: 'BlockAccount',
  CHANGE_PASSWORD: 'ChangePassword',
  LOYALTY: 'Loyalty',
  LOYALTY_DETAIL: 'LoyaltyDetail',
  BANK: 'Bank',
  DELIVERY_INFORMATION: 'DeliveryInformation',
  DELIVERY_DETAIL: 'DeliveryDetail',
  COMPLAIN: 'Complain',
  INGREDIENT: 'Ingredient',
  INGREDIENT_DETAIL: 'IngredientDetail',
  STORE_SEARCH: 'StoreSearch',
  STORE_LIST: 'StoreList',
  STORE_FILTER: 'StoreFilter',
  PRODUCT_SAME_SELLER_LIST: 'ProductSameSellerList',
  STORE_HARD_CODE_PRODUCTS: 'StoreHardCodeProducts',
  QR_CODE: 'QRCode',
  PRODUCT_INFORMATION: 'ProductInformation',
  PRODUCT_DISCOVERY_DETAIL: 'ProductDiscoveryDetail',
  LIVE_STREAM: 'LiveStream',
  IMAGE_VIEW: 'ImageView',
  LOGIN_WITH_SMS: 'LoginWithSMS',
  ENTER_OTP: 'EnterOTP',
  PAYMENT_METHOD: 'PaymentMethod',
  ADD_NEW_INVOICE: 'AddNewInvoice',
  DELIVERY_ADDRESS_DETAIL: 'DeliveryAddressDetail',
  SHIPMENT_DETAILS: 'ShipmentDetails',
  WISH_LIST: 'WishList',
  VERIFY_OTP: 'VerifyOtp',
  YEAR_END: 'YearEnd',
  ON_BOARDING: 'OnBoarding',
  ACTIVE_NOW: 'ActiveNow',
  CAMERA_CUSTOM: 'CameraCustom',
  PREVIEW_OCR: 'PreviewOCR',
  CONFIRM_OCR: 'ConfirmOCR',
  CREATE_PASSWORD: 'CreatePassword',
  FULLSCREEN_LIVE_STREAM: 'FullscreenLiveStream',
  CAMERA_SEARCH: 'CameraSearch',
  CAMERA_SEARCH_RESULT: 'CameraSearchResult',
  COLLECTION_VOUCHER: 'CollectionVoucher',
  SELLER_ALL_PRODUCT_LIST: 'SellerAllProductListScreen',
  VIEWED_PRODUCTS: 'ViewedProducts',
  VOUCHER_SELLER: 'VoucherSeller',
  ONLINE_PAYMENT_WEBVIEW: 'OnlinePaymentWebview',
  ONLINE_PAYMENT_PROCESSING: 'OnlinePaymentProcessing',
  PAYMENT_RESULT: 'PaymentResult',
  PAYMENT_WITH_OTHER_METHOD: 'PaymentWithOtherMethod',
  COMMENT_MODAL: 'CommentModal',
  COMMENT_LIST: 'CommentList',
  SUBSTITUTES_LIST: 'SubstitutesList',
};

const ROUTE_NO_NEED_CALL_CART_LITE = ['/checkout', '/cart', '/quick-order'];

export const CartContext = createContext();

const initialState = { loading: true, firstInit: true };

export const CartContextProvider = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { updateTotalQuantity, updateTotalItems } = useCartActions();
  const router = useRouter();

  const [isShowModalMax200Item, toggleShowModalMax200Item] = useModal();
  const getSellerByCode = useSellers((state) => state.getSellerByCode);

  const giftProductRowRef = useRef(null);
  const totalSaveSectionRef = useRef(null);
  const promoApplySectionRef = useRef(null);
  const availablePromoSectionRef = useRef(null);

  const [isLoadingCart, setIsLoadingCart] = useState(false);
  const [isShowPriceFluctuation, toggleShowPriceFluctuation] = useModal();
  const [isShowRemoveInvalidPromos, toggleShowRemoveInvalidPromos] = useModal();
  const [isOpenPromoList, togglePromoList] = useState(false);
  const [isDeletingPromo, setDeletingPromo] = useState(false);
  const [isOnCheckingPromo, toggleOnCheckingPromo] = useState(false);
  const [tempRedeemApplyResult, setTempRedeemApplyResult] = useState([]);
  const [isFirstTimeGetCart, setIsFirstTimeGetCart] = useState(false);

  const sendSearchEvent = useSearchResult((state) => state.sendSearchEvent);
  const getSearchResult = useSearchResult((state) => state.getSearchResult);

  // const [state, dispatch] = useReducer(CartReducer, initialState);

  // enhance cartReducer support function getState
  const [state, dispatch, getState] = useEnhancedReducer(CartReducer, initialState);
  const clearCart = (_from) => {
    dispatch({ type: CLEAR });
    setIsLoadingCart(false);
  };

  const handleCheckout = () => {
    dispatch({ type: CHECKOUT });
  };

  const getInfoCartItem = useCallback(async ({ data, getPrice }) => {
    if (isEmpty(data)) {
      return [];
    }
    const skusReduce = data.reduce((accumulator, item) => {
      if (item?.sku) return [...accumulator, item.sku];
      return accumulator;
    }, []);

    if (skusReduce.length === 0) {
      return [];
    }

    let cartItems = data.map((item) => {
      const {
        quantity,
        skuStatus,
        salePrice,
        weight,
        productData: {
          consumedMaxQuantity: {
            maxQuantityPerDay = 0,
            quantityPurchasedToday = 0,
            maxQuantityDeal = 0,
            quantityPurchasedDeal = 0,
            maxQuantityPerOrder = 0,
            maxQuantityConsumed = 0,
          } = {},
          product: { unit, volume, name, categoryCodes, productID, imageUrls } = {},
          campaign = {},
          sku = {},
          deal = {},
          sellerInfo = {},
        } = {},
      } = item || {};

      const imagesProxy = getProxyImageList(imageUrls) || '';
      const isCampaign = Object.keys(campaign).length > 0 || false;
      const isHappeningCampaign = (campaign && campaign?.isShow) || false;
      const {
        statusData = null,
        status,
        type,
        retailPriceValue,
        skus = [],
        tags = [],
        discountPercentage,
        sellerCode = null,
        hasGift = false,
        lotDates = [],
        slug = '',
      } = sku || {};
      const tagExpired = lotDates.find((ele) => ele?.isNearExpired === true) || {};

      const { expiredDate = '', isNearExpired = false } = tagExpired;
      const newTags = (status !== 'NORMAL' && status !== 'NEAR_EXPIRATION' && [...tags, status]) || tags;
      if (isNearExpired) newTags.push('NEAR_EXPIRATION');
      // giá hợp đồng
      const isContractPrice = type === 'SKU_CONTRACT' || false;
      const messageLimitOrder = getLimitOrder(maxQuantityPerDay, maxQuantityPerOrder, unit);
      const isDeal = deal && deal?.status === 'ACTIVE' && new Date(deal?.startTime) <= new Date() ? true : null;
      const isGift = status === 'GIFT';
      let requireGPPMessage = null;
      let customMessageproduct = null;
      // product type
      // update formatedCurrency
      const dealPrice = (isDeal && deal && deal?.price) ?? null;
      let displayPrice = dealPrice ?? retailPriceValue;
      let maxQuantity = maxQuantityPerOrder;
      let productType = 'NORMAL';
      const typeItem = 'NORMAL';
      // let discountPercentage;
      let availableProducts;
      // percentage of quantity sold and sold out condition
      let percentDealSold;
      // nếu có campaign
      if (isHappeningCampaign) {
        const {
          campaign: campaignInfo,
          maxQuantityPerOrder: maxQuantityPerOrderCampaign = maxQuantityPerOrder,
          quantity: maxQuantityCampaign,
          retailPriceValue: retailPriceCampaign,
          soldQuantity = 0,
        } = campaign;

        percentDealSold = (soldQuantity / campaign?.quantity) * 100;

        if (percentDealSold < 100) {
          newTags.push('DEAL');
          // discountPercentage = findDiscountPercent(retailPriceValue, retailPriceCampaign);

          availableProducts = Math.min(maxQuantityCampaign - soldQuantity, maxQuantityPerOrderCampaign);
          if (campaignInfo?.campaignType === 'NORMAL') {
            productType = 'MEGA_DAY';
          } else if (campaignInfo?.campaignType === 'FLASH_SALE') {
            productType = 'FLASH_SALE';
            newTags.push(productType);
          }
          displayPrice = retailPriceCampaign;
          maxQuantity = maxQuantityCampaign;
        } else {
          productType = 'NORMAL';
          customMessageproduct = `Bạn đã bỏ lỡ giá khuyến mãi ${formatCurrency(retailPriceCampaign)}`;
        }
      } else if (isDeal) {
        productType = 'DEAL';

        newTags.push(productType);
        availableProducts = deal?.maxQuantity - deal?.quantity || 0;
        percentDealSold = ((deal?.quantity || 0) / deal?.maxQuantity) * 100;
      } else {
        productType = 'NORMAL';
        availableProducts = maxQuantityPerDay;
      }
      if (SELLER_CODES_REQUIRE_GPP?.length > 0 && sellerCode && SELLER_CODES_REQUIRE_GPP.indexOf(sellerCode) >= 0) {
        requireGPPMessage = 'Yêu cầu cung cấp GPP và giấy phép kinh doanh';
      }
      // TODO: ẩn nhà cung cấp

      let { errorCode = '' } = item;
      const isNearOutOfStock = skuStatus === 'LIMIT' || false;
      if (!errorCode && maxQuantityPerDay > 0 && quantity > maxQuantityPerDay - quantityPurchasedToday) {
        // eslint-disable-next-line no-param-reassign
        errorCode = 'MAX_QUANTITY';
      }

      const disableAddTocart =
        errorCode === 'NOT_ACTIVE_SKU' ||
        errorCode === 'SUSPENDED_SKU' ||
        errorCode === 'OUT_OF_STOCK_SKU' ||
        errorCode === 'CART_ITEM_INVALID' ||
        errorCode === 'NOT_AVAILABLE_SKU' ||
        errorCode === 'NOT_FOUND_SKU' ||
        errorCode === 'NOT_FOUND_PRICE_SKU' ||
        errorCode === 'COMBO_INVALID' ||
        errorCode === ERROR_CODE_CART.REQUIRED_CERTIFICATE ||
        false;

      /*
      // nếu item (cart item ) là deal thì có thêm 1 field là  salePrice ( giá gốc )
      // nếu không là deal thì chỉ có price
      nhưng khi hiển thị ở cartProductBuy lại chỉ check salePrice , nên nếu
      Updaate chỗ này để nếu có saleprice thì lấy giá saleprice , còn nếu ko có thì lấy giá price theo cartItem
      salePrice: item.salePrice || item.price,
  */

      const info = {
        ...item,
        salePrice,
        errorCode,
        defaultImage: isGift ? imagesProxy[0] || GIFT_IMAGE : imagesProxy[0] || '',
        displayPrice,
        displayPriceFormated: formatCurrency(displayPrice) || '',
        dealPrice,
        imagesProxy: isGift ? imagesProxy || [GIFT_IMAGE] : imagesProxy,
        volume,
        unit,
        name,
        slug,
        deal,
        isDeal,
        isGift,
        disableAddTocart,
        productId: productID, // for insider
        requireGPPMessage,
        isCampaign,
        availableProducts: availableProducts || 0,
        productTags: tags || [],
        tags: newTags || null,
        productSkuType: status,
        weight,
        statusData,
        isVAT: tags?.indexOf('HOADONNHANH') >= 0 || false,
        limitPerDay: maxQuantityPerDay,
        maxQuantityPerDay,
        quantityPurchasedToday,
        discountPercent: discountPercentage || 0,
        messageLimitOrder,
        categoryCodes,
        campaign,
        isNearOutOfStock,
        isContractPrice,
        skus,
        isTagGift: hasGift,
        expiredDate,
        sellerInfo,
        consumedMaxQuantity: item?.productData?.consumedMaxQuantity || {},
      };
      // khi add khuyến mãi là các gift quà tặng thì auto sellected là false luôn,
      function isGiftSellect(giftSellected) {
        if (giftSellected) return Object.assign(info, { isSelected: false });
        return null;
      }
      isGiftSellect(isGift);
      return info;
    });

    cartItems = cartItems.sort((a, b) => (a.errorCode && !b.errorCode ? -1 : 1));

    return cartItems;
  });

  // const getTotalCartItem = async () => {};
  // const updateCartFirstTime = async () => {};

  const getCartItemsInfo = async (cartData = {}) => {
    const { cartItems = [] } = cartData || {};
    const [cartItemsInfo] = await Promise.all([getInfoCartItem({ data: cartItems, getPrice: true })]);

    return { ...cartData, cartItems: cartItemsInfo };
  };

  // reload cart
  const reloadDataCart = async ({ cartRes, successMessage, errorMessage, isUpdate = false, isLoadFromStart = false }) => {
    // Don't use CartContext in /cart, because it is using useCartState
    if (router.pathname === '/cart') {
      return;
    }

    try {
      if (isLoadFromStart) setIsLoadingCart(true);
      if (cartRes && !isValidWithoutData(cartRes)) {
        const [cartResponse] = getFirst(cartRes, []);
        switch (cartRes.errorCode) {
          case 'MAX_QUANTITY':
            // [ [ {quantity} ] ]
            if (cartResponse?.quantity > 0) {
              NotifyUtils.error(`${cartRes.message}, Số lượng hàng còn lại là ${cartResponse.quantity}`);
            } else {
              NotifyUtils.error(`${cartRes.message}`);
            }
            break;
          case 'PAYLOAD_VALIDATE':
            if (cartRes.message === 'Type must be one of [NORMAL DEAL COMBO CAMPAIGN]') {
              NotifyUtils.error(`Không thể thêm sản phẩm là Quà tặng vào giỏ hàng`);
            } else {
              NotifyUtils.error('Đã có lỗi xảy ra, vui lòng thử lại');
            }
            break;
          case 'INVALID_SESSION_TOKEN':
            NotifyUtils.error(`Tài khoản đã bị đăng xuất, xin vui lòng đăng nhập lại.`);
            break;
          default:
            if (cartRes && cartRes.message) {
              NotifyUtils.error(`${cartRes.message}`);
            } else if (errorMessage) {
              NotifyUtils.error(errorMessage);
            }
            return;
        }
      }
      if (successMessage) NotifyUtils.success(successMessage);
      const params = {};
      params.queryOption = 'price,consumedMaxQuantity,sellerInfo';
      params.getVoucherAuto = false; // default is false, for old app can use
      params.screen = '';
      if (router.pathname === '/checkout') {
        params.queryOption += ',getPaymentMethod';
      }

      if (!isFirstTimeGetCart && router.pathname === '/cart') {
        params.getVoucherAuto = true;
        setIsFirstTimeGetCart(true);
      } else {
        params.getVoucherAuto = false;
      }

      if (router.pathname === CHECKOUT_URL) {
        params.screen = SHARED_SCREEN_NAME.PAYMENT;
      }

      let cartResult = {};

      const isRouteNoNeedCallCartLite = ROUTE_NO_NEED_CALL_CART_LITE.includes(router.pathname);
      if (isRouteNoNeedCallCartLite) {
        if (IS_ENCRYPTED_PRICE) {
          cartResult = IS_WEB_SERVICE ? await loadDataCartEncrypted(params) : await CartClientV2.loadDataCart();
        } else {
          cartResult = IS_WEB_SERVICE ? await loadDataCart(params) : await CartClientV2.loadDataCart();
        }
      } else {
        cartResult = await loadDataCartLiteEncrypted();
      }

      let cartData = getFirst(cartResult);
      if (!cartData) {
        clearCart('7');
        return;
      }

      // FIXME: temp code to sync quantity of useCart and useCartState
      updateTotalQuantity(cartData.totalQuantity);
      updateTotalItems(cartData.totalItem);

      // decrypt cart items
      if (cartData.cartItems && cartData.cartItems?.length > 0 && IS_ENCRYPTED_PRICE && IS_WEB_SERVICE) {
        cartData = CartService.convertCartItem(cartData);
      }

      /*
       chỉ trang cart cần load lại data product , còn  khi các action khác chỉ cần update lại thông tin cartItem thôi là dc
      */
      const { cartItems = [], redeemApplyResult = [], maxWeight = 0, maxVolume = 0, gifts = [] } = cartData || {};
      // const giftArray = redeemApplyResult?.[0]?.gifts || [];
      const hasGiftArray = redeemApplyResult
        ?.filter((item) => item?.gifts && item?.canUse === true)
        ?.map((item) => ({
          ...item,
          gifts: item?.gifts.map((gift) => ({ ...gift })),
        }));
      const giftList = [];
      hasGiftArray.forEach((ele) => {
        giftList.push(...ele.gifts);
      });

      // const arrayHashmap = giftList.reduce((obj, item) => {
      //   const newObj = obj;

      //   if (newObj[item?.sku]) {
      //     newObj[item.sku].quantity += item.quantity;
      //   } else {
      //     newObj[item.sku] = { ...item };
      //   }

      //   return newObj;
      // }, {});

      // const giftArray = Object.values(arrayHashmap);

      const [cartItemsInfo, giftInfo] = await Promise.all([
        getInfoCartItem({ data: cartItems, getPrice: true }),
        // getPromoInfo({ voucherCode: redeemCode[0] }),
        getInfoCartItem({ data: gifts, getPrice: false }),
      ]);

      // thêm isGift vào để phân biệt
      const giftsInfo = giftInfo?.map((item) => ({ ...item, isGift: true, type: 'GIFT' }));

      cartData.cartItems = [...cartItemsInfo, ...giftsInfo];
      setTempRedeemApplyResult(cartData?.redeemApplyResult); // init tempRedeemApplyResult

      /* Check error cart item */
      cartData.isErrorCartItem =
        cartData?.cartItems?.some((item) => item?.isSelected && item?.errorCode && item?.errorCode !== ERROR_CODE_CART.CHANGED_PRICE) || false;
      cartData.havingItemSelected = !!cartData?.cartItems?.find((item) => item?.isSelected === true);
      cartData.isItemInArrayRemove = cartData?.cartItems?.some((item) => ARR_REMOVE_PRODUCT.indexOf(item?.errorCode) >= 0) || false;
      cartData.nonVatItems = cartData?.nonVatItems || {};
      cartData.vatItems = cartData?.vatItems || {};
      cartData.transactionDetail = cartData?.transactionDetail || {};
      cartData.paymentFeeConfigs = cartData?.paymentFeeConfigs || [];
      if (maxVolume > 0 || maxWeight > 0) {
        const { totalWeight, totalVolume, maxWidth, maxHeight, maxLength } = cartData || {};

        const isOverWeightOrVolume = (maxWeight > 0 && totalWeight >= maxWeight) || (maxVolume > 0 && totalVolume >= maxVolume);
        cartData.isOverWeightOrVolume = isOverWeightOrVolume;
        let messageOverWeight = '';
        let messageOverVolume = '';
        if (maxWeight > 0 && totalWeight >= maxWeight) {
          messageOverWeight = `Giỏ hàng hiện tại vượt quá ${maxWeight}kg.`;
          if (isUpdate) NotifyUtils.error(`Tổng khối lượng của đơn hàng đã vượt quá ${maxWeight}kg`);
        }
        if (maxVolume > 0 && totalVolume >= maxVolume) {
          let maxVolumeCalc = '';
          if (maxWidth > 0 && maxLength > 0 && maxHeight > 0) {
            maxVolumeCalc = `${maxLength}x${maxWidth}x${maxHeight}`;
          }
          messageOverVolume = `Giỏ hàng hiện tại vượt quá quy cách đóng gói ${maxVolumeCalc}`;
          if (totalVolume >= maxVolume) {
            NotifyUtils.error(`Giỏ hàng hiện tại đã vượt quá quy cách đóng gói ${maxVolumeCalc}`);
          }
        }
        if (isOverWeightOrVolume) cartData.messageOverWeightOrVolume = `${messageOverWeight}${messageOverVolume}`;
      }

      setIsLoadingCart(false);
      dispatch({ type: FETCH_SUCCESS, payload: cartData || [] });
    } catch (error) {
      NotifyUtils.error(error.message || 'Tải giỏ hàng thất bại');
      dispatch({ type: FETCH_ERROR });
      setIsLoadingCart(false);
    }
  };

  // TODO: change to reloadCart
  const updateCart = async () => {
    // const cartRes = await CartClientV2.loadDataCart();
    // if (!isValid(cartRes)) {
    //   dispatch({ type: FETCH_ERROR });
    //   return;
    // }
    await reloadDataCart({ isLoadFromStart: true });
  };

  useEffect(() => {
    async function fetchData() {
      await updateCart();
    }
    try {
      if (isAuthenticated) {
        fetchData();
      }
    } catch (error) {
      clearCart('9');
    }
  }, [isAuthenticated, router?.pathname]);

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      setIsFirstTimeGetCart(false);
    });

    return () => {
      router.events.off('routeChangeStart', () => {
        setIsFirstTimeGetCart(false);
      });
    };
  }, [router.events]);

  const mapDataProduct = (products = []) => {
    const { mapQuantity = new Map() } = getState();
    const processData = (data) =>
      data?.map((item) => ({
        ...item,
        imagesProxy: getProxyImageList(item?.imageUrls) || [],
        // unit: item.unit && item.unit === '<nil>' ? '' : item.unit,
        quantity: mapQuantity.get(item.sku) || 0,
      }));
    return IS_WEB_SERVICE ? processData(products) : processData(mapDataProductV2({ product: products }));
  };
  const updateCartItem = async (payload, reload = false, isReloadData = false, isNotify = true) => {
    if (!payload?.product?.sku && isNotify) {
      NotifyUtils.error('Không tìm thấy sản phẩm cần thêm.');
      return null;
    }

    const { cartItems, cartNo } = getState();

    const info = cartItems?.find((item) => item.sku === payload?.product?.sku);
    const sellerInfo = await getSellerByCode({ code: payload?.product?.sellerCode });

    const dataUpdate = {
      ...payload,
      cartNo,
      sellerID: sellerInfo?.sellerID || null,
      metadata: {
        block_code: payload?.product?.blockCode,
      },
    };

    dataUpdate.page = info?.page || router.pathname.replace('/', '') || 'home';

    dataUpdate.searchKey = info ? info.searchKey : router.query?.q || router.query?.text;

    dataUpdate.eventSource = mapSourceToEnum(payload?.product || {}, router.pathname) || '';
    dataUpdate.eventScreen = mapScreenToEnum(payload?.product || {}, router.pathname) || '';

    dataUpdate.host = window?.location?.host || '';
    dataUpdate.recommendSKUs = payload?.product?.recommendSKUs;

    let cartRes = await CartClientV2.updateCartItem(dataUpdate);

    if (reload) {
      setTimeout(() => {
        reloadDataCart({
          cartRes,
          isReloadData,
          isUpdate: true,
        });
      }, 0);
    }

    if (isValid(cartRes)) {
      // FB event tracking addToCart
      // fbpixel.addToCart(payload.product);

      if (!info?.quantity && payload?.product?.isRecommendedByTS) {
        // 0 -> 1: send tracking collector event
        MonitorUtils.sendSKUEvent(
          MONITORING_COLLECTOR_TYPE.ADD_TO_CART,
          { ...payload.product, quantity: dataUpdate?.q || 0 },
          window.location.pathname,
        );
      }

      try {
        const searchResult = getSearchResult();
        if (
          [PRODUCTS_URL, QUICK_ORDER].includes(router?.pathname) &&
          searchResult?.[router?.query?.text || router?.query?.q]?.data?.[payload.product.productId]
        ) {
          sendSearchEvent(router?.query?.text || router?.query?.q);
        }
      } catch (err) {
        console.error(err);
      }

      gtag.addToCart({ ...payload.product, quantity: dataUpdate?.q || 0, sellerInfo });
      gtag.addToCartInPage({ ...payload.product, quantity: dataUpdate?.q || 0, sellerInfo }, router.pathname);

      if (isNotify) {
        if (info?.quantity > dataUpdate?.q) {
          NotifyUtils.success(`Số lượng sản phẩm ${capitalizeText(dataUpdate.product.name)} đã được giảm.`);
        } else {
          NotifyUtils.success(`Số lượng sản phẩm ${capitalizeText(dataUpdate.product.name)} đã được tăng.`);
        }
      }
    } else if (cartRes.errorCode === 'CART_MAX_QUANTITY' || cartRes.errorCode === 'MAX_QUANTITY') {
      try {
        // get quanity can add from response and compare with maxQuantity
        const [{ quantity = dataUpdate.product.maxQuantity }] = getFirst(cartRes, []);
        dataUpdate.q = quantity;
        cartRes = await CartClientV2.updateCartItem(dataUpdate);
        // if (isValid(cartRes)) {
        //   NotifyUtils.success(`Đã cập nhật ${capitalizeText(dataUpdate.product.name)} thành công`);
        // } else {
        //   NotifyUtils.error(`Cập nhật sản phẩm thất bại`);
        // }
        dispatch({ type: INCREASE_BY, payload: dataUpdate });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }

    return cartRes;
  };

  const updateCartItemQuickOrder = async (quantity, product, reload = false, isNotify = true, isReloadData = false) => {
    const { id, skuCode, sellerId, trackingMetadata } = product;

    // Check if skuCode is missing and show error notification if needed
    if (!skuCode && isNotify) {
      NotifyUtils.error('Không tìm thấy sản phẩm cần thêm.');
      return null;
    }

    const { cartItems, cartNo } = getState();

    // Find the cart item matching the SKU code
    const info = cartItems?.find((item) => item.sku === skuCode);

    // Prepare parameters for updating cart item
    const params = {
      product,
      cartNo,
      sellerID: sellerId,
      page: info?.page || router.pathname.replace('/', '') || 'home',
      eventSource: mapSourceToEnum(product || {}, router.pathname) || '',
      eventScreen: mapScreenToEnum(product || {}, router.pathname) || '',
      host: window?.location?.host || '',
      blockCode: product?.blockCode,
      metadata: {
        ...(trackingMetadata || {})
      }
    };
    let cartRes;

    try {
      // Update the cart item quantity
      cartRes = await CartClientV2.updateCartItemQuickOrder(params, quantity);

      // Reload data if specified
      if (reload) {
        setTimeout(() => {
          reloadDataCart({
            cartRes,
            isReloadData,
            isUpdate: true,
          });
        }, 0);
      }

      // Check if cartRes is valid
      if (isValid(cartRes)) {
        const searchResult = getSearchResult();

        // Check if router pathname matches specific routes
        if ([PRODUCTS_URL, QUICK_ORDER].includes(router?.pathname) && searchResult?.[router?.query?.text || router?.query?.q]?.data?.[id]) {
          sendSearchEvent(router?.query?.text || router?.query?.q);
        }

        gtag.addToCart({ ...product, quantity: quantity || 0 });
        gtag.addToCartInPage({ ...product, quantity: quantity || 0 }, router.pathname);

        // Show success notification based on quantity change
        if (isNotify) {
          if (info?.quantity > quantity) {
            NotifyUtils.success(`Số lượng sản phẩm ${capitalizeText(params.product.name)} đã được giảm.`);
          } else {
            NotifyUtils.success(`Số lượng sản phẩm ${capitalizeText(params.product.name)} đã được tăng.`);
          }
        }
      } else if (cartRes.errorCode === 'CART_MAX_QUANTITY' || cartRes.errorCode === 'MAX_QUANTITY') {
        // Handle case where quantity exceeds maximum
        const [{ quantity = params.product.maxQuantity }] = getFirst(cartRes, []);

        cartRes = await CartClientV2.updateCartItemQuickOrder(params, quantity);
        dispatch({ type: INCREASE_BY, payload: params });
      }
    } catch (error) {
      console.error(error);
    }

    return cartRes;
  };

  // clone from updateCardItem add skip call API when payload larger than the quantity required
  const updateCartItemMV2 = async (payload, reload = false, isReloadData = false) => {
    if (!payload?.product?.sku) {
      NotifyUtils.error('Không tìm thấy sản phẩm cần thêm.');
      return null;
    }

    const { cartItems, cartNo } = getState();
    const info = cartItems?.find((item) => item.sku === payload?.product?.sku);

    const dataUpdate = { ...payload, cartNo };

    dataUpdate.page = info?.page || router.pathname.replace('/', '') || 'home';

    dataUpdate.searchKey = info ? info.searchKey : router.query?.q || router.query?.text;

    dataUpdate.eventSource = mapSourceToEnum(payload?.product || {}, router.pathname) || '';
    dataUpdate.eventScreen = mapScreenToEnum(payload?.product || {}, router.pathname) || '';

    dataUpdate.host = window?.location?.host || '';

    const cartRes = await CartClientV2.updateCartItem(dataUpdate);

    if (reload) {
      reloadDataCart({
        cartRes,
        isReloadData,
        isUpdate: true,
      });
    }

    if (isValid(cartRes)) {
      // FB event tracking addToCart
      // fbpixel.addToCart(payload.product);

      gtag.addToCart({ ...payload.product, quantity: dataUpdate?.q || 0 });
      // add seller info
      const sellerInfo = await getSellerByCode({ code: payload.product?.sellerCode });
      gtag.addToCartInPage({ ...payload.product, quantity: dataUpdate?.q || 0, sellerInfo }, router.pathname);

      NotifyUtils.success(`Đã cập nhật ${capitalizeText(dataUpdate.product.name)} thành công`);
    } else if (cartRes.errorCode === 'CART_MAX_QUANTITY' || cartRes.errorCode === 'MAX_QUANTITY') {
      return {
        status: cartRes.errorCode,
        maxQuantity: getFirst(cartRes, [0])[0]?.quantity || 0,
      };
    }

    return cartRes;
  };

  // update for excel
  const updateCartItems = async (items) => {
    items.forEach((item) => {
      const { sku, quantity } = item || {};
      updateCartItem({ product: { sku }, q: quantity });
    });
  };

  // update for excel
  const increase = async (payload) => {
    const cartRes = await CartClientV2.updateCartItem({
      ...payload,
      // blockCode: payload.blockCode,
      metadata: {
        blockCode: payload.blockCode,
      },
      eventSource: mapSourceToEnum({}, router.pathname) || '',
      eventScreen: mapScreenToEnum({}, router.pathname) || '',
      host: window?.location?.host || '',
    });
    reloadDataCart({
      cartRes,
      successMessage: 'Thêm sản phẩm thành công',
      errorMessage: 'Cập nhật sản phẩm thất bại',
    });
  };

  const decrease = async (payload) => {
    const cartRes = await CartClientV2.updateCartItem({
      ...payload,
      eventSource: mapSourceToEnum({}, router.pathname) || '',
      eventScreen: mapScreenToEnum({}, router.pathname) || '',
      host: window?.location?.host || '',
    });
    reloadDataCart({
      cartRes,
      successMessage: 'Đã cập nhật giỏ hàng,',
      errorMessage: 'Cập nhật sản phẩm thất bại',
    });
  };

  const removeCartItem = async (
    { sku, isRecommended = false, isSameCategoryProduct = false, blockCode },
    isReloadData = false,
    isShowMessage = true,
  ) => {
    const payload = { sku };
    const { cartItems, cartNo } = getState();

    const info = cartItems.find((item) => item.sku === sku);

    // :TODO: LOGS info for activity
    payload.cartNo = cartNo;
    payload.name = info?.name;
    payload.slug = info?.slug;
    // payload.productCode = info?.productCode;
    payload.productId = info?.productId;
    payload.salePrice = info?.salePrice;
    payload.sellerCode = info?.sellerCode;
    payload.cartNo = info?.cartNo;
    payload.cartItemType = info?.cartItemType;
    payload.type = info?.type || null;
    payload.eventSource = mapSourceToEnum({ isRecommended, isSameCategoryProduct }, router.pathname) || '';
    payload.eventScreen = mapScreenToEnum({ isRecommended, isSameCategoryProduct }, router.pathname) || '';
    payload.host = window?.location?.host || '';
    payload.metadata = {
      block_code: blockCode,
    };
    // end log

    const sellerInfo = await getSellerByCode({ code: payload?.sellerCode });

    const cartRes = await CartClientV2.removeCartItem({ ...payload, sellerID: sellerInfo?.sellerID || null });
    // if (cartRes?.status === 'OK') {
    //   MonitorUtils.sendSKUEvent(MONITORING_COLLECTOR_TYPE.REMOVE_FROM_CART, payload);
    // }

    if (isReloadData) {
      reloadDataCart({
        cartRes,
        successMessage: isShowMessage ? `Sản phẩm ${capitalizeText(payload.name)} đã được xóa ra khỏi giỏ hàng` : '',
        errorMessage: isShowMessage ? 'Xoá sản phẩm thất bại' : '',
        isReloadData,
      });
    }
    return cartRes;
  };

  const removeCartItemQuickOrder = async (
    { blockCode, skuCode, isRecommended = false, isSameCategoryProduct = false, sellerId, trackingMetadata },
    isReloadData = false,
    isShowMessage = true,
  ) => {
    try {
      const { cartItems, cartNo } = getState();
      const info = cartItems.find((item) => item.sku === skuCode);

      // Log activity info
      const payload = {
        sku: skuCode,
        cartNo,
        name: info?.name,
        slug: info?.slug,
        productId: info?.productId,
        salePrice: info?.salePrice,
        sellerCode: info?.sellerCode,
        cartItemType: info?.cartItemType,
        blockCode,
        type: info?.type || null,
        eventSource: mapSourceToEnum({ isRecommended, isSameCategoryProduct }, router.pathname) || '',
        eventScreen: mapScreenToEnum({ isRecommended, isSameCategoryProduct }, router.pathname) || '',
        host: window?.location?.host || '',
        sellerID: sellerId || null,
        metadata: {
          ...(trackingMetadata || {})
        },
      };

      const cartRes = await CartClientV2.removeCartItem(payload);

      if (isReloadData) {
        const successMessage = isShowMessage ? `Sản phẩm ${capitalizeText(payload.name)} đã được xóa ra khỏi giỏ hàng` : '';
        const errorMessage = isShowMessage ? 'Xoá sản phẩm thất bại' : '';
        reloadDataCart({ cartRes, successMessage, errorMessage, isReloadData });
      }

      return cartRes;
    } catch (error) {
      console.error('Error removing item from cart:', error);
      return null; // Or handle error as needed
    }
  };

  const removeAllCartItems = async (isReloadData = false, isShowMessage = true) => {
    // add isDeleteAll to remove all items in cart
    const payload = { isDeleteAll: true };
    const { cartNo } = getState();
    // :TODO: LOGS info for activity
    payload.cartNo = cartNo;
    // payload.eventSource = mapSourceToEnum({ isRecommended, isSameCategoryProduct }, router.pathname) || '';
    // payload.eventScreen = mapScreenToEnum({ isRecommended, isSameCategoryProduct }, router.pathname) || '';
    // end log
    const cartRes = await CartClientV2.removeCartItem(payload);

    if (isReloadData) {
      reloadDataCart({
        cartRes,
        successMessage: isShowMessage ? `Xoá giỏ hàng thành công` : '',
        errorMessage: isShowMessage ? 'Xoá giỏ hàng thất bại' : '',
        isReloadData,
      });
    }
    return cartRes;
  };

  const addImportant = async (payload) => {
    const cartRes = await CartClientV2.updateCartItemImportant({
      eventSource: mapSourceToEnum({}, router.pathname) || '',
      eventScreen: mapScreenToEnum({}, router.pathname) || '',
      sku: payload.sku,
      isImportant: true,
      type: payload.type,
      host: window?.location?.host || '',
    });
    updateCart({
      cartRes,
      successMessage: 'Đánh dấu quan trọng thành công ',
      errorMessage: 'Đánh dấu quan trọng sản phẩm thất bại',
    });
  };

  const removeImportant = async (payload, isReloadData = true) => {
    const cartRes = await CartClientV2.updateCartItemImportant({
      eventSource: mapSourceToEnum({}, router.pathname) || '',
      eventScreen: mapScreenToEnum({}, router.pathname) || '',
      sku: payload.sku,
      isImportant: false,
      type: payload.type,
      host: window?.location?.host || '',
    });
    if (isReloadData)
      updateCart({
        cartRes,
        successMessage: 'Xoá đánh dấu quan trọng thành công',
        errorMessage: 'Xoá đánh dấu quan trọng thất bại',
      });
  };

  const updateDeliveryInfo = async (body) => {
    const res = await CartClientV2.updateDeliveryMethod({
      ...body,
    });
    if (!isValid(res)) {
      return;
    }
    updateCart();
  };

  const updateDeliveryMethod = async ({
    deliveryMethod,
    customerDistrictCode,
    customerProvinceCode,
    customerWardCode,
    customerShippingAddress,
    info, // logs info
  }) => {
    const { cartNo } = getState();

    const res = await CartClientV2.updateDeliveryMethod({
      deliveryMethod,
      customerDistrictCode,
      customerProvinceCode,
      customerWardCode,
      customerShippingAddress,
      ...info,
      cartNo,
    });
    if (!isValid(res)) {
      NotifyUtils.error(res?.message || 'Cập nhật phương thức giao hàng thất bại ');
      return;
    }
    NotifyUtils.success('Cập nhật phương thức giao hàng thành công');
    updateCart();
  };

  const updatePaymentMethod = async ({
    paymentMethod,
    customerDistrictCode,
    customerProvinceCode,
    customerWardCode,
    info, // for logs
  }) => {
    const { cartNo } = getState();
    const res = await CartClientV2.updatePaymentMethod({
      paymentMethod,
      customerDistrictCode,
      customerProvinceCode,
      customerWardCode,
      ...info, // for log
      cartNo,
    });
    if (!isValid(res)) {
      NotifyUtils.error(res.message || 'Cập nhật phương thức thanh toán thất bại ');
      return;
    }
    NotifyUtils.success('Cập nhật phương thức thanh toán thành công');
    updateCart();
  };

  const updateRefuseSplitOrder = async ({ isRefuseSplitOrder }) => {
    const res = await CartClientV2.updateRefuseSplitOrder({
      isRefuseSplitOrder,
    });
    if (!isValid(res)) {
      NotifyUtils.error(res.message || 'Cập nhật lại hình thức nhận hàng thất bại ');
      return;
    }
    NotifyUtils.success('Cập nhật lại hình thức nhận hàng thành công ');
    updateCart();
  };

  const updateInvoice = async (body) => {
    const result = await CartClientV2.updateCart({ body });
    if (!isValid(result)) {
      NotifyUtils.error(result.message || 'Cập nhật thông tin xuất hoá đơn thất bại ');
    }
  };

  const updateCartInfo = async (body) => {
    const result = await CartClientV2.updateCart({ body });
    if (!isValid(result)) {
      NotifyUtils.error(result.message || 'Cập nhật thông tin thất bại');
    } else {
      updateCart();
    }
  };

  const reOrderById = async ({ orderId }) => {
    const result = await CartService.reOrder({ orderId });
    if (isValid(result)) {
      updateCart();
    } else {
      NotifyUtils.error(result.message || 'Không thể đặt lai đơn hàng này');
    }
    return result;
  };

  // APO-8
  const selectCartItem = async ({ sku, skus, isSelected, isReloadCart, name, quantity }) => {
    dispatch({ type: SELECT_ITEM, payload: { sku, skus, isSelected } });
    if (isReloadCart) {
      reloadDataCart({
        successMessage: 'Thêm sản phẩm thành công',
        errorMessage: 'Cập nhật sản phẩm thất bại',
      });
    }
    const result = await CartService.selectCartItem({ sku, skus, isSelected, cartNo: state?.cartNo || '', quantity, name });
    // update lai cart sau khi sellected
    await updateCart();
    return result;
  };

  const selectAllCartItem = async ({ isFetchData = true }) => {
    dispatch({ type: SELECT_ALL_ITEM });
    const result = await CartService.selectAllCartItem({ cartNo: state?.cartNo || '' });
    if (isFetchData) updateCart();
    return result;
  };

  const unselectAllCartItem = async () => {
    dispatch({ type: UN_SELECT_ALL_ITEM });
    const result = await CartService.unSelectAllCartItem({ cartNo: state?.cartNo || '' });
    updateCart();
    return result;
  };

  const verifyPayment = async (body) => {
    const rs = await CartClientV2.verifyPayment({ body });
    return rs;
  };
  // chặn không quá 200 item
  const validate200Item = (sku) => {
    const { mapQuantity } = getState();
    const quantity = mapQuantity?.get(sku) || 0;
    if (quantity === 0 && mapQuantity.size >= MAX_ITEM_IN_CART) {
      NotifyUtils.error(`Giỏ hàng chỉ chứa tối đa ${MAX_ITEM_IN_CART} mặt hàng`);
      // toggleShowModalMax200Item();
      return false;
    }
    return true;
  };

  const handleScrollToGiftRow = () => {
    giftProductRowRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
  };

  // support multi voucher
  const multiVoucherHandleRemovePromo = async (currentCode, listCurrentPromo = []) => {
    const { cartNo } = getState();
    let res;
    if (listCurrentPromo.length === 1) {
      res = await CartClientV2.updateRedeemCode([], cartNo);
    } else {
      const listCodeWithoutCurrentCode = listCurrentPromo?.map((item) => item?.code)?.filter((item) => item !== currentCode);
      res = await CartClientV2.updateRedeemCode(listCodeWithoutCurrentCode, cartNo);
    }

    return res;
  };

  // currentPromoCode will be []
  const multiVoucherHandleRemovePromoV2 = async (currentPromoCode) => {
    const { cartNo } = getState();
    const res = await CartClientV2.putRemoveMultiRedeem(currentPromoCode, cartNo);

    return res;
  };

  const multiVoucherHandleChangePromo = async (currentCode, listCurrentPromo = []) => {
    const { cartNo } = getState();
    let res;
    if (listCurrentPromo.length === 0) {
      res = await CartClientV2.updateRedeemCode([currentCode], cartNo);
    } else {
      const listCode = listCurrentPromo?.map((item) => item?.code);
      res = await CartClientV2.updateRedeemCode([...listCode, currentCode], cartNo);
    }

    return res;
  };

  // currentPromoCode will be []
  const multiVoucherHandleChangePromoV2 = async (currentPromoCode) => {
    const { cartNo } = getState();
    const res = await CartClientV2.putChangeMultiRedeem(currentPromoCode, cartNo);

    return res;
  };

  return (
    <CartContext.Provider
      value={{
        mapDataProduct,
        removeCartItem,
        removeCartItemQuickOrder,
        removeAllCartItems,
        updateCartItem,
        updateCartItemQuickOrder,
        updateCartItemMV2,
        increase,
        decrease,
        clearCart,
        handleCheckout,
        addImportant,
        removeImportant,
        updateCart,
        updateDeliveryMethod,
        updatePaymentMethod,
        reloadDataCart,
        getCartItemsInfo,
        updateInvoice,
        updateDeliveryInfo,
        updateCartInfo,
        reOrderById,
        updateCartItems,
        selectCartItem,
        selectAllCartItem,
        unselectAllCartItem,
        verifyPayment,
        validate200Item,
        toggleShowModalMax200Item,
        isShowModalMax200Item,
        giftProductRowRef,
        handleScrollToGiftRow,
        isLoadingCart,
        getState,
        isShowPriceFluctuation,
        toggleShowPriceFluctuation,
        getInfoCartItem,
        updateRefuseSplitOrder,
        multiVoucherHandleRemovePromo,
        multiVoucherHandleChangePromo,
        multiVoucherHandleChangePromoV2,
        multiVoucherHandleRemovePromoV2,
        isShowRemoveInvalidPromos,
        toggleShowRemoveInvalidPromos,
        totalSaveSectionRef,
        promoApplySectionRef,
        availablePromoSectionRef,
        isOpenPromoList,
        togglePromoList,
        isDeletingPromo,
        setDeletingPromo,
        isOnCheckingPromo,
        toggleOnCheckingPromo,
        tempRedeemApplyResult,
        setTempRedeemApplyResult,
        setIsFirstTimeGetCart,
        ...state,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
