import { GET } from './Clients';

const FRONTEND_APIS_PREFIX = '/marketplace/frontend-apis/v1';
const MARKETING_API_PREFIX = '/marketplace/marketing/v1';

export async function getListHashtagSearch({ ctx, isActive = true, limit = 100, isBasic = false } = {}) {
  return GET({
    ctx,
    url: `${MARKETING_API_PREFIX}/hashtag-search/list`,
    params: {
      limit,
      q: JSON.stringify({ isActive }),
    },
    isBasic,
  });
}

export async function getCountdownBarList({ ctx, isBasic = true } = {}) {
  return GET({
    ctx,
    url: `${MARKETING_API_PREFIX}/countdownbar/list`,
    isBasic,
  });
}

export async function getMetaThumbnailList({ ctx, isBasic = true } = {}) {
  return GET({
    ctx,
    url: `${MARKETING_API_PREFIX}/meta-thumbnail/list`,
    isBasic,
  });
}

export async function getMenuBar({ ctx, isBasic = true } = {}) {
  return GET({
    ctx,
    url: `${MARKETING_API_PREFIX}/menu-bar`,
    isBasic,
  });
}

export async function getInsiderSetting({ ctx, isBasic = true } = {}) {
  return GET({
    ctx,
    url: `${MARKETING_API_PREFIX}/insider-setting`,
    isBasic,
  });
}

export async function getChatSetting({ ctx, isBasic = true } = {}) {
  return GET({
    ctx,
    url: `${MARKETING_API_PREFIX}/setting?key=chat_config`,
    isBasic,
  });
}

export async function getListBanner(ctx) {
  return GET({
    ctx,
    url: `${MARKETING_API_PREFIX}/banners/available`,
  });
}

export async function getBannerNano(ctx, params) {
  return GET({
    ctx,
    url: `${MARKETING_API_PREFIX}/banners/available`,
    params,
  });
}

export async function getCampaignList({ ctx, params } = {}) {
  return GET({
    ctx,
    url: `${MARKETING_API_PREFIX}/customer/campaign-box/list`,
    params,
  });
}

export async function getCampaignListV2(params) {
  return GET({
    url: `${MARKETING_API_PREFIX}/customer/campaign-box/list`,
    params,
  });
}

export async function getProductFiles({ q = {}, isMienBac = false }) {
  return GET({
    url: '/web/sanphammoi',
    params: { q: JSON.stringify(q), isMienBac },
    mock: true,
    isAuth: false,
  });
}

export async function getProductPriceVolatility({ q = {}, regionCode, offset = 0, limit = 1000, getTotal }) {
  return GET({
    url: `${FRONTEND_APIS_PREFIX}/sku/price-volatility/list`,
    params: { q: JSON.stringify(q), offset, limit, regionCode, getTotal },
  });
}

export async function getCustomerMiniBannerList({ ctx, q = {}, offset = 0, limit = 300, isBasic = false }) {
  return GET({
    ctx,
    url: `${MARKETING_API_PREFIX}/customer/mini-banner/list`,
    params: { q: JSON.stringify(q), offset, limit },
    isBasic,
  });
}
