import DateTimeUtils from 'utils/DateTimeUtils';
import { GET, POST, PUT } from './Clients';

const FRONTEND_APIS_PREFIX = '/marketplace/frontend-apis/v1';
const TICKET_API_PREFIX = '/marketplace/ticket/v1';

export async function createFeedback(body) {
  return POST({
    url: `${TICKET_API_PREFIX}/me/ticket`,
    body,
  });
}

export async function createFeedbackWithoutLogin(body) {
  return POST({
    url: `${TICKET_API_PREFIX}/guest/ticket`,
    body,
    isBasic: true,
    isAuth: false,
  });
}

export async function getListReasonsWithoutLogin({ ctx } = {}) {
  return GET({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/ticket/reason-setting/list`,
    params: { ticketReasonType: 'CUSTOMER_TICKET' },
    isBasic: true,
    isAuth: false,
  });
}

export async function getTicketList({ ctx, offset, limit, getTotal, createdFrom, createdTo, type, status, reason, orderId }) {
  const params = {
    offset,
    limit,
    getTotal,
  };

  const q = {};

  if (createdFrom) {
    q.createdFrom = DateTimeUtils.getDateStart(new Date(createdFrom)).toISOString();
  }
  if (createdTo) {
    q.createdTo = DateTimeUtils.getDateEnd(new Date(createdTo)).toISOString();
  }
  if (type) {
    q.type = type;
  }
  if (status) {
    q.status = status;
  }
  if (reason) {
    q.reasonCodes = reason;
  }
  if (orderId) {
    q.orderId = parseInt(orderId, 10);
  }

  params.q = JSON.stringify(q);
  return GET({
    ctx,
    url: `${TICKET_API_PREFIX}/me/ticket/list`,
    params,
  });
}

export async function getDetailTicket({ ctx, ticketId }) {
  return GET({
    ctx,
    url: `${TICKET_API_PREFIX}/me/ticket`,
    params: {
      ticketId,
    },
  });
}

export async function getDetailTicketClient({ ticketId }) {
  return GET({
    url: `${TICKET_API_PREFIX}/me/ticket`,
    params: {
      ticketId,
    },
  });
}

export async function sendFeedback(body) {
  return POST({
    url: `${TICKET_API_PREFIX}/me/ticket/feedback`,
    body,
  });
}

export async function acceptFeedback(body) {
  return PUT({
    url: `${TICKET_API_PREFIX}/me/ticket/accept`,
    body,
  });
}

export async function getTicketReturn(orderId) {
  return GET({
    url: `${TICKET_API_PREFIX}/return-ticket/self-list`,
    params: { q: JSON.stringify({ orderId }) },
  });
}

export async function getVerifyTickerReturn(orderId) {
  return GET({
    url: '/drugstore/inventory/v1/inbound-session/verify-complete',
    params: { orderId },
  });
}

export async function postRatingTicket(body) {
  return POST({
    url: `${TICKET_API_PREFIX}/me/ticket/evaluate`,
    body,
  });
}
