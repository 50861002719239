import { STATIC_STORAGE } from 'sysconfig';
import { GIFT_IMAGE_DEFAULT_CACHED, NEW_MISSING_IMAGE_CACHED, STORE_IMAGE_DEFAULT_CACHED } from './default';

const { NEXT_PUBLIC_CDN_PREFIX } = process.env;

export const BRAND_LOGO_SVG = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/logo/brand-logo.svg`;
export const THUOCSI_HEADER_LOGO_SVG = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/logo/logo_home.svg`;

export const BANNER = `${STATIC_STORAGE}/img/banner.jpg`;

export const BANNER1 = `${STATIC_STORAGE}/images/app_ads_buymed.png`;
export const BANNER2 = `${STATIC_STORAGE}/images/banner2.1_buymed.png`;
export const BANNER3 = `${STATIC_STORAGE}/images/app_convert_buymed.png`;

export const PHONENEW = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/home/FooterPhoneNew.svg`;
export const PHONE_FOOTER = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/home/footer-phone.png`;

export const SANPHAMMOI_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/sanphammoi.png`;
export const DOUBLE_ARROW_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/double_arrow.svg`;
export const LIKE_ICON_GREEN = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/like_green.png`;
export const DEALS_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/deals.png`;
export const TICKET_PROMO_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/ticket_promo.svg`;

export const PRODUCT_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/product_icon.png`;
export const ADD_FILE_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/icon/add_file.png`;

export const KG_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/icon/kg.svg`;
export const KG_ICON_V2 = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/icon/kg_v2.svg`;
export const EYE_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/icon/eye.svg`;
export const SHOPPING_CART_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/icon/shopping-cart.svg`;

export const WISHLIST_ICON_1 = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/wishlist_icon_1.svg`;
// export const WISHLIST_ICON_2 = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ''}/icons/wishlist_icon_2.svg`;
// export const WISHLIST_ICON_3 = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ''}/icons/wishlist_icon_3.svg`;
export const WISHLIST_ICON_4 = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/wishlist_icon_4.svg`;

// OTHER
export const LOGO_THUOCSI = `${NEXT_PUBLIC_CDN_PREFIX || ''}/logo_ts.png`;
export const LOGO_THUOCSI_SHORTENED = `${NEXT_PUBLIC_CDN_PREFIX || ''}/thuocsi_short.svg`;
// export const MISSING_IMAGE = DEFAULT_MISSING_IMAGE;
export const MISSING_IMAGE = NEW_MISSING_IMAGE_CACHED;
export const GIFT_IMAGE = GIFT_IMAGE_DEFAULT_CACHED;
export const LOGO_NOT_MATCH_CONDITIONS = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/chua_thoa_dk.svg`;
export const GIFT_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/gift.svg`;
export const STORE_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/deals/store.svg`;
// export const FAST_DELIVERY_ICON = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/images/deals/fast-delivery.svg`;
export const MOBILE_STORE_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/deals/mobile_store.svg`;

// Khuyen mai
export const MEGA_SALE_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/deals/mega_sale.svg`;
export const FLASH_SALE_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/deals/flash_sale_new.svg`;
export const DISCOUNT_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/deals/discount_icon.svg`;

// FOOTER_V2
export const CALL_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/footer-v2/Contact/call.svg`;
// export const FB_ICON = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/images/footer-v2/Contact/fbicon.svg`;
export const SUPPORT_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/footer-v2/Contact/support.svg`;
export const APPSTORE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/footer-v2/Download/appstore.svg`;
export const URL_APPSTORE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/footer-v2/Download/appstore.svg`;
export const GOOGLEPLAY = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/footer-v2/Download/googleplay.svg`;
export const URL_APPSTORE_V2 = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/footer-v2/Download/appstore_v2.png`;
export const GOOGLEPLAY_V2 = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/footer-v2/Download/googleplay_v2.png`;
export const AHAMOVE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/footer-v2/ShipService/ahamove.png`;
export const GHN = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/footer-v2/ShipService/ghn.png`;
export const GHTK = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/footer-v2/ShipService/ghtk.png`;
export const GRAB = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/footer-v2/ShipService/grab.png`;
export const NINJAVAN = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/footer-v2/ShipService/ninjavan.png`;
export const PHONEFRAME = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/footer-v2/phoneFrame.svg`;
export const FACEBOOK_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/footer-v2/Connect/facebook_icon.svg`;
export const LINKED_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/footer-v2/Connect/linked_icon.svg`;
export const TIKTOK_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/footer-v2/Connect/tiktok_icon.svg`;
export const ZALO_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/footer-v2/Connect/zalo_icon.svg`;
// export const LICENSE = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/images/footer-v2/license.png`;
// logo ship new
export const URL_LICENSE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/footer/logo_register.png`;
// export const URL_LICENSE_TRANSPARENT = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/images/footer-v2/license.png`;

export const NOTICEV2_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/header-v2/Notice_icon.svg`;
export const AVATARV2_ICON_IMAGE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/header-v2/UserAvatar_icon.svg`;
export const AVATARV2_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/header-v2/UserAvatar_icon.svg`;
export const NEWSV2_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/header-v2/New_icon.svg`;
export const CAREERV2_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/header-v2/Career_icon.svg`;
export const HOMEV2_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/header-v2/Home_icon.svg`;

export const CHAT_LIVE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/home/chatLive.svg`;
export const B_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/header-v2/Home_icon.svg`;
export const SEARCHV2_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/header-v2/Search_icon.svg`;
export const SEARCHV2ACTIVE_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/header-v2/SearchActive_icon.svg`;

// FOOTER
export const LOGO_FOOTER_SVG = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/footer/logo_footer.svg`;
export const LOGO_FOOTER_REGISTER = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/footer/logo_register2.png`;

export const LINK_REGISTER = `http://online.gov.vn/Home/WebDetails/52192`;
export const LINK_REGISTER_MOBILE = `http://online.gov.vn/Home/AppDetails/2786`;
export const LINK_APPLESTORE = `https://apps.apple.com/vn/app/thuocsi/id1518730923`;
export const LINK_CYSTACK = `https://cystack.net/projects/buymed`;
export const LINK_GOOGLEPLAY = 'https://play.google.com/store/apps/details?id=com.buymed.app&hl=en_US';
// export const LINK_LICENSE = `https://buymed-storage.s3-ap-southeast-1.amazonaws.com/trading_license/1.+Trading+License+-+Buymed+(GC+20+June+2019)+(VN).pdf`;

// promotion

// New version 2

// export const CARTV2_ICON = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/images/header-v2/Cart_icon.svg`;
export const CARTV2_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/header-v2/Cartv2_icon.svg`;
export const DELETEV2_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/iconV2/deleteIcon.svg`;
// Level Icon
export const DIAMOND_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/icons/diamond.svg`;
export const GOLD_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/icons/gold.svg`;
export const PLATINUM_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/icons/platinum.svg`;
export const SILVER_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/icons/silver.svg`;

// export const GIFT_IMAGE =
//   `https://assets.thuocsi.vn/assets/defaults/qua_tang-d6b9623d40a9924ac56d42815f5ed983f93ef06a88ec91c36261bb3bbb222553.jpg`;
export const LOGO_PHARMACY = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/default/pharmacy.svg`;

// seller
export const STORE_IMAGE_DEFAULT = STORE_IMAGE_DEFAULT_CACHED;

// infoTabsV2
export const USER_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/user.svg`;
export const USER_ICON_ACTIVE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/userActive.svg`;
export const BILL_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/billoflading.svg`;
export const BILL_ICON_ACTIVE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/billofladingActive.svg`;
export const FEEDBACK_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/feedback.svg`;
export const FEEDBACK_RED_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/feedback_red.svg`;
export const FEEDBACK_ICON_ACTIVE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/feedBackActive.svg`;

export const ORDER_ICON_MENU = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/myOrder.svg`;
export const ORDER_ICON_MENU_ACTIVE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/myOrderActive.svg`;

export const POINTS_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/points.svg`;
export const POINTS_ICON_ACTIVE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/pointsActive.svg`;
export const REFER_FRIEND_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/referFriends.svg`;
export const REFER_FRIEND_ICON_ACTIVE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/referFriendsActive.svg`;
export const STATISTICAL_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/statistical.svg`;
export const STATISTICAL_ICON_ACTIVE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/statisticalActive.svg`;

export const WISHLIST_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/wishlist.svg`;
export const WISHLIST_ICON_ACTIVE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/wishlistActive.svg`;

export const VIEWED_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/viewed.svg`;
export const VIEWED_ICON_ACTIVE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/viewedActive.svg`;

export const QR_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/qr.svg`;
export const QR_ICON_ACTIVE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/qrActive.svg`;

export const QUEST_REWARDS_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/quest_rewards.svg`;
export const QUEST_REWARDS_ICON_WHITE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/quest_reward_white.svg`;
export const QUEST_REWARDS_ICON_ACTIVE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/quest_rewards_active.svg`;
export const LEVEL_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/level_icon.svg`;
export const LEVEL_ICON_ACTIVE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/level_icon_active.svg`;
export const VOUCHER_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/voucher_icon.svg`;
export const VOUCHER_ICON_ACTIVE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/voucher_icon_active.svg`;

export const LOGOUT_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/logout.svg`;

export const USER_ICON_WHITE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/account_white.svg`;
export const TRUCK_ICON_WHITE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/truck_white.svg`;
export const FEEDBACK_ICON_WHITE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/feedback_white.svg`;
export const ORDER_ICON_MENU_WHITE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/order_white.svg`;
export const POINTS_ICON_WHITE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/point_white.svg`;
export const REFER_FRIEND_ICON_WHITE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/refer_white.svg`;
export const STATISTICAL_ICON_WHITE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/static_white.svg`;
export const WISHLIST_ICON_WHITE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/heart_white.svg`;
export const VIEWED_ICON_WHITE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/viewed_white.svg`;
export const QR_ICON_WHITE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/qr_white.svg`;
export const LEVEL_ICON_WHITE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/level_white.svg`;
export const VOUCHER_ICON_WHITE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/voucher_white.svg`;
export const LOGOUT_ICON_WHITE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/infoTabsV2/logout_white.svg`;

// export const FILE_NEW_PRODUCT = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/static/files/newproduct.pdf`;
// export const FILE_NEW_PRODUCT = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/static/files/sanphammoi.pdf`;
// export const TRUCK_ICON_WHITE = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/images/infoTabsV2/truck_white.svg`;

// export const FILE_NEW_PRODUCT_MIEN_BAC = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/static/files/sanphammoi_mienbac.pdf`;
// export const FILE_NEW_PRODUCT_MIEN_NAM = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/static/files/sanphammoi_miennam.pdf`;

// export const FILE_ALTERNATIVE_PRODUCT_MIEN_BAC = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/static/files/san_pham_thay_the_mien_bac.pdf`;
// export const FILE_ALTERNATIVE_PRODUCT_MIEN_NAM = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/static/files/san_pham_thay_the_mien_nam.pdf`;

// /myOrder icon
export const ReturnOrderIcon = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/ReturnOrderIcon.svg`;
// block account
export const BLOCK_ACCOUNT = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/block_account.png`;
export const MAIL_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/mail.png`;

// logoV2
export const NEW_LOGO = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/logoV2.svg`;
export const URL_NEW_LOGO = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/logoV2.svg`;
export const LOGO_CIRCLE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/LogoCircle_thuocsi.svg`;

//
export const DELIVERY_FROM = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/shipping-policy/delivery-form.png`;
export const DELIVERY_APOLOGIZE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/shipping-policy/apologize-delivery.png`;

// 404
export const IMAGE_404 = `${NEXT_PUBLIC_CDN_PREFIX || ''}/assets/404/404.svg`;
export const LOGO_THUOCSI_404 = `${NEXT_PUBLIC_CDN_PREFIX || ''}/assets/404/newLogoThuocsi.svg`;
export const ICON_404_MAIL = `${NEXT_PUBLIC_CDN_PREFIX || ''}/assets/404/mail.svg`;
export const ICON_404_PHONE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/assets/404/phone.svg`;
// loyalty v2
export const VOUCHER_TITLE_DETAIL = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/loyaltyv2/giftPromotion.svg`;
export const VOUCHER_TITLE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/loyaltyv2/voucherTS.svg`;
// export const VOUCHER_TS_IMG = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/images/loyaltyv2/voucherThuocsi_img.svg`;
// export const VOUCHER_FRAME = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/images/loyaltyv2/voucherFrame.svg`;
export const LOYALTY_ARROW_VIEW_MORE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/loyaltyv2/loyalty_arrow_bottom.svg`;
// export const VOUCHER_TS_MIN = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/images/loyaltyv2/voucherTS_min.svg`;
export const VOUCHER_TS_MIN = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/loyaltyv2/voucherNew.svg`;
export const VOUCHER_FRAME_MIN = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/loyaltyv2/voucher_frame_min.svg`;
export const TimeOrderStatus = `${NEXT_PUBLIC_CDN_PREFIX || ''}/orderStatus/time.svg`;
// export const ICON_VAT_ORDER = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/orderStatus/iconVAT.svg`;
// export const ICON_EXPORT_XLS_ORDER = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/orderStatus/iconExportXLS.svg`;
export const ICON_FEEDBACK_ORDER = `${NEXT_PUBLIC_CDN_PREFIX || ''}/orderStatus/iconFeedback.svg`;
export const ICON_ARROW_ORDER = `${NEXT_PUBLIC_CDN_PREFIX || ''}/orderStatus/arrowOrder.svg`;
export const ICON_ARROW_ORDER_ACTIVE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/orderStatus/arrowOder_active.svg`;
export const VOUCHER_TITLE_GREEN = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/loyaltyv2/voucherTSgreen.svg`;
export const VOUHCER_PROMOTION_BOX = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/loyaltyv2/giftBox.svg`;
// orderdetail v2
export const ReAddToCartV2 = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/detailOrderV2/addtocartV2.svg`;
export const EditDetailV2 = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/detailOrderV2/editOrderV2.svg`;
export const I_WAIT_TO_CONFIRM = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/detailOrderV2/wait_to_confirm.svg`;
// export const I_PROCESSING = `${NEXT_PUBLIC_CDN_PREFIX ? NEXT_PUBLIC_CDN_PREFIX : ""}/images/detailOrderV2/wait_to_confirm.svg`;
export const I_DELIVERING = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/detailOrderV2/delivering.svg`;
export const I_DELIVERED = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/detailOrderV2/delivered.svg`;
export const I_TRANSPORTING = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/detailOrderV2/transporting.svg`;
export const I_FILE_CHECKED = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/detailOrderV2/file_checked.svg`;
export const I_EDIT_ORDER_V2 = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/detailOrderV2/edit_order_v2.svg`;
export const I_PROCESSING_V2 = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/detailOrderV2/processing.svg`;

export const ICON_DELIVERING = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/icon-delivering.svg`;
export const ILLUSTRATE_DELIVERY = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/illustrate-delivery.svg`;

export const I_CONFIRMED = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/detailOrderV2/confirmed.svg`;
export const I_COMPLETED = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/detailOrderV2/completed.svg`;
export const I_EXIT = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/detailOrderV2/exit.svg`;
export const I_FEEDBACK_DETAILV2 = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/detailOrderV2/feedbackOrderV2.svg`;
export const I_RETURN_ORDER_DETAILV2 = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/detailOrderV2/returnOrderV2.svg`;
export const I_ARROW_RIGHT_DETAILV2 = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/detailOrderV2/arrowRight.svg`;
export const EDIT_INVOICE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/detailOrderV2/editInvoice.svg`;
export const UP_INVOICE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/detailOrderV2/iconUP.svg`;
// loyalty v2
export const SLIVER_ICON_RANK = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/loyalty-v2/loyalty_rank_sliver.svg`;
export const GOLD_ICON_RANK = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/loyalty-v2/loyalty_gold_rank.svg`;
export const PLATINUM_ICON_RANK = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/loyalty-v2/loyalty_rank_platinum.svg`;
export const DIAMOND_ICON_RANK = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/loyalty-v2/loyalty_diamond_rank.svg`;

export const SLIVER_ICON_RANK_ACTIVE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/loyalty-v2/rank_sliver_active.svg`;
export const DIAMOND_ICON_RANK_ACTIVE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/loyalty-v2/rank_diamond_active.svg`;
export const PLATINUM_ICON_RANK_ACTIVE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/loyalty-v2/rank_platinum_active.svg`;
export const GOLD_ICON_RANK_ACTIVE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/loyalty-v2/rank_gold_active.svg`;
export const SECTION_STORE_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/pill.png`;

// cart -> popup promo
export const VOUCHER_TS_PROMO = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/logoV2.svg`;

// mobile layout
export const MOBILE_ICON_ALERT_SUCCESS = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/mobile/alert/success.svg`;
export const MOBILE_ICON_RATING_STAR = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/mobile/conversations/rating_star.svg`;
export const MOBILE_ICON_RATING_STAR_FILL = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/mobile/conversations/rating_star_fill.svg`;
export const MOBILE_ICON_RATING_STAR_NOT_FILL = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/mobile/conversations/rating_star_not_fill.svg`;
export const MOBILE_ICON_RATING_SUCCESS = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/mobile/conversations/rating_success.svg`;
export const MOBILE_ICON_ATTACH_FILE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/mobile/conversations/icon_attach_file.svg`;
// detail product
export const SAME_STORE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/mobile/detail-product/same_store.svg`;
export const SAME_INGREDIENT = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/mobile/detail-product/same_ingredient.svg`;
// wishlist v2
export const I_WISHLIST_DEL = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/wishlist_del_icon.svg`;
export const DELETE_VOUCHER_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/delete_voucher.svg`;
export const GIFT_ICON2 = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/gift_icon.svg`;

// new gift
export const GIFT_NEW_GREEN = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/gift_icon.svg`;

// thankyou page
export const ORDER_SUCCESS_THANKYOU = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/thankyou/order_success.svg`;
export const ORDER_SUCCESS_THANKYOU_GIF = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/thankyou/thankyou.gif`;
export const THANKYOU_ICON_SUCCESS = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/thankyou/iconSuccess.svg`;
export const THANKYOU_ICON_PEN = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/thankyou/iconPen.svg`;
export const THANKYOU_ICON_NOTI = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/thankyou/iconNoti.svg`;
export const THANKYOU_ICON_WAIT = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/thankyou/iconWait.svg`;
export const THANKYOU_ICON_INFO_SUCCESS = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/thankyou/iconInfoCircle.svg`;

export const ICON_KHUNG_LONG = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/khunglong.svg`;

export const HOME_BG_MEGA_SALE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/mega-sale-bacground.svg`;
export const HOME_ICON_MEGA_SALE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/mega-sale.svg`;

// new icon

export const HOME_ICON_CHECK_IN_PIN = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/home/iconCheckInPin.svg`;
export const HOME_ICON_CITY_NEW = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/home/iconCityNew.svg`;
export const HOME_ICON_HOME_NEW = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/home/iconHomeNew.svg`;
export const HOME_ICON_PHONE_NEW = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/home/iconPhoneNew.svg`;
export const HOME_ICON_SEARCH_NEW = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/home/iconSearchNew.svg`;
export const HOME_ICON_USER_NEW = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/home/iconUserCircle.svg`;
export const HOME_ICON_PRODUCT_NEW = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/home/badges_new_product.svg`;
export const HOME_ICON_PRODUCT_COMPANY = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/home/badges_company_product.svg`;
export const HEART_HOME = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/home/heart.svg`;
export const HELP = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/home/help.svg`;
export const WHITE_HOUSE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/home/home.svg`;
export const NOTI = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/home/noti.svg`;
export const PAPER = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/home/paper.svg`;
export const HIRING = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/home/hiring.svg`;
export const AVATAR = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/home/avatar.svg`;
// my ticket
export const MYTICKET_ICON_ARROW_BOTTOM = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/my-ticket/arrow-down.svg`;
export const MYTICKET_ICON_CALENDAR = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/my-ticket/calendar.svg`;
export const MYTICKET_ICON_CREATE_REQUEST = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/my-ticket/create-request.svg`;
export const MYTICKET_ICON_WARNING = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/my-ticket/warning.svg`;
export const MYTICKET_ICON_UPLOAD = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/my-ticket/upload.svg`;
export const MYTICKET_ICON_FEEDBACK_USER = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/my-ticket/user.svg`;
export const MYTICKET_BG_TAB_MID = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/my-ticket/tab-bg-mid.svg`;
export const MYTICKET_BG_TAB_LEFT = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/my-ticket/tab-bg-left.svg`;
export const MYTICKET_BG_TAB_RIGHT = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/my-ticket/tab-bg-right.svg`;
export const MYTICKET_ICON_FEEDBACK_TS = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/my-ticket/ts-logo.svg`;
export const MYTICKET_ICON_CLEAR = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/my-ticket/clear.svg`;

// update cart page
export const CART_ICON_INCREASE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/cart/increase_icon.svg`;
export const CART_ICON_DECREASE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/cart/decrease_icon.svg`;
export const CART_ICON_ALERT_PRICE_FLUCTUATION = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/cart/warning_price_fluctuation.svg`;

export const TOUR_BANNER_MOBILE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/imageTourMobile.png`;
export const TOUR_BANNER = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/imageTourWeb.png`;
export const TOUR_HOMEPAGE_ONE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/home/guide/guide_one.png`;

export const REWARD_PAYOUT = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/home/guide/reward_payout.svg`;
export const CLOSE_DATE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/home/guide/close_date.svg`;
export const INVOICE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/home/guide/invoice.svg`;
export const EXCHANGE_SAMPLE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/home/guide/exchange_sample.svg`;

// lucky wheel
export const LUCKYWHEEL_BORDER = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/spinner/wheel_without_center.svg`;
export const LUCKYWHEEL_BORDER_WITH_CENTER = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/spiner/wheel_with_center.svg`;
export const LUCKYWHEEL_CENTER = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/spinner/wheel_center.svg`;
export const LUCKYWHEEL_ARROW_SELECTOR = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/spinner/selector.svg`;
export const LUCKYWHEEL_TOP_SPIN_BUTTON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/spinner/top_button_spin.svg`;
export const LUCKYWHEEL_BOTTOM_SPIN_BUTTON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/spinner/bottom_button_spin.svg`;
export const LUCKWHEEL_ICON_WARNING = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/spinner/icon_popup_warning.svg`;
export const LUCKYWHEEL_BACKGROUND = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/spinner/spinner_bg.svg`;
export const LUCKYWHEEL_TIME_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/spinner/time_icon.svg`;
export const LUCKYWHEEL_GUILDLINE_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/spinner/guildline_icon.svg`;
export const LUCKYWHEEL_TITLE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/spinner/wheel_title.png`;
export const LUCKYWHEEL_MOBILE_MISSION_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/spinner/mission_shadow_icon.svg`;
export const LUCKYWHEEL_MOBILE_GUILDLINE_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/spinner/guildline_shadow_icon.svg`;
export const LUCKYWHEEL_MOBILE_HISTORY_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/spinner/history_shadow_icon.svg`;
export const LUCKYWHEEL_MOBILE_TAB_BACKGROUND = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/spinner/mobile_tab_background.svg`;
export const LUCKYWHEEL_MOBILE_CLOSE_BUTTON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/spinner/mobile_close_button.svg`;
export const LUCKYWHEEL_MOBILE_PRIZE_BG = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/spinner/mobile_popup_prize_bg.svg`;
export const LUCKYWHEEL_MISSION_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/spinner/mission_icon.svg`;
export const LUCKYWHEEL_MOBILE_SPIN_TEXT = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/spinner/mobile_spin_text.png`;
export const LUCKYWHEEL_MOBILE_OOT_TEXT = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/spinner/mobile_oot.png`;
export const LUCKYWHEEL_MOBILE_POPUP_MISSION_BG = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/spinner/mobile_popup_mission_bg.png`;
export const LUCKYWHEEL_MOBILE_OOT_TEXT_V2 = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/spinner/mobile_oot_v2.png`;
export const LUCKYWHEEL_MOBILE_SPIN_TEXT_V2 = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/spinner/mobile_spin_text_v2.png`;
export const LUCKYWHEEL_BLOCKED_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/spinner/blocked_icon.svg`;
export const LUCKYWHEEL_DEFAULT_BACKGROUND = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/spinner/spinner_bg.png`;

// level ava
export const SLIVER = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/level/sliver.svg`;
export const PLATINUM = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/level/platinum.svg`;
export const DIAMOND = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/level/diamond.svg`;
// level profile
export const SLIVER_PROFILE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/level_new/sliver_new.svg`;
export const GOLD_PROFILE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/level_new/gold_new.svg`;
export const PLATINUM_PROFILE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/level_new/platinum_new.svg`;
export const DIAMOND_PROFILE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/level_new/diamond_new.svg`;
export const REWARD_PROFILE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/level_new/reward.svg`;
export const GUIDE_WISH_ITEM = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/level_new/guide.png`;
export const HERE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/level_new/here.gif`;
export const HEART = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/level_new/heart.svg`;
export const DEBT = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/level_new/debt.svg`;
export const ARROW = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/level_new/arrow.svg`;
//
export const SLIVER_ICON_V2 = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/level/sliver_icon.svg`;
export const PLATINUM_ICON_V2 = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/level/platinum_icon.svg`;
export const DIAMOND_ICON_V2 = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/level/diamond_icon.svg`;

export const LUCKYWHEEL_SHARE_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/spinner/popup_share_icon.png`;
export const LUCKYWHEEL_SHARE_FACEBOOK_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/spinner/facebook_icon.svg`;
export const LUCKYWHEEL_SHARE_ZALO_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/spinner/zalo_mobile_icon.svg`;

// new promo-codes page
export const PROMOCODE_CHECK_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/promoCode/check_icon.svg`;
export const PROMOCODE_REFERRAL_BG = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/promoCode/referral_bg.svg`;
export const PROMOCODE_PHONE_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/promoCode/phone_icon.svg`;
export const PROMOCODE_STAR_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/promoCode/star_icon.svg`;
export const PROMOCODE_COUPON_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/promoCode/coupon_icon.svg`;
export const PROMOCODE_DISCOUNT_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/promoCode/discount_icon.svg`;
export const PROMOCODE_BLOCK_VOUCHER_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/promoCode/display_voucher_icon.svg`;
export const PROMOCODE_ERROR_MESSAGE_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/promoCode/error_code_icon.svg`;

export const COUNT_PRODUCT = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/product-detail/count_product_2.svg`;

// daily-checkin
export const DAILY_CHECKIN_BACKGROUND_BORDER = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/dailyCheckin/new_background_border.png`;
export const DAILY_CHECKIN_BACKGROUND = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/dailyCheckin/new_background_daily.png`;
export const DAILY_CHECKIN_CHECKED_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/dailyCheckin/checked_icon.svg`;
export const DAILY_CHECKIN_FIREWORK_EFFECT = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/dailyCheckin/firework_effect.svg`;
export const DAILY_CHECKIN_CHECKED_ANIMATION = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/dailyCheckin/check_animation.gif`;
export const DAILY_CHECKIN_WARNING_FAIL_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/dailyCheckin/warning_icon.svg`;
export const DAILY_CHECKIN_DEFAULT_SUCCESS = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/dailyCheckin/default_checkin_success.svg`;
export const DAILY_CHECKIN_POPUP_GUIDE_BANNER = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/dailyCheckin/header.svg`;
export const DAILY_CHECKIN_DEFAULT_PRIZE_INFO = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/dailyCheckin/mobile_default_prize_info.svg`;

// register
export const REGISTER_ICON_BUSSINESS = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/register/bussiness.svg`;
export const REGISTER_ICON_SUPPORT = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/register/support.svg`;
export const REGISTER_ICON_LOCATION = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/register/location.svg`;
export const REGISTER_ICON_PHONE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/register/phone.svg`;
export const REGISTER_ICON_GROUP = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/register/group.svg`;

// rating
export const ICON_RATING = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/rating/rating.png`;
export const STAR_OUTLINE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/rating/star_outline.svg`;
export const STAR_FULFILLED = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/rating/star_fullfield.svg`;
// icon new checkout
export const CHECKOUT_ICON_CHECK_IN_PIN = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/checkout/Check_In_Pin.svg`;
export const CHECKOUT_ICON_TAX = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/checkout/Tax.svg`;
export const CHECKOUT_ICON_PERSON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/checkout/Person.svg`;
export const CHECKOUT_ICON_PHONE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/checkout/Phone.svg`;
export const CHECKOUT_ICON_DROPDOWN = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/checkout/Dropdown.svg`;
export const CHECKOUT_ICON_STORE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/checkout/Store.svg`;
export const CHECKOUT_ICON_PERCENTAGE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/checkout/iconPercentage.svg`;

export const HISTORY_CLOCK_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/history_clock.svg`;
export const TITLE_YER = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/yer/review_title.svg`;
export const END_2023_PNG = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/yer/decoration_2023.png`;
export const TOTAL_PAY = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/yer/total_pay.gif`;
export const TOTAL_PAY_PNG = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/yer/total_pay.png`;
export const PIG = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/yer/pig.gif`;
export const TOP_RANK = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/yer/rank_top.png`;
export const TOP_AGENT = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/yer/agent_top.png`;
export const CHECK_LIST = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/yer/check_list.png`;
export const GIFT = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/yer/gift.png`;
export const BOX = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/yer/box.png`;
export const VIP = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/yer/vip.png`;
export const BLINK = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/yer/blink.gif`;
export const STAR = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/yer/star.png`;
export const THUOCSI_MASCOT = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/yer/meo.png`;
// cart
export const CART_ICON_VOUCHER = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/cart/voucher_icon.svg`;
export const CART_NEW_GIFT_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/cart/gift_icon.svg`;
export const CART_VOUCHER_CHECKED_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/cart/checked_icon.svg`;
export const CART_VOUCHER_CHECKBOX_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/cart/checkbox_icon.svg`;
export const CART_LIST_PROMO_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/cart/list_promo_icon.svg`;

// OCR
export const OCR_GIF_CONFIRM = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/ocr/ocr-image.png`;
export const OCR_IMAGE_CONFIRM = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/ocr/confirmClose.svg`;
export const OCR_IMAGE_UNVERIFIED = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/ocr/unverified.svg`;
export const OCR_IMAGE_SUCCESS = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/ocr/confirm_success.svg`;
export const OCR_IMAGE_CONFIRM_BACKGROUND = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/ocr/ConfirmCloseBackground.svg`;
export const OCR_IMAGE_ESTABLISHED = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/ocr/establisted1.svg`;
export const OCR_ICON_CERTIFICATE_VALID = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/ocr/certificate_validate.svg`;
export const OCR_ICON_CIRCLE_CHECKED = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/ocr/circleChecked.svg`;
export const OCR_ICON_WARNING = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/ocr/certificate_warning.svg`;

export const OCR_ICON_CERTIFICATE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/ocr/certificate.svg`;
export const OCR_ICON_CERTIFICATE_TITLE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/ocr/certificate_title.svg`;
export const OCR_ICON_CERTIFICATE_ADD_FILE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/ocr/add_file.svg`;
export const OCR_ICON_BANNED_CIRCLE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/ocr/Banned_Circle.svg`;
export const OCR_ICON_POINTER = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/ocr/icon_pointer_add_file.svg`;
export const OCR_IMAGE_REMIND = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/ocr/remind.svg`;
// Add more constants as needed
// user reward
export const USERREWARD_TS_LOGO_W_TEXT = '/images/usersReward/logo.svg';

export const LEFT = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/promotion/left.svg`;
export const RIGHT = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/promotion/right.svg`;
// promo-codes, add loyalty point
export const PROMOCODES_LOYALTY_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/promoCode/loyalty_logo.svg`;
export const PROMOCODES_TS_COIN_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/promoCode/ts_coin.svg`;
export const PROMOCODES_POPUP_CONFIRM_WARNING_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/promoCode/warning_icon.svg`;
export const PROMOCODES_POPUP_CONFIRM_SUCCESS_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/promoCode/success_icon.svg`;
export const PROMOCODES_POPUP_GUIDE_BANNER = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/promoCode/popup_guide_banner.svg`;
export const PROMOCODES_TS_WITH_TEXT_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/promoCode/logo_ts_w_text.svg`;
export const PROMOCODES_MEDX_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/promoCode/logo_med_x.svg`;
export const PROMOCODES_SECTION_COLLECT_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/promoCode/section_collect_voucher_icon.svg`;
export const PROMOCODES_SECTION_SELLER_VOUCHER_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/promoCode/section_seller_voucher_icon.svg`;
export const PROMOCODES_EXTERNAL_SELLER_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/promoCode/external_seller_icon.svg`;

export const ICON_ARROW_DOWN = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/arrow-down.svg`;
export const ICON_ARROW_DOWN_ACTIVE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/arrow-down-active.svg`;
export const ICON_WISH_LIST_INACTIVE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/wish_list_inactive.svg`;
export const ICON_WISH_LIST_ACTIVE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/wish_list_active.svg`;
export const ICON_SELLER_STORE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/seller_store.svg`;
export const CART_STORE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/icons/cart_store.svg`;

// collect voucher
export const COLLECT_VOUCHER_COLLECTED_BLUE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/product-detail/collected_blue_icon.svg`;
export const COLLECT_VOUCHER_COLLECTED_GREEN = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/product-detail/collected_green_icon.svg`;
export const COLLECT_VOUCHER_COLLECTED_GREY = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/product-detail/collected_grey_icon.svg`;
export const COLLECT_VOUCHER_LOGO_TS_WITH_TEXT = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/product-detail/logo_ts_with_text.svg`;
export const COLLECT_VOUCHER_LOGO_MED_X = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/product-detail/logo_med_x.svg`;

// cart
export const CART_DANGER = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/cart/danger_icon.svg`;
export const CART_WARNING = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/cart/warning_icon.svg`;
export const CART_DOC = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/cart/document_icon.svg`;
export const CART_STAR = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/cart/star_icon.svg`;
export const CART_OPEN = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/cart/open_icon.svg`;
export const CART_TRASH_GREY = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/cart/trash_grey_icon.svg`;
export const CART_TRASH_GREEN = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/cart/trash_green_icon.svg`;
export const CART_TRASH_RED = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/cart/trash_red_icon_v2.svg`;
export const CART_INACTIVE_STAR = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/cart/inactive_important_icon.svg`;
export const CART_ACTIVE_STAR = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/cart/active_important_icon.svg`;
export const CART_INCREASE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/cart/increase_icon.svg`;
export const CART_DECREASE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/cart/decrease_icon.svg`;
export const CART_LIMIT = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/cart/limit_icon.svg`;
export const CART_NEAR_DATE = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/cart/near_date_icon.svg`;
export const CART_BOX_VOUCHER = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/cart/box_voucher.svg`;
export const CART_NEAR_EXPIRED = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/cart/near_expired.svg`;
export const CART_ARROW_DOWN = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/cart/arrow_down.svg`;
export const CART_INFO_DANGER = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/cart/info_danger_icon.svg`;
export const CART_SUGGEST_VOUCHER_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/cart/suggest_voucher_icon.svg`;
export const CART_SUGGEST_ALREADY_APPLY_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/cart/suggest_already_apply.svg`;

// product viewed
export const EMPTY_LIST = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/productviewed/empty-product.svg`;
// users reward detail
export const USER_REWARD_PLAY_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/usersReward/new_play_icon.svg`;
export const USERREWARD_PLAY_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/usersReward/play_icon.svg`;
export const USER_REWARD_PAUSE_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/usersReward/pause_icon.svg`;
export const USER_REWARD_REPLAY_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/usersReward/replay_icon.svg`;

export const QA_TITLE_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/logo_qa.svg`;
export const QA_BTN_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/send_btn_qa.svg`;
export const QA_CUSTOMER_AVATAR_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/customerv2_avatar_qa.svg`;
export const QA_EMPLOYEE_AVATAR_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/employee_avatar_qa.svg`;
export const QA_AUTHEN_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/authen_qa.svg`;
export const QA_STORE_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/store_qa.svg`;
export const QA_LOCATION_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/location_qa.svg`;
export const QA_REPLY_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/reply_qa.svg`;

export const QUICKORDER_SUGGEST_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/suggest_icon.svg`;
export const QUICKORDER_REPLACE_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/replace_icon.svg`;

export const SUBSTITUDE_REPLACE_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/substitude/new_replace_icon.svg`;
export const SUBSTITUDE_ARROW_RIGHT_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/substitude/new_arrow_right_icon.svg`;
export const SUBSTITUTE_RECOMMEND_ICON = `${NEXT_PUBLIC_CDN_PREFIX || ''}/images/substitude/recommend_icon.svg`;

// LEVEL BACKGROUND
export const LEVEL_BACKGROUND_SLIVER = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/userLoyalty/rank_silver_bg.png`;
export const LEVEL_BACKGROUND_GOLD = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/userLoyalty/rank_gold_bg.png`;
export const LEVEL_BACKGROUND_PLATINUM = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/userLoyalty/rank_platinum_bg.png`;
export const LEVEL_BACKGROUND_DIAMOND = `${NEXT_PUBLIC_CDN_PREFIX || ""}/images/userLoyalty/rank_diamond_bg.png`;
