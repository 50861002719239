import { GET } from './Clients';

const CONFIG_MANAGER_V1 = '/core/config-manager/v1';

export async function getAppConfigs({ ctx, appCode = '884FW961', isBasic = true, isAuth = false }) {
  return GET({
	ctx,
    url: `${CONFIG_MANAGER_V1}/app-value/single`,
    isBasic,
    isAuth,
    params: {
      appCode,
    },
  });
}
