import { getDebtCheck } from 'clients/AccountingClient';
import { getUser, getUserWithContext } from 'clients/AuthClient';
import { getFirst, isValid } from 'clients/Clients';
import { formatCurrency } from 'utils/FormatNumber';
import { generateCustomerType } from 'utils/Insider';

export async function getAccount(ctx, retry = 0) {
  let userRes = null;
  if (ctx) {
    userRes = await getUserWithContext(ctx, retry);
  } else {
    userRes = await getUser(retry);
  }
  if (!isValid(userRes)) {
    return userRes;
  }
  const info = getFirst(userRes);

  return {
    ...userRes,
    data: [
      {
        ...info,
        isActive: info.status === 'ACTIVE',
        isQuest: info.level === 'LEVEL_GUEST',
        customerType: generateCustomerType(info),
        createdTime: null,
        lastUpdatedTime: null,
      },
    ],
  };
}

export async function getDebt(user) {
  const debtRes = await getDebtCheck({ customerId: user?.customerID });
  if (debtRes.status !== 'OK') return {};

  const debt = debtRes.data[0];
  if (!debt) return {};

  if (debt.balance <= 0) {
    debt.balanceMessageError = 'Số hạn mức khả dụng không đủ, vui lòng kiểm tra lại hạn mức hoặc thanh toán để tăng lại hạn mức.';
  }

  return debt;
}

export default { getAccount };
