export const PRODUCT_TAG = {
  HANG_DIEM: 'Y7GB',
  HANG_HANG: '2ABC',
  HOADONNHANH: 'HOADONNHANH',
  KHUYENMAI: 'DEAL',
  BANCHAY: 'BANCHAY',
  NHAN_DOI_TICH_LUY: 'GY7G', // https://internal.thuocsi.vn/cms/tag/edit?tagCode=GY7G
  RE_NHAT: 'DOCQUYENGIATOT', // https://internal.thuocsi.vn/cms/tag/edit?tagCode=DOCQUYENGIATOT
  SIEU_RE: 'RENHAT', // https://internal.thuocsi.vn/cms/tag/edit?tagCode=RENHAT
  SANPHAMMOI: 'V2U1', // https://internal.thuocsi.vn/cms/tag/edit?tagCode=V2U1
};
