import { APIStatus } from '@thuocsi/customer-live-chat/src/utils/common';
import { LOYALTY_STATUS } from 'constants/Enums/loyalty';
import { parseJSON } from 'utils/jsonParse';
import { DELETE, GET, GET_ALL, POST, PUT, getData } from './Clients';

const DEFAULT_TIME_EXPIRED = 900000;
const CUSTOMER_API_PREFIX = '/marketplace/customer/v1';
const FRONTEND_APIS_PREFIX = '/marketplace/frontend-apis/v1';

export async function retrySendSms({ code }) {
  return POST({
    url: `${CUSTOMER_API_PREFIX}/me/reference/sms`,
    params: { code },
  });
}

export async function sendSmsToPhone({ phoneNumber }) {
  return POST({
    url: `${CUSTOMER_API_PREFIX}/me/reference`,
    body: { phone: phoneNumber },
  });
}

export async function getReferral({ params }) {
  return GET({
    url: `${CUSTOMER_API_PREFIX}/me/reference/list`,
    params,
  });
}

export async function updateProfile(data) {
  return PUT({
    url: `${CUSTOMER_API_PREFIX}/me`,
    body: data,
  });
}

export async function updateProfileEnterprise(data) {
  return PUT({
    url: `${CUSTOMER_API_PREFIX}/me/business-info`,
    body: data,
  });
}

export async function getBankAccount(ctx) {
  const result = await GET({
    ctx,
    url: `${CUSTOMER_API_PREFIX}/me/account/bank`,
  });
  return getData(result);
}

export async function loginY360Account(ctx) {
  return POST({
    ctx,
    url: `${CUSTOMER_API_PREFIX}/y360/login`,
    body: {},
  });
}

export async function logoutY360Account(ctx) {
  return POST({
    ctx,
    url: `${CUSTOMER_API_PREFIX}/y360/logout`,
    body: {},
  });
}

export async function getAddressList({ ctx, params }) {
  return GET({
    ctx,
    url: `${CUSTOMER_API_PREFIX}/me/account/address`,
    params,
  });
}

export async function createAddress({ ctx, body }) {
  return POST({
    ctx,
    url: `${CUSTOMER_API_PREFIX}/me/account/address`,
    body,
  });
}

export async function deleteAddress({ ctx, code, customerID }) {
  return DELETE({
    ctx,
    url: `${CUSTOMER_API_PREFIX}/me/account/address`,
    params: { code, customerID },
  });
}

export async function updateAddress({ ctx, body }) {
  return PUT({
    ctx,
    url: `${CUSTOMER_API_PREFIX}/me/account/address`,
    body,
  });
}

export async function updateAddressDefault({ ctx, code, customerID }) {
  return PUT({
    ctx,
    url: `${CUSTOMER_API_PREFIX}/me/account/address`,
    body: { code, isDefault: true, customerID },
  });
}

export async function getLoyaltyList(ctx) {
  return GET({
    ctx,
    url: `${CUSTOMER_API_PREFIX}/loyalty/list`,
    params: { status: LOYALTY_STATUS.ACTIVE },
  });
}

export async function getListHistoryLoyalty({ ctx, offset, limit, q, type = null, getIncRankPointOnly = false }) {
  return GET({
    ctx,
    url: `${CUSTOMER_API_PREFIX}/me/history-loyalty/list`,
    params: { offset, limit, getTotal: true, q, type, getIncRankPointOnly },
  });
}

export async function getAllListHistoryLoyalty({ ctx, q, type = null }) {
  return GET_ALL({
    ctx,
    url: `${CUSTOMER_API_PREFIX}/me/history-loyalty/list`,
    params: { q, type },
  });
}

export async function exchangeLoyalty({ ctx, body }) {
  return POST({
    ctx,
    url: `${CUSTOMER_API_PREFIX}/me/loyalty`,
    body,
  });
}

export async function getListInvoiceInfo({ ctx }) {
  return GET({
    ctx,
    url: `${CUSTOMER_API_PREFIX}/me/invoice-info/list`,
  });
}

export async function createInvoiceInfo({ ctx, body }) {
  return POST({
    ctx,
    url: `${CUSTOMER_API_PREFIX}/me/invoice-info`,
    body,
  });
}

export async function deleteInvoiceInfo({ ctx, code, customerID }) {
  return DELETE({
    ctx,
    url: `${CUSTOMER_API_PREFIX}/me/invoice-info`,
    params: { code, customerID },
  });
}

export async function updateInvoiceInfo(body) {
  return PUT({
    url: `${CUSTOMER_API_PREFIX}/me/invoice-info`,
    body,
  });
}

export async function updateInvoiceInfoOrder(body) {
  return PUT({
    url: '/marketplace/order/v2/order/invoice',
    body,
  });
}

export async function getBankAccountInfo({ ctx } = {}) {
  return GET({
    ctx,
    url: `${CUSTOMER_API_PREFIX}/me/bank/list`,
  });
}

export async function createBankAccountInfo({ ctx, body }) {
  return POST({
    ctx,
    url: `${CUSTOMER_API_PREFIX}/me/bank`,
    body,
  });
}

export async function updateBankAccountInfo({ body }) {
  return PUT({
    url: `${CUSTOMER_API_PREFIX}/me/bank`,
    body,
  });
}

export async function getLevelList({ ctx }) {
  return GET({
    ctx,
    url: `${CUSTOMER_API_PREFIX}/level/list`,
  });
}

// update api updateAccountInfo apm - 173 - https://buymed.atlassian.net/browse/APM-173

export async function updateCustomerInfo(dataAccount) {
  return PUT({
    url: `${CUSTOMER_API_PREFIX}/me/basic-info`,
    body: dataAccount,
  });
}

export async function updateCaculatePoints() {
  return GET({
    url: `${CUSTOMER_API_PREFIX}/setting`,
  });
}

export async function asyncCustomerLicenseImageInfo({ body }) {
  return POST({
    url: '/integration/gcv/v1/customer-license/convert/async',
    body,
  });
}

export async function asyncCustomerLicenseImageInfoV2({ body }) {
  return POST({
    url: '/integration/gcv/v1/customer-license/convert-v2/async',
    body,
  });
}

export async function pingCustomerLicenseInfoCheck({ params }) {
  return GET({
    url: '/integration/gcv/v1/customer-license/convert/check',
    params,
  });
}

export async function getRequsetLicenseTicket({ params }) {
  return GET({
    url: `${CUSTOMER_API_PREFIX}/request-license`,
    params,
  });
}

export async function createRequestLicenseTicket({ body }) {
  return POST({
    url: `${CUSTOMER_API_PREFIX}/request-license`,
    body,
  });
}

export async function updateRequestLicenseTicketStatus({ body }) {
  return PUT({
    url: `${CUSTOMER_API_PREFIX}/request-license/switch-status`,
    body,
  });
}

export async function getUserResetLoyaltyPoint({ ctx } = {}) {
  return GET({
    ctx,
    url: `${CUSTOMER_API_PREFIX}/me/reset-point`,
  });
}

/**
 *
 * @param {object} param0
 * @param {import('next').GetServerSidePropsContext=} param0.ctx
 * @param {{feature: string|string[]}} param0.q
 * @returns
 */
export async function getUserLogGuide({ ctx, q }) {
  const featureKey = q.feature;
  const localCache = localStorage.getItem(featureKey);

  const qCache = parseJSON(localCache, null);
  if (qCache) {
    const now = new Date().getTime();
    if (now <= qCache.expired) {
      return qCache.value;
    }
  }

  const res = await GET({
    url: `${FRONTEND_APIS_PREFIX}/me/guide`,
    ctx,
    params: {
      q: q ? JSON.stringify(q) : undefined,
    },
  });

  if (res.status === APIStatus.OK) {
    const cache = {
      value: res,
      expired: new Date().getTime() + DEFAULT_TIME_EXPIRED,
    };

    localStorage.setItem(q.feature, JSON.stringify(cache));
  }
  return res;
}

/**
 *
 * @param {object} param0
 * @param {string} param0.feature
 * @param {string=} param0.featureName
 * @param {boolean=} param0.isViewAll - true = user have clicked through all the guide
 * @returns
 */
export async function postUserLogGuide({ feature, featureName, isViewAll }) {
  const localCache = localStorage.getItem(feature);

  const cache = parseJSON(localCache, null);

  try {
    if (cache.value && cache.value?.data?.length) {
      if (typeof cache.value?.data[0] === 'object') {
        cache.value.data[0].isViewAll = isViewAll;
        cache.value.data[0].isGuided = true;
        localStorage.setItem(feature, JSON.stringify(cache));
      }
    }
  } catch (err) {
    localStorage.removeItem(feature);
  }

  return POST({
    url: `${CUSTOMER_API_PREFIX}/me/guide`,
    body: {
      feature,
      featureName,
      isViewAll,
    },
  });
}

export async function ratingOrder({ body }) {
  try {
    return await PUT({
      url: `${CUSTOMER_API_PREFIX}/me/rating`,
      body,
    });
  } catch (error) {
    return error?.message;
  }
}
