import { IS_ENCRYPTED_PRICE } from 'sysconfig';
import { GET, POST } from './Clients';

const FRONTEND_APIS_PREFIX = '/marketplace/frontend-apis/v1';

export async function loadProductDetail({ ctx, params, isBasic = true }) {
  return GET({
    url: `${FRONTEND_APIS_PREFIX}/product/detail-encrypted`,
    ctx,
    isBasic,
    params,
  });
}

export async function getProductDetailRaw({ ctx, params, isBasic = true, isAuth = false }) {
  return GET({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/product/detail-raw`,
    params,
    isBasic,
    isAuth,
  });
}

export async function getProductDetailRawLite({ ctx, params, isBasic = true, isAuth = false }) {
  return GET({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/product/detail-raw/lite`,
    params,
    isBasic,
    isAuth,
  });
}

export async function getTopProductsSkipLogin({ ctx, params, isBasic = true, isAuth = false }) {
  return GET({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/top-products/skip-login`,
    params,
    isBasic,
    isAuth,
  });
}

export async function loadProductsFuzzySearch({ body, ctx, ...restProps }) {
  const newBody = { ...body };

  if (newBody.filter?.categoryFilters?.length) {
    for (let i = 0; i < newBody.filter.categoryFilters.length; i += 1) {
      if (newBody.filter.categoryFilters[i].efficacyCodes?.length) {
        newBody.filter.categoryFilters[i].categoryCodes = [];
        newBody.filter.categoryFilters[i].code = '';
      }
    }
  }

  newBody.queryOption = {
    ...newBody.queryOption,
    quantityInCart: true,
    isReplacePriceAfterVoucher: true,
    isGetSKUReplace: true,
  };

  return POST({
    ctx,
    url: IS_ENCRYPTED_PRICE ? `${FRONTEND_APIS_PREFIX}/search/fuzzy-encrypted` : `${FRONTEND_APIS_PREFIX}/search/fuzzy`,
    body: newBody,
    ...restProps,
  });
}

export async function getProductRecommendation({ ctx, body }) {
  return POST({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/recommendation/product`,
    body,
  });
}

export async function getProductRecommendationLite({ ctx, q = {}, offset = 0, limit = 40, queryOption = {} }) {
  return POST({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/recommendation/product-lite`,
    body: { q, offset, limit, queryOption },
  });
}

export async function loadCart({ ctx, params }) {
  return GET({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/cart`,
    params: {
      ...params,
      getVoucherAuto: params?.getVoucherAuto || false,
    },
  });
}

export async function loadDataCart(params) {
  return GET({
    url: `${FRONTEND_APIS_PREFIX}/cart`,
    params: {
      ...params,
      getVoucherAuto: params?.getVoucherAuto || false,
    },
  });
}

export async function loadDataCartEncrypted(params) {
  return GET({
    url: `${FRONTEND_APIS_PREFIX}/cart-encrypted`,
    params: {
      ...params,
      getVoucherAuto: params?.getVoucherAuto || false,
    },
  });
}

export async function searchFuzzyLite({ ctx, params, body }) {
  return POST({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/search/fuzzy/lite`,
    params,
    body,
  });
}

export async function sellerSkipLogin() {
  return GET({
    url: `${FRONTEND_APIS_PREFIX}/seller/product/skip-login`,
    isBasic: true,
  });
}

export async function contactMe({ body }) {
  return POST({
    url: `${FRONTEND_APIS_PREFIX}/customer/contact-me`,
    body,
    isBasic: true,
  });
}

export async function getDoccenURL({ ctx }) {
  return GET({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/customer/doccen`,
  });
}

export async function loadDataCartLite() {
  return GET({ url: `${FRONTEND_APIS_PREFIX}/cart-lite` });
}

export async function loadDataCartLiteEncrypted() {
  return GET({ url: `${FRONTEND_APIS_PREFIX}/cart-lite-encrypted` });
}

export async function getWebOrderDetail({ ctx, params, body }) {
  return GET({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/order`,
    params,
    body,
  });
}

export async function verifyInvoiceTaxCode({ taxCode, customerName, customerAddress }) {
  return POST({
    url: `${FRONTEND_APIS_PREFIX}/invoice/tax-code/verify`,
    body: { taxCode, customerName, customerAddress },
  });
}

export async function getTotalProductCategories({ ctx, params, body }) {
  return POST({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/category/total-product`,
    params,
    body,
  });
}

export async function getCategories({ ctx } = {}) {
  return GET({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/product/category/list`,
  });
}

export async function getTopManufacturerList({ ctx } = {}) {
  return GET({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/manufacturer/list`,
    params: {
      queryOption: 'getTopManufacturers',
    },
  });
}

export async function getPopup({ ctx, type, platform }) {
  return GET({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/popup`,
    params: { type, platform },
  });
}

export async function getRecentSearchTexts() {
  return GET({ url: `${FRONTEND_APIS_PREFIX}/customer/recent-search-texts` });
}

export async function getYearInReview({ ctx, params, body }) {
  return GET({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/me/order-report?year=2023`,
    params,
    body,
  });
}

export async function getMenuList({ ctx, params, body }) {
  return GET({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/menu-profile`,
    params,
    body,
  });
}

export async function getMenuBar({ ctx, params, body }) {
  return GET({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/menu-bar`,
    params,
    body,
  });
}

export async function getScreenCartItemHelper() {
  return GET({ url: `${FRONTEND_APIS_PREFIX}/screen/cart/item-helper` });
}

export async function getCommentsBySkuCode({ isAuth = true, skuCode, sort, getTotal = false, offset, limit, excludeID = '', q = null }) {
  return GET({
    url: `${FRONTEND_APIS_PREFIX}/product/comment`,
    params: {
      skuCode,
      getTotal,
      sort,
      offset,
      limit,
      ...(q && { q: JSON.stringify(q) }),
      ...(excludeID && { excludeID }),
    },
    isAuth,
  });
}

export async function createComment({ isAuth = true, isBasic = false, content, targetCode, targetType = 'SKU' }) {
  return POST({
    url: `/marketplace/ticket/v1/comment`,
    body: {
      content,
      targetType,
      targetCode,
    },
    isAuth,
    isBasic,
  });
}

export async function createCommentNotLogin({
  isAuth = true,
  isBasic = false,
  fullname = '',
  phone = '',
  scope = '',
  provinceCode = '',
  content,
  targetCode,
  targetType = 'SKU',
}) {
  return POST({
    url: `/marketplace/ticket/v1/comment-not-login`,
    body: {
      fullname,
      phone,
      scope,
      provinceCode,
      content,
      targetType,
      targetCode,
    },
    isAuth,
    isBasic,
  });
}

export async function createReply({ isAuth = true, isBasic = false, content, parentCommentID }) {
  return POST({
    url: `/marketplace/ticket/v1/comment/reply`,
    body: {
      content,
      parentCommentID,
    },
    isAuth,
    isBasic,
  });
}

export async function getReply({ isAuth = true, parentCommentID, getTotal = false, offset, limit }) {
  return GET({
    url: `${FRONTEND_APIS_PREFIX}/product/comment/reply`,
    params: {
      parentCommentID,
      getTotal,
      offset,
      limit,
    },
    isAuth,
  });
}

export async function getCombosContainingProduct({ ctx, skuCode }) {
  return await POST({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/product/combo-containing-product`,
    body: {
      skuCodes: [skuCode],
      queryOption: {
        price: true,
        consumedMaxQuantity: true,
        sellerInfo: true,
        isReplacePriceAfterVoucher: true,
      },
    },
  });
}

export async function getRecommendationProduct({ q, limit = 15 }) {
  return POST({
    url: `${FRONTEND_APIS_PREFIX}/recommendation/product`,
    body: {
      q,
      limit,
      queryOption: {
        consumedMaxQuantity: true,
        sellerInfo: true,
        getWishList: true,
        isReplacePriceAfterVoucher: true,
      },
    },
  });
}

export async function getScreenCartVoucherHelper() {
  return GET({ url: `${FRONTEND_APIS_PREFIX}/screen/cart/voucher-helper` });
}
