const addQueryOption = ({ body = null, params = null, documentFile = false, productDetailPage = false }) => {
  const result = {};
  if (body) {
    const newBody = { ...body } || null;
    const queryOption = {
      consumedMaxQuantity: true,
      sellerInfo: true,
      documentFile,
      isReplacePriceAfterVoucher: true,
      productDetailPage,
    };
    newBody.queryOption = queryOption;

    result.body = newBody;
  }

  if (params) {
    const newParams = { ...params } || null;
    newParams.queryOption = `price,consumedMaxQuantity,sellerInfo,isReplacePriceAfterVoucher,` + (documentFile ? 'documentFile,' : '') + (productDetailPage ? 'productDetailPage,countTotalProductOfVendorAndSeller,' : '') + `quantityInCart,`;
    result.params = newParams;
  }
  return result;
};

export default {
  addQueryOption,
};
