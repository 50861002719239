import { decryptAES, genkey } from './decode';

const THEN_PERCENT = 'Đang bán chạy';
const SEVENTY_PERCENT = 'Sắp hết hàng';
const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
export const isEqual = (array1 = [], array2 = []) => {
  const arrayRevert1 = array1.sort();
  const arrayRevert2 = array2.sort();
  for (let i = 0; i < arrayRevert1.length; i++) {
    if (arrayRevert1[i] !== arrayRevert2[i]) {
      return false;
    }
  }

  return true;
};
export const checkRule = (percent) => {
  if (Number.isNaN(percent)) {
    return THEN_PERCENT;
  }
  if (percent < 10) return THEN_PERCENT;
  if (percent > 70) {
    return SEVENTY_PERCENT;
  }
  return null;
};
export const conditionRender = (number) => {
  if (Number.isNaN(number)) {
    return true;
  }
  if (number < 10 || number > 70) return true;
  return false;
};
export const formattedPhoneNumber = (dataForm) => dataForm.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3');
export const getOtp = (values) => {
  let otp = '';
  for (const key in values) {
    if (values[key] && key !== 'phoneNumber') {
      otp += values[key];
    }
  }
  return otp;
};
export const hasValue = (obj) => Object.keys(obj).every((key) => Boolean(obj[key]));
export const splitArray = (arr) => {
  const mid = arr.length % 2 === 0 ? arr.length / 2 : Math.floor(arr.length / 2) + 1;
  const arr1 = arr.slice(0, mid);
  const arr2 = arr.slice(mid);
  return [arr1, arr2];
};
export const calculateTwoDates = (date1) => {
  const dateFirst = new Date(date1);
  const dateSecond = new Date();
  const differenceInMilliseconds = dateSecond.getTime() - dateFirst.getTime();
  const daysDifference = differenceInMilliseconds / (1000 * 60 * 60 * 24);
  return daysDifference;
};
export const zoomingPercent = () => {
  const zoomPercent = ((window.outerWidth - 10) / window.innerWidth) * 100;
  return zoomPercent;
};
export const checkTypeScreen = () => {
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const percent = zoomingPercent();
  let isXsScreen = false;

  if (screenWidth <= 1024 && screenHeight <= 768) {
    // The screen is HD.
    isXsScreen = true;
  }
  if (screenWidth >= 1024 && screenHeight >= 768 && percent > 109) {
    // The screen is HD.
    isXsScreen = true;
  }
  if (screenWidth === 1280 && screenHeight === 720) {
    // The screen is HD.
    isXsScreen = true;
  }

  return isXsScreen;
};
export const generateDot = (count) => {
  const arr = [];
  if (count) {
    for (let i = 0; i < count; i++) {
      arr.push('.');
    }
  }
  return arr;
};
export const formattedDateTime = (date) => {
  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const month = dateObj.getMonth() + 1; // Months are 0-indexed
  const year = dateObj.getFullYear();
  const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
  if (day && month && year) {
    return formattedDate;
  }
  return null;
};
export const convertDateTime = (stringDate) => {
  const dateParts = stringDate.split('/');
  const [day, month, year] = dateParts;

  const formattedDate = new Date([year, month, day].join('-'));

  return formattedDate;
};
function isLeapYear(year) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}
function isNotValidDay(year, month, day) {
  if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
    daysInMonth[1] = 29; // Adjust for leap year
  }
  const dayConvert = Number(day);
  return dayConvert > daysInMonth[month];
}

export const validDateTime = (date) => {
  const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
  const currentYear = new Date().getFullYear();
  if (!dateRegex.test(date)) {
    return { isValid: false, message: 'Ngày sinh không hợp lệ' };
  }

  const dateParts = date.split('/');
  const [day, month, year] = [dateParts[0], dateParts[1] - 1, dateParts[2]];
  if (month === 1 && day > 29) {
    return { isValid: false, message: isLeapYear(year) ? `Năm ${year} tháng 2 chỉ có 29 ngày ` : `Năm ${year} tháng 2 chỉ có 28 ngày ` };
  }
  if (day < 1 || day > 31) {
    return { isValid: false, message: 'Ngày sinh không hợp lệ' };
  }
  if (month < 0 || month > 11) {
    return { isValid: false, message: 'Tháng sinh không hợp lệ' };
  }
  if (+month === 1 && +day === 29 && !isLeapYear(year)) {
    return { isValid: false, message: `Không tồn tại 29/2 trong năm ${year}` };
  }
  if (currentYear - year < 18 || currentYear - year > 80) {
    return { isValid: false, message: 'Độ tuổi cho phép sử dụng thuocsi.vn là từ 18 đến 80' };
  }
  if (isNotValidDay(year, month, day)) {
    return { isValid: false, message: 'Ngày sinh không hợp lệ' };
  }
  // Handle potential edge cases like invalid time zones or browser inconsistencies
  try {
    // eslint-disable-next-line no-new
    new Date(year, month - 1, day);
    return { isValid: true };
  } catch (error) {
    return { isValid: false, message: 'Vui lòng nhập ngày sinh' };
  }
};

export const isInViewport = (element, isCalcHeightEl = false) => {
  const rect = element.getBoundingClientRect();

  let { top, bottom } = rect;

  if (isCalcHeightEl) {
    top += rect.height;
    bottom -= rect.height;
  }

  return (
    top >= 0 &&
    rect.left >= 0 &&
    bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export function encryptedData(data) {
  return decryptAES({
    key: genkey(process.env.NEXT_PUBLIC_DECODE_PRICE_KEY),
    value: data || '',
  });
}

export function sleep(seconds) {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
}
