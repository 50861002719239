import { isValid } from 'clients/Clients';
import { getAppConfigs } from 'clients/ConfigClient';
import { BeCustomer } from 'types/customer';
import { isJsonString } from './helper';

export const redirectToCustomerDashboard = async (userInfo: BeCustomer) => {
  if(!userInfo) return;

  const { customerID, provinceCode } = userInfo;
  getAppConfigs({ ctx: undefined, appCode: '884FW961' }).then((res) => {
    if (isValid(res)) {
      const config = res.data;
      const cloneData = config.reduce((preApp: any, curApp: any) => {
        const { key, object } = curApp.value;
        preApp[key] = isJsonString(object) ? JSON.parse(object) : object;
        return preApp;
      }, {});
      const GO_TO_CUSTOMER_DASHBOARD = cloneData.GO_TO_CUSTOMER_DASHBOARD;

      if(!GO_TO_CUSTOMER_DASHBOARD) return;
      
      const customerIds = GO_TO_CUSTOMER_DASHBOARD.customerIds;
      const provinceCodes = GO_TO_CUSTOMER_DASHBOARD.provinceCodes;

      if (customerIds?.includes(customerID) || provinceCodes?.includes(provinceCode)) {
        window.location.href = origin + '/customer-dashboard';
      }
    }
  });
};

