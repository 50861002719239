import { GET, GET_ALL } from './Clients';

const SUPPLIER_API_PREFIX = '/seller/core/v1';

async function getInfoSupplier({ ctx, params }) {
  return GET({
    ctx,
    url: `${SUPPLIER_API_PREFIX}/seller/info`,
    isBasic: true,
    params,
  });
}

// for api/seller/index.js
// nhằm hạn chế data trả ra ngoài nên sẽ làm api/seller để lấy hết data , rồi trả ra 1 api dạng internal
async function getAll({ ctx }) {
  return GET_ALL({
    ctx,
    url: `${SUPPLIER_API_PREFIX}/account/list`,
    isBasic: true,
    isAuth: false,
    params: {
      getStoreInfo: true,
    },
  });
}

// for web
async function getSellersMock() {
  return GET({
    url: '/seller',
    mock: true,
    isAuth: false,
  });
}

async function getSellerConfig({ ctx, sellerCodes }) {
  return GET({
    ctx,
    url: `${SUPPLIER_API_PREFIX}/seller/config`,
    params: { sellerCodes },
  });
}

export default {
  getInfoSupplier,
  getAll,
  getSellersMock,
  getSellerConfig,
};
