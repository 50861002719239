import { POST } from 'clients/Clients';
import { collectEvents } from 'clients/MonitorClient';
import Script from 'next/script';
import { useEffect } from 'react';

const sendSegAttributes = async (body) => POST({ url: '/marketplace/frontend-apis/v1/insider/dynamic-segment/attribute-value', ctx: null, body });

export default function Segmentation({}) {
  useEffect(() => {
    window.sendSegAttributes = sendSegAttributes;
    window.sendSegEvents = collectEvents;
  }, []);

  return (
    <Script
      async
      strategy="lazyOnload"
      // src={`/static/segmentation/test-seg.js`}
      src={process.env.NEXT_PUBLIC_URL_SEGMENTATION}
    />
  );
}
