import { getFirst, isValid } from 'clients/Clients';
import { getNotificationCount } from 'clients/NotifyClient';

export const getTotalNotification = async ({ ctx, tags }) => {
  const res = await getNotificationCount({ ctx, tags });
  if (!isValid(res)) {
    return {};
  }
  return getFirst(res);
};

export default {
  getTotalNotification,
};
