import { GET, PUT } from './Clients';

const NOTIFICATION_API_PREFIX = '/integration/notification/v1';

export async function getNotify({ ctx, limit = 20, page = 1, tags }) {
  return GET({
    ctx,
    url: `${NOTIFICATION_API_PREFIX}/notification/me`,
    params: { limit, offset: (page - 1) * limit, tags },
  });
}

export async function getNotificationCount({ ctx, tags }) {
  return GET({
    ctx,
    url: `${NOTIFICATION_API_PREFIX}/notification/me/counter`,
    params: { tags },
  });
}

export async function markReadNotiByCode({ ctx, code }) {
  return PUT({
    ctx,
    url: `${NOTIFICATION_API_PREFIX}/notification`,
    body: { code },
  });
}

export async function markReadAllNoti({ ctx, tags }) {
  return PUT({
    ctx,
    url: `${NOTIFICATION_API_PREFIX}/notification/all`,
    body: { tags },
  });
}
