/* eslint-disable no-shadow */
export type NotiTab = "important" | "product" | "promo"

export interface DateLabelProps {
    label: string
}

export interface NotificationTagColorProps {
    text: string;
    background: string;
    border: string;
}

export enum ENUM_NOTIFICATION_TAG {
    ACCOUNT = "ACCOUNT",
    ORDER = "ORDER",
    PRICE = "PRICE",
    PRODUCT = "PRODUCT",
    TICKET = "TICKET",
    PROMOTION = "PROMOTION",
    IMPORTANT = "IMPORTANT",
    FAVORITE = "FAVORITE"
}

export interface NotificationProps {
    code: string;
    link: string;
    isRead: boolean;
    title: string;
    description: string;
    createdTime: string;
    lastUpdatedTime: string;
    tags: ENUM_NOTIFICATION_TAG[];
    dateKey: string;
}

export interface MapProps {
    [x: string]: NotificationProps[]
}

export enum ENUM_NOTIFICATION_TAB {
    IMPORTANT = "IMPORTANT",
    PRODUCT = "PRODUCT",
    PROMOTION = "PROMOTION"
}
