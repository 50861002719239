import { useEffect } from 'react';

// [CUS-5713]: Limit users can capture screen */

export const usePreventCapture = () => {
  const handlePreventContextMenu = (e: MouseEvent) => {
    e.preventDefault();
  };

  useEffect(() => {
    document.addEventListener('contextmenu', handlePreventContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handlePreventContextMenu);
    };
  }, []);
};

