import { createContext, useContext, useState } from 'react';

/**
 * @typedef PopupContext
 * @property {boolean} open - true = popup is opened
 * @property {import('react').SetStateAction<boolean>} setOpen
 * @property {boolean} canShowNextPopup - true = popup is fetching popupData
 * @property {import('react').SetStateAction<boolean>} setCanShowNextPopup
 */

/**
 * @type {import('react').Context<PopupContext>}
 */
const PopupContext = createContext({});

export function PopupProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [canShowNextPopup, setCanShowNextPopup] = useState(false);

  return (
    <PopupContext.Provider
      value={{
        open,
        setOpen,

        canShowNextPopup,
        setCanShowNextPopup,
      }}
    >
      {children}
    </PopupContext.Provider>
  );
}

export const usePopup = () => useContext(PopupContext);
