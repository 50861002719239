import { POST } from './Clients';

const SMART_RECOMMENDATION = '/recommendation/product/v1';

export const getRecommendProduct = async ({ ctx, body }) =>
  POST({
    ctx,
    url: `${SMART_RECOMMENDATION}/recommend`,
    body,
  });

export default {
  getRecommendProduct,
};
