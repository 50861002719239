import { HTTP_STATUS } from 'constants/Enums/http';
import { isEmpty } from 'utils/ValidateUtils';
import { GET, GET_ALL } from './Clients';

const CORE_MASTER_DATA_PREFIX = '/core/master-data/v1';

export async function getAllProvinces({ctx} = {}) {
  return GET({
    ctx,
    url: `${CORE_MASTER_DATA_PREFIX}/provinces/list`,
    isBasic: true,
    isAuth: false,
  });
}

export async function getRegions({ ctx, offset = 0, limit = 100, ids = [], codes = [], getTotal = false }) {
  return GET({
    ctx,
    url: `${CORE_MASTER_DATA_PREFIX}/region/list`,
    params: {
      offset,
      limit: codes.length > 0 ? (ids.length > 0 ? ids.length : codes.length) : limit,
      ids: ids.join(','),
      codes: codes.join(','),
      getTotal,
    },
  });
}

export async function getAllRegions(ctx) {
  return GET_ALL({
    ctx,
    url: `${CORE_MASTER_DATA_PREFIX}/region/list`,
    isBasic: true,
    isAuth: false,
    cache: true,
  });
}

export async function getDistrictsByProvince(provinceCode, isAuth, isBasic) {
  return GET({
    url: `${CORE_MASTER_DATA_PREFIX}/districts`,
    params: { provinceCode },
    isAuth,
    isBasic,
  });
}

export async function getWardsByDistrict(districtCode = '', isAuth, isBasic) {
  if (isEmpty(districtCode)) {
    return { status: HTTP_STATUS.invalid, msg: 'DistrictCode not found' };
  }
  return GET({
    url: `${CORE_MASTER_DATA_PREFIX}/administratives/list`,
    params: { districtCode },
    isAuth,
    isBasic,
  });
}

export async function getWardByCode({ ctx, wardCode } = {}) {
  GET({
    ctx,
    url: `${CORE_MASTER_DATA_PREFIX}/ward/list`,
    params: { wardCode },
  });
}

export async function getDistrictByCode({ ctx, code } = {}) {
  return GET({
    ctx,
    url: `${CORE_MASTER_DATA_PREFIX}/district`,
    params: { code },
  });
}

export async function getProvinceByCode({ ctx, code } = {}) {
  return GET({
    ctx,
    url: `${CORE_MASTER_DATA_PREFIX}/province`,
    params: { code },
  });
}

export async function getAllCountries({ ctx } = {}) {
  return GET({
    ctx,
    url: `${CORE_MASTER_DATA_PREFIX}/country/list`,
    isBasic: true,
    isAuth: false,
  });
}
