import { useState } from 'react';

const useModal = (visible = false, defvalue = null) => {
  const [isShowing, setIsShowing] = useState(visible);
  const [value, setValue] = useState(defvalue);

  const toggle = (val, callback) => {
    setValue(val);
    if (callback && typeof callback === 'function') {
      callback();
    }
    setIsShowing(!isShowing);
  };

  const getVal = () => value;

  const toggleCheckShowing = () => {
    isShowing && toggle();
  };

  return [isShowing, toggle, getVal, toggleCheckShowing];
};

export default useModal;
