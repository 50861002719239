import { HTTP_STATUS } from 'constants/Enums/http';
import Cookies from 'js-cookie';
import { ACCESS_TOKEN, ACCESS_TOKEN_LONGLIVE, API_HOST, BASIC_AUTHEN, GENERAL_DOMAIN, MOCK_API_HOST } from 'sysconfig';
import CookiesParser from 'utils/CookieParser';
import { convertObjectToParameter } from 'utils/RequestUtils';
import { getSessionTokenClient, removeSessionToken } from 'utils/SessionUtils';

export const MAX_LIMIT = 50;
export const OFFSET_DEFAULT = 0;
export const LIMIT_DEFAULT = 20;

const DEVICE_ID = 'device_id';

const mapRequest = {};
const mapRequestPromise = {};

export function isValid(resp) {
  return resp && resp.status && resp.status === HTTP_STATUS.Ok && resp.data && resp.data[0];
}

export function getFirst(resp, def = null) {
  return resp && resp.data && resp.data.length > 0 ? resp.data[0] : def;
}

export function getData(resp, def = []) {
  return isValid(resp) ? resp.data : def;
}
export function isValidWithoutData(resp) {
  return resp && resp.status && resp.status === HTTP_STATUS.Ok;
}

export function getSessionToken(ctx) {
  // Discovery
  // if (ctx?.req?.headers?.authorization) {
  //   return ctx?.req?.headers?.authorization?.split(' ')[1];
  // }
  if (ctx?.req?.headers?.authorization && ctx?.req?.headers?.isAuthorizationClient) {
    return ctx?.req?.headers?.authorization?.split(' ')[1];
  }

  const tk = CookiesParser.getCookieFromCtx(ctx, ACCESS_TOKEN);
  if (tk && tk.length > 0) {
    return tk;
  }

  return CookiesParser.getCookieFromCtx(ctx, ACCESS_TOKEN_LONGLIVE);
}

function getSetDeviceID(ctx) {
  let deviceId;
  if (ctx) {
    deviceId = CookiesParser.getCookieFromCtx(ctx, DEVICE_ID);
    if (!deviceId) {
      deviceId = Math.random().toString(36).substring(2, 12);
      ctx.res.setHeader('Set-Cookie', `${DEVICE_ID}=${deviceId}; Path=/; Domain=${GENERAL_DOMAIN};SameSite=Lax; Max-Age=31536000`);
    }
  } else {
    deviceId = Cookies.get(DEVICE_ID);
    if (!deviceId) {
      deviceId = Math.random().toString(36).substring(2, 12);
      Cookies.set(DEVICE_ID, deviceId, { expires: 365 });
    }
  }
  return deviceId;
}

async function request({
  url,
  params,
  method,
  body,
  mock = false,
  page = false,
  isAuth = true,
  ctx = null,
  isBasic = false,
  debug = false,
  cache = false,
  timeout = null,
  priority = null,
  retry = 0,
  ssoToken,
}) {
  try {
    /*
    page = /pages
    mock api : folder:  /api
    dev / production : /backend
   */
    const baseURL = getBaseURL(process.env.NEXT_PUBLIC_ENV, ctx);

    const logTime = +new Date();
    const headers = {};
    const parameters = { ...params };
    let link = url;
    if (!page && url?.indexOf('http') === -1) {
      link = (mock ? MOCK_API_HOST : baseURL) + url;
    }

    // prepare Authorization header
    let isUseBasic = false;
    if (ssoToken) {
      headers.Authorization = `Bearer ${ssoToken}`;

      // Update cookie to the new ssoToken
      if (ctx) {
        headers['user-agent'] = ctx.req.headers['user-agent'];
        ctx.res.setHeader('Set-Cookie', [
          `${ACCESS_TOKEN}=${ssoToken}; Path=/; Domain=${GENERAL_DOMAIN};SameSite=Lax; Max-Age=99999999`,
          `${ACCESS_TOKEN_LONGLIVE}=${ssoToken}; Path=/; Domain=${GENERAL_DOMAIN};SameSite=Lax; Max-Age=99999999`,
        ]);
      } else {
        Cookies.set(ACCESS_TOKEN, ssoToken, {
          domain: GENERAL_DOMAIN,
          sameSite: 'Lax',
        });
        Cookies.set(ACCESS_TOKEN_LONGLIVE, ssoToken, {
          expires: 5000,
          domain: GENERAL_DOMAIN,
          sameSite: 'Lax',
        });
      }
    } else {
      if (isAuth) {
        if (ctx) {
          const AuthorizationValue = getSessionToken(ctx);
          if (AuthorizationValue && AuthorizationValue.length > 0) {
            headers['user-agent'] = ctx.req.headers['user-agent'];
            headers.Authorization = `Bearer ${AuthorizationValue}`;
          }
        } else {
          const AuthorizationValue = getSessionTokenClient();
          if (AuthorizationValue && AuthorizationValue.length > 0) {
            headers.Authorization = `Bearer ${AuthorizationValue}`;
          }
        }
        if (isBasic && (!headers.Authorization || headers.Authorization.length === 0)) {
          headers.Authorization = BASIC_AUTHEN;
          isUseBasic = true;
          parameters.isBasic = true;
        }

        if (!headers.Authorization || headers.Authorization.length === 0) {
          return {
            errorCode: 'MISSING_AUTHORIZATION',
            status: HTTP_STATUS.Unauthorized,
            message: 'Missing session',
          };
        }
      } else if (isBasic && (!headers.Authorization || headers.Authorization.length === 0)) {
        headers.Authorization = BASIC_AUTHEN;
        isUseBasic = true;
        parameters.isBasic = true;
      }
    }

    // Check for DEVICE_ID in cookies
    const deviceId = getSetDeviceID(ctx);

    // console.log('link : ', link);
    if (parameters) {
      const parameterStr = convertObjectToParameter(parameters);
      if (parameterStr.length > 0) link += (link?.indexOf('?') >= 0 ? '&' : '?') + parameterStr;
    }

    /*
    priority
high: A high priority fetch request relative to other requests of the same type.
low: A low priority fetch request relative to other requests of the same type.
auto: Automatically determine the priority of the fetch request relative to other requests of the same type (default).
    */
    // console.log(' fetch data ', link, method, headers, body);
    const dataRequest = {
      method,
      credentials: 'same-origin',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
        'X-Request-Path': url,
        'X-Request-ID': new Date().getTime(),
      },
      body: typeof body === 'object' ? JSON.stringify(body) : body,
    };

    let id = null;
    // timeout with signal
    if (timeout > 0) {
      const controller = new AbortController();
      id = setTimeout(() => controller.abort(), timeout);
      dataRequest.signal = controller.signal;
    }

    if (priority) {
      dataRequest.priority = priority;
    }

    const logTimeFetch = +new Date();

    // cache with client side & cache boolean
    if (!ctx && method === 'GET' && cache) {
      if (mapRequest[link]) {
        return mapRequest;
      }
      if (!mapRequestPromise[link]) {
        mapRequestPromise[link] = fetch(link, dataRequest);
      }
    }

    const res = await (mapRequestPromise[link] || fetch(link, dataRequest));

    const result = await res.json();
    if (timeout > 0 && id) {
      clearTimeout(id);
    }

    // force logout when having rate limit OR session expired
    if (result.errorCode === 'RATE_LIMIT_EXCEEDED' || result.errorCode === 'TOKEN_HAS_EXPIRED') {
      removeSessionToken();

      try {
        window.location.href = '/home';
      } catch (error) {
        console.error('[Error] cannot redirect on SSR', {
          method,
          url,
          result,
          token: extractToken(headers.Authorization),
          deviceId, // Log DEVICE_ID
        });
      }

      return {};
    }

    // when migrating SSO, call with old session key will return RE_LOGIN_REQUIRED with new token, we can capture this and call again with new ssoToken
    if (result.errorCode === 'RE_LOGIN_REQUIRED') {
      let ssoToken = result.data[0]?.ssoToken;

      if (!ssoToken) {
        // Try calling /me to get ssoToken
        const meRes = await request({ url: '/marketplace/customer/v1/me', method: 'GET', ctx, retry: retry - 1 });

        if (meRes.errorCode === 'RE_LOGIN_REQUIRED') {
          ssoToken = meRes.data[0]?.ssoToken;
        } else if (meRes.status !== HTTP_STATUS.Ok) {
          return {
            error: 'ERROR_RELOGIN',
            status: HTTP_STATUS.Unauthorized,
            message: result.message || ' Hệ thống đã xảy ra lỗi .',
          };
        }
      }

      if (retry >= 0) {
        return request({ url, params, method, body, mock, page, isAuth, ctx, isBasic, debug, cache, timeout, priority, retry: retry - 1, ssoToken });
      }
    }

    if (cache) {
      mapRequest[link] = result;
      mapRequestPromise[link] = null;
      result.cache = true;
    }
    if (isUseBasic) {
      result.isBasic = true;
    }

    if (debug) {
      result.link = link;
      result.method = method;
      // result.result = result;
      result.headers = headers;
      result.body = body;
      result.params = params;
    }

    // if (result.status === 'FORBIDDEN') {
    //   console.log(`FORBIDDEN ${link}`);
    // }

    // console.log('result : ', result);
    // console.log(` fetch data ${method} ${link}`, result);
    // console.log(` fetch data ${link}`, headers, body, result);
    // if (ENV === 'local') {
    // console.log(` fetch data ${method} ${link}`, headers, params, body, result);
    // }
    const timeExcute = +new Date() - logTime;
    const timeFetchAPI = +new Date() - logTimeFetch;
    // console.log(` fetch data ${link}`, timeFetchAPI, timeExcute);
    result.timeExcute = timeExcute;
    result.timeFetchAPI = timeFetchAPI;
    // if (!isValid(result) && ENV === 'local') {
    //   console.log(` fetch data ${method} ${link}`, params, body, result);
    // }
    const retryCodes = [408, 500, 502, 503, 504, 522, 524];

    if (retry > 0 && retryCodes.includes(res.status)) {
      return request({ url, params, method, body, mock, page, isAuth, ctx, isBasic, debug, cache, timeout, priority, retry: retry - 1 });
    }

    return result;
  } catch (err) {
    console.error('err ', err);
    if (retry < 0)
      return {
        error: err,
        status: HTTP_STATUS.Error,
        data: [],
        message: err.message || ' Hệ thống đã xảy ra lỗi .',
      };

    // retry request
    return request({ url, params, method, body, mock, page, isAuth, ctx, isBasic, debug, cache, timeout, priority, retry: retry - 1 });
  }
}

function getBaseURL(environment, ctx) {
  switch (environment) {
    case 'local':
      return 'http://local.thuocsi-web.vn/backend';
    default: {
      const isServerSide = (() => {
        if (ctx) return true;

        try {
          if (window) return false;
        } catch (error) {
          console.error('[Error] calling /backend from SSR:', error.stack);
          return true;
        }
      })();

      return isServerSide ? API_HOST : `${window.location.origin}/backend`;
    }
  }
}

// FIXME: Temp log to debug logout issue. Remove later
function extractToken(authorization) {
  try {
    const bearerRaw = (authorization || '').split('Bearer ')[1];
    const bearer = JSON.parse(atob(bearerRaw));
    const token = bearer.token.substring(0, 24); // Extract half for security

    return token;
  } catch (error) {
    return '';
  }
}

export async function GET(props) {
  return request({ ...props, method: 'GET' });
}

export async function POST(props) {
  return request({ ...props, method: 'POST' });
}

export async function PUT(props) {
  return request({ ...props, method: 'PUT' });
}

export async function DELETE(props) {
  return request({ ...props, method: 'DELETE' });
}

// @deprecate in next release
export function isValidWithData(resp) {
  return resp && resp.status && resp.status === HTTP_STATUS.Ok && resp.data && resp.data[0];
}

export async function GET_ALL(props) {
  const { params, limit } = props;
  const result = {
    status: HTTP_STATUS.Ok,
    data: [],
  };

  const limitPage = limit || MAX_LIMIT;

  const rsTotal = await GET({
    ...props,
    params: { ...params, getTotal: true, limit: limitPage, page: 1 },
  });
  // console.log('get total >> ', rsTotal);

  if (!isValid(rsTotal)) {
    return rsTotal;
  }

  const { total = null, data: dataFirst } = rsTotal;
  result.data = dataFirst;
  if (!total) {
    // ko tra total  ->> get 1000
    const rsAll = await GET({
      ...props,
      params: { ...params, getTotal: true, limit: 1000, page: 1 },
    });
    return rsAll;
  }

  const promiseAll = [];
  for (let i = limitPage; i < total; i += limitPage) {
    // console.log('get all : ', i + 1, totalPage, limitPage);
    promiseAll.push(
      GET({
        ...props,
        params: { ...params, limit: limitPage, page: i / limitPage, offset: i },
      }).then((rs) => {
        if (isValid(rs)) {
          result.data = [...result.data, ...rs.data];
        } else {
          result.status = rs.status || 'Error';
          result.message = rs.message || 'Lỗi : ';
        }
      }),
    );
  }

  await Promise.all(promiseAll);

  return result;
}

export async function POST_ALL(props) {
  const { body, limit } = props;
  const result = {
    status: HTTP_STATUS.Ok,
    data: [],
  };

  const limitPage = limit || MAX_LIMIT;

  const rsTotal = await POST({
    ...props,
    body: { ...body, getTotal: true, limit: 1, page: 1 },
  });
  // console.log('get total >> ', rsTotal);

  if (!isValid(rsTotal)) {
    return rsTotal;
  }
  const { total } = rsTotal;
  const promiseAll = [];
  const totalPage = total / limitPage;
  for (let i = 0; i <= totalPage; i += 1) {
    // console.log('post all : ', i + 1, totalPage, limitPage);
    promiseAll.push(
      POST({
        ...props,
        body: { ...body, limit: limitPage, page: i + 1, offset: i * limitPage },
      }).then((rs) => {
        if (isValid(rs)) {
          result.data = [...result.data, ...rs.data];
        } else {
          result.status = rs.status || 'Error';
          result.message = rs.message || 'Lỗi : ';
        }
      }),
    );
  }

  await Promise.all(promiseAll);

  return result;
}

export default {
  GET,
  GET_ALL,
  POST,
  POST_ALL,
  PUT,
  DELETE,
  isValid,
  isValidWithData,
  getSessionToken,
  isValidWithoutData,
  getSessionTokenClient,
  LIMIT_DEFAULT,
  MAX_LIMIT,
  OFFSET_DEFAULT,
};
