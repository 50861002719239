export const ENUM_SCOPE = {
  DRUGSTORE: 'DRUGSTORE',
  PHARMACY: 'PHARMACY',
  CLINIC: 'CLINIC',
  HOSPITAL: 'HOSPITAL',
  PHARMA_COMPANY: 'PHARMA_COMPANY',
  DENTISTRY: 'DENTISTRY',
  BEAUTY_SALON: 'BEAUTY_SALON',
  HEALTH_CENTER: 'HEALTH_CENTER',
  PATIENT: 'PATIENT',
  PHARMACIST: 'PHARMACIST',
};

export const ENUM_SCOPE_LABEL = {
  [ENUM_SCOPE.DRUGSTORE]: 'Quầy thuốc',
  [ENUM_SCOPE.PHARMACY]: 'Nhà thuốc',
  [ENUM_SCOPE.CLINIC]: 'Phòng khám',
  [ENUM_SCOPE.HOSPITAL]: 'Bệnh viện',
  [ENUM_SCOPE.PHARMA_COMPANY]: 'Công ty dược phẩm',
  [ENUM_SCOPE.DENTISTRY]: 'Nha khoa',
  [ENUM_SCOPE.BEAUTY_SALON]: 'Thẩm mỹ viện',
  [ENUM_SCOPE.HEALTH_CENTER]: 'Trung tâm y tế',
  [ENUM_SCOPE.PATIENT]: 'Bệnh nhân',
  [ENUM_SCOPE.PHARMACIST]: 'Dược sĩ',
};

export const PAYMENT_METHOD = {
  COD: 'PAYMENT_METHOD_NORMAL',
  TRANSFER: 'PAYMENT_METHOD_BANK',
  CREDIT: 'PAYMENT_METHOD_CREDIT',
};

export const PROMO_TYPE = {
  DISCOUNT: 'DISCOUNT',
  COMBO: 'COMBO',
  GIFT: 'GIFT',
  VOUCHERCODE: 'VOUCHERCODE',
  FREESHIP: 'FREESHIP',
  PERCENTAGE: 'PERCENTAGE',
  ABSOLUTE: 'ABSOLUTE',
  POINT: 'POINT',
};

export const PROMO_REWARD_TYPE = {
  PERCENTAGE: 'PERCENTAGE',
  GIFT: 'GIFT',
  POINT: 'POINT',
  ABSOLUTE: 'ABSOLUTE',
};

export const ALIGN = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
};

export const ENUM_ORDER_STATUS = {
  ALL: 'ALL',
  WAIT_TO_CONFIRM: 'WAIT_TO_CONFIRM',
  CONFIRMED: 'CONFIRMED',
  PROCESSING: 'PROCESSING',
  DELIVERING: 'DELIVERING',
  TRANSPORTING: 'TRANSPORTING',
  DELIVERED: 'DELIVERED',
  CANCEL: 'CANCEL',
  COMPLETED: 'COMPLETED',
  WAIT_TO_DELIVER: 'WAIT_TO_DELIVER',
};

export const ENUM_ORDER_STATUS_LABEL = {
  [ENUM_ORDER_STATUS.WAIT_TO_CONFIRM]: 'Chờ xác nhận',
  [ENUM_ORDER_STATUS.CONFIRMED]: 'Đã xác nhận',
  [ENUM_ORDER_STATUS.PROCESSING]: 'Đang xử lý',
  [ENUM_ORDER_STATUS.DELIVERING]: 'Đang vận chuyển',
  [ENUM_ORDER_STATUS.TRANSPORTING]: 'Đang trung chuyển',
  [ENUM_ORDER_STATUS.WAIT_TO_DELIVER]: 'Chờ vận chuyển',
  [ENUM_ORDER_STATUS.DELIVERED]: 'Đã giao',
  [ENUM_ORDER_STATUS.CANCEL]: 'Đã hủy',
  [ENUM_ORDER_STATUS.COMPLETED]: 'Đã hoàn tất',
};

export const ENUM_ORDER_STATUS_COLOR = {
  [ENUM_ORDER_STATUS.WAIT_TO_CONFIRM]: 'btn--default',
  [ENUM_ORDER_STATUS.CONFIRMED]: 'btn--default',
  [ENUM_ORDER_STATUS.PROCESSING]: 'btn--default',
  [ENUM_ORDER_STATUS.WAIT_TO_DELIVER]: 'btn--default',
  [ENUM_ORDER_STATUS.DELIVERING]: 'btn--delivery',
  [ENUM_ORDER_STATUS.TRANSPORTING]: 'btn--delivery',
  [ENUM_ORDER_STATUS.DELIVERED]: 'btn--delivered',
  [ENUM_ORDER_STATUS.CANCEL]: 'btn--cancel',
  [ENUM_ORDER_STATUS.COMPLETED]: 'btn--complete',
};

export const ENUM_ORDER_TYPE = {
  NORMAL: 'NORMAL',
  COMBO: 'COMBO',
  DEAL: 'DEAL',
  GIFT: 'GIFT',
  CAMPAIGN: 'CAMPAIGN',
};

export const GROUP_ADDRESS_TYPE = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
};

export const ADDRESS_CHANGE_TYPE = {
  ASSIGNED: 'ASSIGNED',
  PROVINCE_CHANGE: 'PROVINCE_CHANGE',
  DISTRICT_CHANGE: 'DISTRICT_CHANGE',
  WARD_CHANGE: 'WARD_CHANGE',
  SELECT: 'SELECT',
};

export const REFERRAL_STATUS = {
  NEW: 'NEW',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
  DONE: 'DONE',
};

export const REFERRAL_STATUS_LABEL = {
  [REFERRAL_STATUS.NEW]: 'Chưa tạo tài khoản',
  [REFERRAL_STATUS.APPROVED]: 'Giới thiệu thành công',
  [REFERRAL_STATUS.DECLINED]: 'Giới thiệu bị từ chối',
  [REFERRAL_STATUS.DONE]: 'Giới thiệu hoàn tất',
};

export const FILE_TYPE = {
  PDF: 'application/pdf',
  PNG: 'image/png',
  JPEG: 'image/jpeg',
  DOC: 'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
};

export const ENUMS_PAYMENT_METHOD = {
  PAYMENT_METHOD_NORMAL: 'PAYMENT_METHOD_NORMAL',
  PAYMENT_METHOD_BANK: 'PAYMENT_METHOD_BANK',
  PAYMENT_METHOD_CREDIT: 'PAYMENT_METHOD_CREDIT',

  // partner payment method
  ATM: 'ATM_BANK_ACCOUNT',
  MASTERCARD: 'MASTERCARD',
  VISA: 'VISA',
  CREDIT_DEBIT: 'CREDIT_DEBIT', // Credit card
  QR: 'QR_CODE',
  MOMO: 'MOMO',
  VIETQR: 'VIETQR',
  ZALOPAY: 'ZALOPAY',
};
export const PAYMENT_METHOD_NAME = {
  [ENUMS_PAYMENT_METHOD.PAYMENT_METHOD_NORMAL]: 'Thanh toán tiền mặt khi nhận hàng',
  [ENUMS_PAYMENT_METHOD.PAYMENT_METHOD_BANK]: 'Thanh toán chuyển khoản',
  [ENUMS_PAYMENT_METHOD.PAYMENT_METHOD_CREDIT]: 'Thanh toán hợp đồng',
  [ENUMS_PAYMENT_METHOD.ATM]: '	Thanh toán ATM',
  [ENUMS_PAYMENT_METHOD.MASTERCARD]: 'Thanh toán Mastercard',
  [ENUMS_PAYMENT_METHOD.VISA]: 'Thanh toán Visa',
  [ENUMS_PAYMENT_METHOD.CREDIT_DEBIT]: 'Thẻ thanh toán quốc tế',
  [ENUMS_PAYMENT_METHOD.QR]: 'Thanh toán bằng mã QR',
  [ENUMS_PAYMENT_METHOD.MOMO]: 'Thanh toán bằng Ví MoMo',
  [ENUMS_PAYMENT_METHOD.VIETQR]: 'Thanh toán VietQR',
  [ENUMS_PAYMENT_METHOD.ZALOPAY]: 'Thanh toán qua Zalopay',
};
export const PAYMENT_METHOD_NAME_SHORT = {
  [ENUMS_PAYMENT_METHOD.PAYMENT_METHOD_NORMAL]: 'Tiền mặt',
  [ENUMS_PAYMENT_METHOD.PAYMENT_METHOD_BANK]: 'Chuyển khoản',
  [ENUMS_PAYMENT_METHOD.PAYMENT_METHOD_BANK_1]: 'Chuyển khoản',
  [ENUMS_PAYMENT_METHOD.PAYMENT_METHOD_CREDIT]: 'Hợp đồng',
  [ENUMS_PAYMENT_METHOD.ATM]: 'online',
  [ENUMS_PAYMENT_METHOD.MASTERCARD]: 'online',
  [ENUMS_PAYMENT_METHOD.VISA]: 'online',
  [ENUMS_PAYMENT_METHOD.CREDIT_DEBIT]: 'online',
  [ENUMS_PAYMENT_METHOD.QR]: 'online',
  [ENUMS_PAYMENT_METHOD.MOMO]: 'online',
  [ENUMS_PAYMENT_METHOD.VIETQR]: 'online',
  [ENUMS_PAYMENT_METHOD.ZALOPAY]: 'online',
};

export const ARR_REMOVE_PRODUCT = [
  'NOT_ACTIVE_SKU',
  'SUSPENDED_SKU',
  'STOP_PRODUCING_SKU',
  'OUT_OF_STOCK_SKU',
  'CART_ITEM_INVALID',
  'NOT_AVAILABLE_SKU',
  'NOT_FOUND_SKU',
  'NOT_FOUND_PRICE_SKU',
  'COMBO_INVALID',
  'REQUIRED_CERTIFICATE',
  'MAX_QUANTITY',
];
export const SELLER_GROUP = {
  MEDX: 'MEDX',
  DOITAC: 'DOITAC',
  HANGMUAHO: 'HANGMUAHO',
  DUREX: 'DUREX', // hardcode cho DUREX,
  GIFT_ORDER: 'GIFT_ORDER',
};

export const LEVEL_CUSTOMER = {
  LEVEL_SILVER: 'bạc',
  LEVEL_GOLD: 'vàng',
  LEVEL_PLATINUM: 'bạch kim',
  LEVEL_DIAMOND: 'kim cương',
};

export const CUSTOMER_TAG = {
  BAN: 'BAN',
  BLOCK_COD: 'BLOCK_COD',
};
export const TYPE_CUSTOMER_BY_COLOR = {
  LOCKED_CUSTOMER: 'LOCKED_CUSTOMER',
};
export const FLAGSHIP_LABEL = 'Hàng hãng';
export const THUOCSI_LABEL = 'thuocsi.vn Store';
export const MOBILE_FLAGSHIP_LABEL = 'Gian hàng hãng';
export const LABEL_GIFT_TAG_PROMOTION = 'Tặng Quà';

export const ENUM_ORDER_STATUS_COLOR_V2 = {
  [ENUM_ORDER_STATUS.WAIT_TO_CONFIRM]: '#788FCA',
  [ENUM_ORDER_STATUS.CONFIRMED]: '#3B438F',
  [ENUM_ORDER_STATUS.PROCESSING]: '#E8AE00',
  [ENUM_ORDER_STATUS.DELIVERING]: '#D4323B',
  [ENUM_ORDER_STATUS.DELIVERED]: '#01A0B5',
  [ENUM_ORDER_STATUS.CANCEL]: '#A2A0A0',
  [ENUM_ORDER_STATUS.COMPLETED]: '#0CBA69',
  [ENUM_ORDER_STATUS.WAIT_TO_DELIVER]: '#D55D2A',
};

export const THUOCSI_RECOMMENDATION = {
  SAME_CATEGORY: 'SAME_CATEGORY',
  PURCHASE_TOGETHER: 'PURCHASE_TOGETHER',
  CONFIG_WEIGHT: 'CONFIG_WEIGHT',
  ALL: 'ALL',
};

export const INSIDER_RECOMMENDATION = {
  MOST_VIEWED: 'MOST_VIEWED',
  USER_BASED: 'USER_BASED',
};

export const BRAND_URL = 'https://thuocsi.vn';
export const BRAND_NAME = 'thuocsi.vn';
export const BRAND_EMAIL_HELP = 'hotro@buymed.com';
export const BRAND_PHONE = '18002038';
export const BRAND_PHONE_DISPLAY = '1800 2038';

export const ENUMS_ERROR_MSG_CODE = {
  STATUS_INVALID: 'Đã có lỗi xảy ra, xin vui lòng thử lại sau.',
  PROMOTION_NOT_FOUND: 'Mã khuyến mãi không tồn tại.',
  PROMOTION_INACTIVE: 'Mã khuyến mãi chưa được kích hoạt.',
  PROMOTION_TYPE_INVALID: 'Mã khuyến mãi có loại không hợp lệ.',
  LOYALTY_FAILED: 'Đã xảy ra lỗi khi đổi điểm.',
  LOYALTY_INVALID: 'Số điểm đổi phải nhỏ hơn 1000.',
  LOYALTY_NOT_FOUND: 'Điểm đổi không hợp lệ.',
  NOT_ENOUGH_POINTS: 'Bạn không có đủ điểm để đổi mã giảm giá này.',
  TYPE_INVALID: 'Đã có lỗi xảy ra, xin vui lòng thử lại sau.',
  MAX_USAGE_PER_CUSTOMER: 'Bạn đã đổi đủ số lượt quy định. Vui lòng chọn mã khác để đổi điểm.',
  MAX_USAGE_QUANTITY: 'Mã đổi điểm đã đủ số lượng đổi toàn sàn. Vui lòng chọn mã khác để đổi điểm',
};

export const ENUMS_ERROR_ACC_CODE = {
  CUSTOMER_DELETED: 'CUSTOMER_DELETED',
  CUSTOMER_BLOCK: 'CUSTOMER_BLOCK',
  LOGIN_FAILED: 'LOGIN_FAILED',
  WRONG_PASSWORD: 'WRONG_PASSWORD',
  INVALID: 'INVALID',
  NOT_FOUND: 'NOT_FOUND',
  NOT_FOUND_OTP: 'NOT_FOUND_OTP',
  EXPIRED_OTP: 'EXPIRED_OTP',
  EXPIRED_VERIFICATION_CODE: 'EXPIRED_VERIFICATION_CODE',
  TOO_MANY_VERIFICATION_CODE_REQUESTS: 'TOO_MANY_VERIFICATION_CODE_REQUESTS',
  NOT_FOUND_VERIFICATION_CODE: 'NOT_FOUND_VERIFICATION_CODE',
  TOO_MANY_REQUEST: 'TOO_MANY_REQUEST',
  TOO_MANY_OTP_REQUESTS: 'TOO_MANY_OTP_REQUESTS',
  CUSTOMER_EXISTED: 'CUSTOMER_EXISTED',
  USED_OTP: 'USED_OTP',
  INVALID_CUSTOMER_STATUS: 'INVALID_CUSTOMER_STATUS',
  INACTIVE_ACCOUNT: 'INACTIVE_ACCOUNT',
  EXPIRED_TOKEN_RECOVERY: 'EXPIRED_TOKEN_RECOVERY',
  RATE_LIMIT_EXCEEDED: 'RATE_LIMIT_EXCEEDED',
  ACCOUNT_LOGIN_BLOCKED: 'ACCOUNT_LOGIN_BLOCKED',
  FORBIDDEN_APP: 'FORBIDDEN_APP',
  NOT_FOUND_ACCOUNT: 'NOT_FOUND_ACCOUNT',
  PASSWORD_MISSING: 'PASSWORD_MISSING',
  USERNAME_MISSING: 'USERNAME_MISSING',
  REQUIRE_PHONE_NUMBER: 'REQUIRE_PHONE_NUMBER',
  EMAIL_EXISTED: 'EMAIL_EXISTED',
  PHONE_EXISTED: 'PHONE_EXISTED',
  WRONG_VERIFICATION_CODE: 'WRONG_VERIFICATION_CODE',
};

export const ERROR_CODE_CONTENT = {
  [ENUMS_ERROR_ACC_CODE.CUSTOMER_DELETED]: 'Tài khoản đã bị xoá',
  [ENUMS_ERROR_ACC_CODE.NOT_FOUND]: 'Tài khoản hoặc mật khẩu không đúng. Vui lòng kiểm tra lại.',
  [ENUMS_ERROR_ACC_CODE.NOT_FOUND_OTP]: 'Mã xác thực không đúng. Vui lòng kiểm tra và nhập lại!',
  [ENUMS_ERROR_ACC_CODE.LOGIN_FAILED]: 'Tài khoản hoặc mật khẩu không đúng. Vui lòng kiểm tra lại.',
  [ENUMS_ERROR_ACC_CODE.WRONG_PASSWORD]: 'Tài khoản hoặc mật khẩu không đúng. Vui lòng kiểm tra lại.',
  [ENUMS_ERROR_ACC_CODE.EXPIRED_OTP]: 'Mã xác thực đã hết hiệu lực. Vui lòng nhập mã mới',
  [ENUMS_ERROR_ACC_CODE.EXPIRED_VERIFICATION_CODE]: 'Mã xác thực đã hết hiệu lực. Vui lòng nhập mã mới',
  [ENUMS_ERROR_ACC_CODE.INVALID]: 'Bạn đã đăng ký quá nhiều tài khoản.<br/> Vui lòng thử lại sau.',
  [ENUMS_ERROR_ACC_CODE.TOO_MANY_VERIFICATION_CODE_REQUESTS]: 'Bạn đã gửi quá nhiều yêu cầu.<br/>  Vui lòng thử lại sau 3 phút.',
  [ENUMS_ERROR_ACC_CODE.TOO_MANY_REQUEST]: 'Bạn đã gửi quá nhiều yêu cầu.<br/>  Vui lòng thử lại sau 3 phút.',
  [ENUMS_ERROR_ACC_CODE.TOO_MANY_OTP_REQUESTS]: 'Bạn đã gửi quá nhiều yêu cầu.<br/>  Vui lòng thử lại sau 3 phút.',
  [ENUMS_ERROR_ACC_CODE.USED_OTP]: 'Mã xác thực không đúng.<br/> Vui lòng kiểm tra và nhập lại!',
  [ENUMS_ERROR_ACC_CODE.INVALID_CUSTOMER_STATUS]: 'Tài khoản của bạn đang tạm ngưng hoạt động.',
  [ENUMS_ERROR_ACC_CODE.INACTIVE_ACCOUNT]: 'Tài khoản của bạn đang tạm ngưng hoạt động.',
  [ENUMS_ERROR_ACC_CODE.EXPIRED_TOKEN_RECOVERY]: 'Mã xác thực đã hết hiệu lực. Vui lòng nhập mã mới',
  [ENUMS_ERROR_ACC_CODE.ACCOUNT_LOGIN_BLOCKED]: 'Tài khoản bị khóa tạm thời do đăng nhập sai quá nhiều lần, vui lòng thử lại sau 10 phút.',
  [ENUMS_ERROR_ACC_CODE.FORBIDDEN_APP]: 'Tài khoản không tồn tại.', // Message tạm cho case tài khoản đã tồn tại trên hệ thống SSO nhưng chưa đăng ký Thuocsi.vn
  [ENUMS_ERROR_ACC_CODE.NOT_FOUND_ACCOUNT]: 'Tài khoản không tồn tại.',
  [ENUMS_ERROR_ACC_CODE.PASSWORD_MISSING]: 'Cần nhập mật khẩu.',
  [ENUMS_ERROR_ACC_CODE.USERNAME_MISSING]: 'Cần nhập tên đăng nhập.',
  [ENUMS_ERROR_ACC_CODE.REQUIRE_PHONE_NUMBER]: 'Cần nhập số điện thoại.',
  [ENUMS_ERROR_ACC_CODE.WRONG_VERIFICATION_CODE]: 'Mã xác thực không đúng. Vui lòng kiểm tra và nhập lại!',
};

export const OTP_ID = {
  OTP_1: 'otp_1',
  OTP_2: 'otp_2',
  OTP_3: 'otp_3',
  OTP_4: 'otp_4',
  OTP_5: 'otp_5',
  OTP_6: 'otp_6',
};

export const ENUMS_CHAT_SETTING_VALUE = {
  THUOCSI: 'THUOCSI',
  OFF: 'OFF',
  MESSENGER: 'MESSENGER',
};

export const ENUMS_FILTER_CATEGORY_TYPE = {
  EXTRA_CATEGORY: 'EXTRA_CATEGORY',
  CATEGORY: 'CATEGORY',
  MANUFACTURER: 'MANUFACTURER',
};

export const RETRY_NUMBER_GET_USER = 3;

export const ENUM_GAME_TYPE = {
  LUCKY_WHEEL: 'LUCKY_WHEEL',
  LUCKY_TREE: 'LUCKY_MONEY_ENVELOPE',
};

