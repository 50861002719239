import { getNotify, markReadAllNoti, markReadNotiByCode } from 'clients/NotifyClient';
import { ENUM_NOTIFICATION_TAB } from 'components-v2/mocules/NewNotification/interface';
import { AddKeyDateNotification, tagsInTab } from 'components-v2/mocules/NewNotification/utils';
import { createContext, useContext, useEffect, useReducer, useRef, useState } from 'react';
import NotifyService from 'services/NofifyService';
import { WS_HOST } from 'sysconfig';
import NotifyUtils from 'utils/NotifyUtils';
import NotiReducer, { NOTIFY_TYPES } from './NotiReducer';

export const NotiContext = createContext();

export const NotiContextProvider = ({ children, initUser: user }) => {
  // const initialState = { loading: true, notification: [], totalNotification: 0, initSocket: false, numberNotifyWithTab: {} };
  const initialState = { loading: true, notification: {
    important: [],
    product: [],
    promotion: [],
  },
  importantNotify: {
    total:  0,
    unread: 0,
  },
  productNotify: {
    total:  0,
    unread: 0,
  },
  promotionNotify: {
    total:  0,
    unread: 0,
  }, totalNotification: 0, initSocket: false, numberNotifyWithTab: {} };
  const [state, dispatch] = useReducer(NotiReducer, initialState);
  const [isLoadingNotify, setIsLoadingNotify] = useState(false);

  const countData = async () => {
    try {
      setIsLoadingNotify(true)
      const totalNotification = await NotifyService.getTotalNotification({})
      if (!totalNotification) {
        return;
      }
      const { unread, total, read } = totalNotification;
      dispatch({
        type: NOTIFY_TYPES.COUNT_SUCCESS,
        payload: {
          unread,
          total,
          read,
        },
      });
      setIsLoadingNotify(false)
    } catch (error) {
      dispatch({ type: NOTIFY_TYPES.FETCH_ERROR });
      setIsLoadingNotify(false)
    }
  }
  const fetchData = async () => {
    try {
      let temp = {
        ...state
      }
      setIsLoadingNotify(true)
      const totalNotification = await NotifyService.getTotalNotification({})
      if (!totalNotification) {
        return;
      }
      const { unread, total } = totalNotification;
      if (unread > 0) {
        const [totalNotificationImportant, totalNotificationProduct, totalNotificationPromotion] = await Promise.all([
          NotifyService.getTotalNotification({ tags: tagsInTab(ENUM_NOTIFICATION_TAB.IMPORTANT)}),
          NotifyService.getTotalNotification({ tags: tagsInTab(ENUM_NOTIFICATION_TAB.PRODUCT)}),
          NotifyService.getTotalNotification({ tags: tagsInTab(ENUM_NOTIFICATION_TAB.PROMOTION)})
        ])
        temp = {
          ...temp,
          importantNotify: {
            total: totalNotificationImportant?.total || 0,
            unread: totalNotificationImportant?.unread || 0
          },
          productNotify: {
            total: totalNotificationProduct?.total || 0,
            unread: totalNotificationProduct?.unread || 0
          },
          promotionNotify: {
            total: totalNotificationPromotion?.total || 0,
            unread: totalNotificationPromotion?.unread || 0
          },
        }
      }

      const [ importantNotifyRes, productNotifyRes, promoNotifyRes ] = await Promise.all([
        temp.importantNotify?.unread > 0 ? getNotify({tags: tagsInTab(ENUM_NOTIFICATION_TAB.IMPORTANT)}) : Promise.resolve(temp.notification.important || []),
        temp.productNotify?.unread > 0 ? getNotify({tags: tagsInTab(ENUM_NOTIFICATION_TAB.PRODUCT)}) : Promise.resolve(temp.notification.product || []),
        temp.promotionNotify?.unread > 0 ? getNotify({tags: tagsInTab(ENUM_NOTIFICATION_TAB.PROMOTION)}) : Promise.resolve(temp.notification.promotion || []),
      ])
      dispatch({
        type: NOTIFY_TYPES.FETCH_SUCCESS,
        payload: {
          notification: {
            important:  AddKeyDateNotification(importantNotifyRes.data) || [],
            product:  AddKeyDateNotification(productNotifyRes.data) || [],
            promotion:  AddKeyDateNotification(promoNotifyRes.data) || [],
          },
          unread,
          total,
          importantNotify: {
            total: temp.importantNotify?.total || 0,
            unread: temp.importantNotify?.unread || 0
          },
          productNotify: {
            total: temp.productNotify?.total || 0,
            unread: temp.productNotify?.unread || 0
          },
          promotionNotify: {
            total: temp.promotionNotify?.total || 0,
            unread: temp.promotionNotify?.unread || 0
          }
        },
      });
      setIsLoadingNotify(false)
    } catch (error) {
      dispatch({ type: NOTIFY_TYPES.FETCH_ERROR });
      setIsLoadingNotify(false)
    }
  }

  const fetchDataByTab = async (tab = ENUM_NOTIFICATION_TAB.IMPORTANT) => {
    try {
      let temp = {
        ...state
      }
      setIsLoadingNotify(true)

      if (state.unread > 0) {
        const [totalNotificationImportant, totalNotificationProduct, totalNotificationPromotion] = await Promise.all([
          NotifyService.getTotalNotification({ tags: tagsInTab(ENUM_NOTIFICATION_TAB.IMPORTANT)}),
          NotifyService.getTotalNotification({ tags: tagsInTab(ENUM_NOTIFICATION_TAB.PRODUCT)}),
          NotifyService.getTotalNotification({ tags: tagsInTab(ENUM_NOTIFICATION_TAB.PROMOTION)})
        ])
        temp = {
          ...temp,
          importantNotify: {
            total: totalNotificationImportant?.total || 0,
            unread: totalNotificationImportant?.unread || 0
          },
          productNotify: {
            total: totalNotificationProduct?.total || 0,
            unread: totalNotificationProduct?.unread || 0
          },
          promotionNotify: {
            total: totalNotificationPromotion?.total || 0,
            unread: totalNotificationPromotion?.unread || 0
          },
        }
      }

      let notificationRes = null
      if (tab == ENUM_NOTIFICATION_TAB.IMPORTANT && (temp.importantNotify?.unread > 0 || temp.notification?.important?.length == 0)) {
        notificationRes = await getNotify({tags: tagsInTab(tab)})
        temp.notification.important = AddKeyDateNotification(notificationRes.data) || temp.notification.important || []
      } else if (tab == ENUM_NOTIFICATION_TAB.PRODUCT && (temp.productNotify?.unread > 0 || temp.notification?.product?.length == 0)) {
        notificationRes = await getNotify({tags: tagsInTab(tab)})
        temp.notification.product = AddKeyDateNotification(notificationRes.data) || temp.notification.product || []
      } else if (tab == ENUM_NOTIFICATION_TAB.PROMOTION && (temp.promotionNotify?.unread > 0 || temp.notification?.promotion?.length == 0)) {
        notificationRes = await getNotify({tags: tagsInTab(tab)})
        temp.notification.promotion = AddKeyDateNotification(notificationRes.data) || temp.notification.promotion || []
      }
      if (notificationRes == null || notificationRes.status == "OK" || notificationRes.status == "NOT_FOUND" ) {
        dispatch({
          type: NOTIFY_TYPES.FETCH_SUCCESS,
          payload: {
            notification: temp.notification,
            unread: state.unread,
            total: state.total,
            importantNotify: {
              total: temp.importantNotify?.total || 0,
              unread: temp.importantNotify?.unread || 0
            },
            productNotify: {
              total: temp.productNotify?.total || 0,
              unread: temp.productNotify?.unread || 0
            },
            promotionNotify: {
              total: temp.promotionNotify?.total || 0,
              unread: temp.promotionNotify?.unread || 0
            }
          },
        });
      } else {
        dispatch({ type: NOTIFY_TYPES.FETCH_ERROR });
      }
      setIsLoadingNotify(false)

    } catch (error) {
      dispatch({ type: NOTIFY_TYPES.FETCH_ERROR });
      setIsLoadingNotify(false)

    }
  }
  useEffect(() => {
    countData()
  }, [])

  const ws = useRef(null);

  useEffect(() => {
    if (ws.current || !user?.account || !user?.session) return

	openConnection()

    return () => {
		if (ws.current) {
		  ws.current.onopen = null;
		  ws.current.onclose = null;
		  ws.current.onerror = null;
		  ws.current?.close?.();
		}
	};
  }, []);

  function openConnection() {
    try {
      ws.current = new WebSocket(`wss://${WS_HOST}/integration/notification/v1/web-socket`);
      ws.current.onopen = () => {};
      ws.current.onclose = () => {
        // The websocket can be closed aftet timeout of 10 minutes.
        // Attempt to reconnect after closing the connection.
        openConnection();
      };
    } catch (error) {
		console.error("[ERROR] notification channel:", error)
	}
  }

  useEffect(() => {
    if (!ws.current) return
    const authSocket = async () => {
      const { account, session = {} } = user || {};
      const { token, type } = session || {};
      const authMessage = {
        topic: 'AUTHORIZATION',
        content: {
          username: account?.username,
          sessionToken: token,
          type,
        },
      };
      ws.current.send(JSON.stringify(authMessage));
    }
    ws.current.onmessage = e => {
      try {
        const data = JSON.parse(e.data);
        if (data) {
          const { topic } = data;
          switch (topic) {
            case 'CONNECTED':
              authSocket({});
              break;
            case 'AUTHORIZATION':
              break;
            case 'CONNECTION':
              break;
            case 'PROMOTION':
              break;
            case 'EVENT':
              break;
            case 'ANNOUNCEMENT':
              NotifyUtils.info('Bạn có thông báo mới.');
              fetchData();
              break;
            case 'CART_UPDATE':
              NotifyUtils.info('Giỏ hàng vừa có sự thay đổi.');
              break;
            default:
              break;
          }
        }
      } catch (error) {
      }
    };
  }, [user]);

  const markAll = async (tab, isFetchData = true) => {
    const getTagsInTab = tab ? tagsInTab(tab) : ""
    const rest = await markReadAllNoti({ tags: getTagsInTab });
    if (isFetchData) {
      fetchData();
    }
    return rest;
  };

  const markReadByCode = async (code) => {
    const res = await markReadNotiByCode({ code });
    fetchData();
    return res;
  };

  const contextValues = {
    ...state,
    markAll,
    markReadByCode,
    fetchDataByTab,
    isLoadingNotify,
    fetchData,
  };

  return <NotiContext.Provider value={contextValues}>{children}</NotiContext.Provider>;
};

export const useNotify = () => useContext(NotiContext);
