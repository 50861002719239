import { collectEvent, collectEventRegister } from 'clients/MonitorClient';

export const ENUM_TRACKING_SOURCE = {
  QUICK_ORDER: 'quick-order',
  PRODUCT_LIST: 'product-list',
  JUST_FOR_YOU: 'just-for-you',
  CART: 'cart',
  PROMOTION: 'promotion',
  SELLER_HOME: 'seller-home',
  SELLER_LIST: 'seller-list',
  RECOMMENDATION: 'recommendation',
  RECOMMENDATION_TS: 'recommendation-ts',
  SAME_CATEGORY: 'same-category',
  SAME_INGREDIENT: 'same-ingredient',
  SAME_SELLER: 'same-seller',
  WISHLIST: 'wishlist',
  VIEWED_LIST: 'viewed-list',
  HOME: 'home',
  PRODUCT_DETAIL: 'product-detail',
  EXCLUSIVE: 'exclusive',
  TU_THUOC_COVID: 'tu-thuoc-covid',
  THUOC_KE_DON_GIA_TOT: 'thuoc-ke-don-gia-tot',
  PRODUCT_LIST_SKU_SHADOW: 'product-list-sku-shadow',
  PROMO_CODES: 'promo-codes',
  USER_LOYALTY_POINTS: 'users-loyalty-points',
  VOUCHER_LIST: 'voucher-list',
  HIGH_PROFIT: 'high-profit',
  BLOCK_DETAIL_PAGE: 'block-detail-page',
};

export const MONITORING_COLLECTOR_TYPE = {
  LOGIN: 'LOGIN',
  SKU_DETAIL_VIEW: 'SKU_DETAIL_VIEW',
  ADD_TO_CART: 'ADD_TO_CART',
  ADD_SUGGESTED_COMBO_TO_CART: 'ADD_SUGGESTED_COMBO_TO_CART',
  REMOVE_FROM_CART: 'REMOVE_FROM_CART',
  SKU_IMPRESSION: 'SKU_IMPRESSION',
  KEY_SKU_IMPRESSION: 'KEY_SKU_IMPRESSION',
  SEARCH: 'SEARCH',
  DISPLAY_LIVESTREAM: 'DISPLAY_LIVESTREAM',
  CLICK_BUY_MORE_BUTTON: 'CLICK_BUY_MORE_BUTTON',
  CLICK_CHANGING_POINT_BUTTON: 'CLICK_CHANGING_POINT_BUTTON',
  CLICK_CHANGING_POINT_BUTTON_IN_DETAIL_POPUP: 'CLICK_CHANGING_POINT_BUTTON_IN_DETAIL_POPUP',
  CLICK_GREY_OUT_CHANGING_POINT_BUTTON: 'CLICK_GREY_OUT_CHANGING_POINT_BUTTON',
  CLICK_GREY_OUT_CHANGING_POINT_BUTTON_IN_DETAIL_POPUP: 'CLICK_GREY_OUT_CHANGING_POINT_BUTTON_IN_DETAIL_POPUP',
  CLICK_DETAIL_CHANGING_POINT_POPUP: 'CLICK_DETAIL_CHANGING_POINT_POPUP',
  CLICK_CHANGING_POINT_BUTTON_IN_NOTIFICATION_POPUP: 'CLICK_CHANGING_POINT_BUTTON_IN_NOTIFICATION_POPUP',
  CLICK_CHANGING_POINT_GUIDE: 'CLICK_CHANGING_POINT_GUIDE',
  CLICK_CHANGING_LOYALTY_POINT: 'CLICK_CHANGING_LOYALTY_POINT',
  // collect voucher
  CLICK_COLLECTION_VOUCHER_SECTION: 'CLICK_COLLECTION_VOUCHER_SECTION',
  CLICK_COLLECTION_BUTTON: 'CLICK_COLLECTION_BUTTON',
  CLICK_COLLECTION_VOUCHER_DETAIL: 'CLICK_COLLECTION_VOUCHER_DETAIL',
  CLICK_COLLECTION_VOUCHER_BUTTON_IN_DETAIL_POPUP: 'CLICK_COLLECTION_VOUCHER_BUTTON_IN_DETAIL_POPUP',
  // podcast's users reward
  CLICK_PODCAST_RULE_ON_REWARD_PROGRAM: 'CLICK_PODCAST_RULE_ON_REWARD_PROGRAM',
  // seller store
  CLICK_VOUCHER_SECTION_ON_SELLER_STORE: 'CLICK_VOUCHER_SECTION_ON_SELLER_STORE',
  CLICK_VOUCHER_CONDITION_ON_SELLER_STORE: 'CLICK_VOUCHER_CONDITION_ON_SELLER_STORE',
  // suggest voucher
  IMPRESSION_ON_SUGGESTED_VOUCHER: 'IMPRESSION_ON_SUGGESTED_VOUCHER',
  CLICK_SEE_MORE_BUTTON: 'CLICK_SEE_MORE_BUTTON',
  CLICK_BUTTON_OF_SUGGESTED_VOUCHER: 'CLICK_BUTTON_OF_SUGGESTED_VOUCHER',
  // substituteProdcut
  SUBSTITUTE_PRODUCT_MAIN_SKU_IMPRESSION: 'SUBSTITUTE_PRODUCT_MAIN_SKU_IMPRESSION',
  SUBSTITUTE_PRODUCT_CLICK_DETAIL: 'SUBSTITUTE_PRODUCT_CLICK_DETAIL',
  SUBSTITUTE_PRODUCTS_CLICK_MORE: 'SUBSTITUTE_PRODUCTS_CLICK_MORE',
  SUBSTITUTE_PRODUCTS_ADD_TO_CART: 'SUBSTITUTE_PRODUCTS_ADD_TO_CART',
};

export const SCREEN_TO_ENUM_TRACKING_SOURCE_MAP = {
  '/quick-order': ENUM_TRACKING_SOURCE.QUICK_ORDER,
  '/products': ENUM_TRACKING_SOURCE.PRODUCT_LIST,
  '/product/just-for-you': ENUM_TRACKING_SOURCE.JUST_FOR_YOU,
  '/cart': ENUM_TRACKING_SOURCE.CART,
  '/khuyenmai': ENUM_TRACKING_SOURCE.PROMOTION,
  '/khuyenmai/[slug]': ENUM_TRACKING_SOURCE.PROMOTION,
  '/list-product': ENUM_TRACKING_SOURCE.SELLER_HOME,
  '/seller/[slug]': ENUM_TRACKING_SOURCE.SELLER_HOME,
  '/seller/[slug]/list-product': ENUM_TRACKING_SOURCE.SELLER_HOME,
  '/seller/[slug]/rewards': ENUM_TRACKING_SOURCE.SELLER_HOME,
  '/flagship-store/[slug]': ENUM_TRACKING_SOURCE.SELLER_HOME,
  '/flagship-store/durex': ENUM_TRACKING_SOURCE.SELLER_HOME,
  '/flagship-store/sanofi': ENUM_TRACKING_SOURCE.SELLER_HOME,
  '/flagship-store/[slug]/list-product': ENUM_TRACKING_SOURCE.SELLER_HOME,
  '/flagship-store/durex/list-product': ENUM_TRACKING_SOURCE.SELLER_HOME,
  '/flagship-store/sanofi/list-product': ENUM_TRACKING_SOURCE.SELLER_HOME,
  '/flagship-store/[slug]/flagship-rewards': ENUM_TRACKING_SOURCE.SELLER_HOME,
  '/seller-products/[slug]': ENUM_TRACKING_SOURCE.SELLER_HOME,
  '/doitac/[code]': ENUM_TRACKING_SOURCE.SELLER_HOME,
  '/sellers': ENUM_TRACKING_SOURCE.SELLER_LIST,
  '/': ENUM_TRACKING_SOURCE.HOME,
  '/home': ENUM_TRACKING_SOURCE.HOME,
  '/product/[slug]': ENUM_TRACKING_SOURCE.PRODUCT_DETAIL,
  '/products/[slug]': ENUM_TRACKING_SOURCE.PRODUCT_DETAIL,
  '/promo-codes': ENUM_TRACKING_SOURCE.PROMO_CODES,
  '/users/loyalty_points': ENUM_TRACKING_SOURCE.USER_LOYALTY_POINTS,
};

const SOURCE_TO_ENUM_TRACKING_SOURCE_MAP = {
  '/quick-order': ENUM_TRACKING_SOURCE.QUICK_ORDER,
  '/products': ENUM_TRACKING_SOURCE.PRODUCT_LIST,
  '/product/just-for-you': ENUM_TRACKING_SOURCE.JUST_FOR_YOU,
  '/cart': ENUM_TRACKING_SOURCE.CART,
  '/khuyenmai': ENUM_TRACKING_SOURCE.PROMOTION,
  '/khuyenmai/[slug]': ENUM_TRACKING_SOURCE.PROMOTION,
  '/user/wishlist': ENUM_TRACKING_SOURCE.WISHLIST,
  '/productviewed': ENUM_TRACKING_SOURCE.VIEWED_LIST,
  '/list-product': ENUM_TRACKING_SOURCE.SELLER_HOME,
  '/seller/[slug]': ENUM_TRACKING_SOURCE.SELLER_HOME,
  '/seller/[slug]/list-product': ENUM_TRACKING_SOURCE.SELLER_HOME,
  '/seller/[slug]/rewards': ENUM_TRACKING_SOURCE.SELLER_HOME,
  '/flagship-store/[slug]': ENUM_TRACKING_SOURCE.SELLER_HOME,
  '/flagship-store/durex': ENUM_TRACKING_SOURCE.SELLER_HOME,
  '/flagship-store/sanofi': ENUM_TRACKING_SOURCE.SELLER_HOME,
  '/flagship-store/[slug]/list-product': ENUM_TRACKING_SOURCE.SELLER_HOME,
  '/flagship-store/durex/list-product': ENUM_TRACKING_SOURCE.SELLER_HOME,
  '/flagship-store/sanofi/list-product': ENUM_TRACKING_SOURCE.SELLER_HOME,
  '/flagship-store/[slug]/flagship-rewards': ENUM_TRACKING_SOURCE.SELLER_HOME,
  '/seller-products/[slug]': ENUM_TRACKING_SOURCE.SELLER_HOME,
  '/doitac/[code]': ENUM_TRACKING_SOURCE.SELLER_HOME,
  '/sellers': ENUM_TRACKING_SOURCE.SELLER_LIST,
  '/product/[slug]': ENUM_TRACKING_SOURCE.PRODUCT_DETAIL,
  '/products/[slug]': ENUM_TRACKING_SOURCE.PRODUCT_DETAIL,
};

const convertProduct = (product) => {
  return {
    sku: product.sku || product.skuCode || '',
    seller_id: String(product.sellerInfo?.sellerID || product.sellerID || ''),
    product_id: String(product.productId || product.id || ''),
    last_quantity: product?.last_quantity,
    current_quantity: product?.current_quantity,
  };
};

const productBlockDetailPathPattern = /^\/products\/[A-Z0-9]+$/;
export const mapSourceToEnum = (product = {}, path = '') => {
  if (product?.isSameIngredient) return ENUM_TRACKING_SOURCE.SAME_INGREDIENT;
  if (product?.isSameCategoryProduct) return ENUM_TRACKING_SOURCE.SAME_CATEGORY;
  if (product?.isSameSeller) return ENUM_TRACKING_SOURCE.SAME_SELLER;
  if (product?.isRecommended) return ENUM_TRACKING_SOURCE.RECOMMENDATION;
  if (product?.isRecommendedByTS) return ENUM_TRACKING_SOURCE.RECOMMENDATION_TS;
  if (product?.isThuocKeDonGiaTot) return ENUM_TRACKING_SOURCE.THUOC_KE_DON_GIA_TOT;
  if (product?.isShadowSKU) return ENUM_TRACKING_SOURCE.PRODUCT_LIST_SKU_SHADOW;
  if (product?.isHighProfit) return ENUM_TRACKING_SOURCE.HIGH_PROFIT;
  // if (product?.isExclusive) return ENUM_TRACKING_SOURCE.EXCLUSIVE; // temporary hide, wait to create new source tracking for this product line

  return SOURCE_TO_ENUM_TRACKING_SOURCE_MAP[path || window.location.pathname] || '';
};

export const mapScreenToEnum = (product = {}, path = '') => {
  // if (product?.isSameCategoryProduct) return ENUM_TRACKING_SOURCE.SAME_CATEGORY;
  // if (product?.isSameIngredient) return ENUM_TRACKING_SOURCE.SAME_INGREDIENT;
  if (path && (productBlockDetailPathPattern.test(path) || path.startsWith('/products/[slug]'))) {
    return ENUM_TRACKING_SOURCE.BLOCK_DETAIL_PAGE
  }

  return SCREEN_TO_ENUM_TRACKING_SOURCE_MAP[path || window.location.pathname] || '';
};

// document: https://buymed.atlassian.net/wiki/spaces/V2U/pages/549847093/collector
// TODO: use path -> source :  quick-order, product-list, promotion, seller-home, recommendation, same-category, whislist
const sendSKUEvent = async (event, product, path = '', returnObject = false) => {
  const convertedProduct = convertProduct(product);
  const metadata = {
    ...convertedProduct,
    source: product?.eventSource || mapSourceToEnum(product, path),
    screen: mapScreenToEnum(product, path),
    host: window?.location?.host || '',
    block_code: product?.blockCode,
    ...product?.trackingMetadata,
  };

  if (!path) delete metadata?.source;

  if (event === MONITORING_COLLECTOR_TYPE.SKU_IMPRESSION) {
    const productSentArray = JSON.parse(localStorage?.getItem('collectorProductSentArray') || '[]')?.filter(
      (item) => Date.now() - item?.lastTimeSend < 60000,
    );
    const productIndex = productSentArray?.findIndex((prd) => prd?.productId === convertedProduct?.product_id);

    // Mỗi sản phẩm với một user chỉ gửi một lần/phút
    if (productIndex === -1) {
      let res = {};
      if (returnObject) {
        res = {
          event,
          metadata,
          createdTime: new Date().toISOString(),
        };
      } else {
        res = await collectEvent(event, metadata);
      }

      const info = {
        lastTimeSend: Date.now(),
        productId: convertedProduct?.product_id || '',
      };
      productSentArray.push(info);

      try {
        localStorage.setItem('collectorProductSentArray', JSON.stringify(productSentArray));
      } catch (error) {
        window.localStorage.removeItem('collectorProductSentArray');
      }
      return res;
    }
  }

  if (returnObject) return { event, metadata, createdTime: `${new Date().toISOString()}` };

  const res = await collectEvent(event, metadata);
  return res;
};

const sendLoginEvent = async (event, metadata) => {
  const res = await collectEvent(event, metadata);
  return res;
};

const MAP_PATHS = {
  '/cart': 'CART_DETAIL',
  '/checkout': 'CHECKOUT',
  '/quick-order': 'QUICK_ORDER',
  '/sellers': 'SELLER_LIST',
  '/seller-products/': 'SELLER_HOME',
  '/seller/[slug]': 'SELLER_HOME',
  '/seller/[slug]/products': 'SELLER_HOME',
  '/discovery': 'DISCOVERY',
  '/users/wishlist': 'WISHLIST',
  '/user/wishlist': 'WISHLIST',
  '/khuyenmai': 'PROMOTION',
  '/promotions': 'PROMOTION',
  '/promo-codes': 'VOUCHER',
  '/deals': 'DEALS',
  '/learning': 'Y360_LEARNING',
  '/doitac/[code]': 'SELLER_HOME',
  '/products': 'PRODUCT_LIST',
  '/home?register=true': 'REDIRECT_REGISTER',
  // '/product/': 'PRODUCT_DETAIL',
  // '/productviewed': 'PRODUCT_VIEWED',
  // '/users/referrals': 'REFERRAL',
  // '/my-account': 'MY_ACCOUNT',
  // '/users/loyalty_points': 'LOYALTY_POINTS',
  // '/my-order': 'MY_ORDER',
  // '/my-order/[slug]': 'ORDER_DETAIL',
  // '/manufacturers': 'MANUFACTURER',
  // '/': 'HOME',
};

const convertUrlToPathEvent = (url) => {
  if (MAP_PATHS[url]) {
    return MAP_PATHS[url];
  }
  // if (url.startsWith('/product/')) {
  //   return MAP_PATHS['/product/'];
  // }

  if (url?.startsWith('/seller-products/')) {
    return MAP_PATHS['/seller-products/'];
  }
  if (url?.startsWith('/seller/') || url?.startsWith('/flagship-store/')) {
    if (url?.endsWith('/list-product')) {
      return MAP_PATHS['/seller/[slug]/products'];
    }
    // seller/[slug]/[rewards / products ]
    return MAP_PATHS['/seller/[slug]'];
  }
  if (url?.startsWith('/manufacturers/')) {
    return MAP_PATHS['/manufacturers'];
  }

  if (url?.startsWith('/home?register=true')) {
    return MAP_PATHS['/home?register=true'];
  }

  return '';
};

const convertUrlToScreen = ({ url, product }) => {
  if (mapScreenToEnum(product, url)) {
    return mapScreenToEnum(product, url);
  }
  if (url?.startsWith('/seller-products/')) {
    return mapScreenToEnum(product, '/seller-products/[slug]');
  }
  if (url?.startsWith('/seller/') || url?.startsWith('/flagship-store/')) {
    return mapScreenToEnum(product, '/seller/[slug]');
  }
  if (url?.startsWith('/product/')) {
    return mapScreenToEnum(product, '/product/[slug]')
  }
  return null;
};

const convertUrlToSource = ({ url, product }) => {
  if (mapSourceToEnum(product, url)) {
    return mapSourceToEnum(product, url);
  }
  if (url?.startsWith('/seller-products/')) {
    return mapSourceToEnum(product, '/seller-products/[slug]');
  }
  if (url?.startsWith('/seller/') || url?.startsWith('/flagship-store/')) {
    return mapSourceToEnum(product, '/seller/[slug]');
  }
  if (url?.startsWith('/product/')) {
    return mapScreenToEnum(product, '/product/[slug]')
  }
  return null;
};

// eslint-disable-next-line consistent-return
const sendPageEvent = async ({ url, reffererUrl, metadata = {}, product = {}, returnObject = false, isTimeTracking = false }) => {
  const event = convertUrlToPathEvent(url);
  if (event) {
    const metaData = { ...metadata };
    const screen = convertUrlToScreen({ product, url: reffererUrl });
    if (screen) {
      metaData.screen = screen;
    }
    const source = convertUrlToSource({ product, url: reffererUrl });
    if (source) {
      metaData.source = source;
    }
    metaData.host = window?.location?.host || '';

    if (returnObject) return { event, metadata: metaData, createdTime: `${new Date().toISOString()}` };

    return collectEvent(event, metaData);
  }
};

const sendLivestreamEvent = async (event, metadata) => {
  return await collectEvent(event, {
    ...metadata,
    // screen: mapScreenToEnum({}, path),
    liveStreamID: metadata.liveStreamID.toString(),
    host: window?.location?.host || '',
  });
};

const rsLoyaltyPointClickEvent = async (event, metadata) => {
  // eslint-disable-next-line no-param-reassign
  metadata = {
    ...metadata,
    host: window?.location?.host || '',
  };
  const res = await collectEvent(event, metadata);
  return res;
};

const collectVoucherClickEvent = async (event, metadata) => {
  return await collectEvent(event, {
    ...metadata,
    host: window?.location?.host || '',
  });
};

const podcastRuleClickEvent = async (event, metadata) => {
  return await collectEvent(event, {
    ...metadata,
    host: window?.location?.host || '',
  });
};

const sellerStoreClickEvent = async (event, metadata) => {
  return await collectEvent(event, {
    ...metadata,
    host: window?.location?.host || '',
  });
};

// substitute prod
const substituteProductClickEvent = async (event, metadata) => {
  return await collectEvent(event, {
    ...metadata,
    host: window?.location?.host || '',
  });
};

const cartPageSuggestVoucherEvent = async (event, metadata) => {
  // eslint-disable-next-line no-param-reassign
  metadata = {
    ...metadata,
    host: window?.location?.host || '',
  };
  const res = await collectEvent(event, metadata);
  return res;
};


export const sendPageEventRegister = async ({ url, metadata }) => {
  const event = convertUrlToPathEvent(url);
  if (event) {
    const newMetadata = { ...metadata };
    if (newMetadata.scope) {
      delete newMetadata.businessType;
    }
    const res = await collectEventRegister(event, newMetadata);
    return res;
  }
};

export default {
  sendSKUEvent,
  sendLoginEvent,
  sendPageEvent,
  sendLivestreamEvent,
  rsLoyaltyPointClickEvent,
  collectVoucherClickEvent,
  podcastRuleClickEvent,
  sellerStoreClickEvent,
  substituteProductClickEvent,
  cartPageSuggestVoucherEvent,
  convertUrlToScreen,
  convertUrlToSource,
};
